import _ from 'lodash';

const VerificaClienteAcessoFinanceiro = Clientes =>{
    const CLientesComAcessoAoFinanceiro = _.filter(Clientes,function(cliente){
        return cliente.ClienteFinanceiro === true && cliente.usuarioVinculado === true;
      });
     return CLientesComAcessoAoFinanceiro.length > 0;
}
const RetornaDadosBulkFinanceiro = (Clientes,Senha) =>{
  const CLientesVinculados= _.filter(Clientes,function(cliente){
    return cliente.usuarioVinculado === true;
  });
  
  const dadosCliente = _.map(CLientesVinculados,function(dado){
        return {
            IdCliente: dado.IdCliente,
            ClienteFinanceiro: dado.ClienteFinanceiro,
            IdContaFinanceiro: dado.IdContaFinanceiro
        };
  });
  return {
    Clientes:dadosCliente,
    Usuario:{
      SenhaFinanceiro:Senha
    }
  };
}

export { VerificaClienteAcessoFinanceiro, RetornaDadosBulkFinanceiro };

