import UsuarioService from '../usuario/usuarioService';
import Auth0Service from './auth0Service';
require('dotenv').config();

class Auth0Manager {

  montaCampoParaAtualizarSenha(senhaUsuarioAuth) {
    return {
      password: senhaUsuarioAuth,
    };
  }

  async obtemTokenManagementAuth(tokenAcessoUsuario) {
    try {
      const respostaServicoGerenciadorAuth = await Auth0Service.obtemTokenManagementAuth(
        tokenAcessoUsuario
      );
      return respostaServicoGerenciadorAuth.data;
    } catch (error) {
      console.log(error);
    }
  }

  _mapeiaPermissao(permissoes) {
    const mapeamentoPermissoes = {
      "Acesso App Views": "acessa_aplicativo",
      "Acesso Portal Views Manejo": "acessa_plataforma",
      "Estoque Final": "estoque_final",
      "Importar mapas": "importar_mapas",
      "Exportar/Importar CSV pasto": "exportar_importar_csv_pasto",
      "Cadastrar ciclo de forragems": "cadastrar_ciclo_forragem",
      "Semiconfinamento": "semiconfinamento",
      "Acesso Portal Views Configurações": "acessa_portal_configuracoes",
      "Usuário Tableau": "usuario_tableau",
      "Usuário Analytics": "usuario_analytics",
    }

    return permissoes.reduce((acc, permissao) => {
      const permissaoMapeada = mapeamentoPermissoes[permissao.nome];
      if (permissaoMapeada) {
        acc[permissaoMapeada] = true;
      }
      return acc;
    }, {})
  }

  async obtemUsuarioAuthPeloEmail(emailUsuario, idUsuario) {
    try {
      const usuarioAuth = await Auth0Service.obtemUsuarioAuthPeloEmail(emailUsuario);
      const { data: usuario } = await UsuarioService.buscaUsuario(idUsuario)

      if (!usuario || !usuario.length) {
        return { ...usuarioAuth, permissoes: {} };
      }

      const permissoes = this._mapeiaPermissao(usuario[0].permissoes)
      const dadosUsuario = usuario[0]
      return {
        ...usuarioAuth,
        ...dadosUsuario,
        permissoes
      };

    } catch (error) {
      console.log(error);
    }
  }

  async obtemUsuarioAuthPeloUsername(usernamelUsuario) {
    try {
      const usuarioAuth = await Auth0Service.obtemUsuarioAuthPeloUsername(usernamelUsuario);
      return usuarioAuth;
    } catch (error) {
      console.log(error);
    }
  }

  async atualizaPermissoesUsuarioAuth0(usuario, permissoes) {
    try {
      if (!permissoes || !permissoes.length) {
        return
      }
      const resposta = await UsuarioService.atualizaPermissoesUsuario(usuario, permissoes);
      return resposta
    } catch (error) {
      console.error(error);
    }
  }

  async excluiUsuarioAuth0(userId) {
    try {
      const respostaAuth0 = await Auth0Service.excluiUsuarioAuth0(userId);
      return respostaAuth0;
    } catch (error) {
      console.log(error);
    }
  }

  async obtemRoledoUsuario(IdUsuario) {
    try {
      const role = await UsuarioService.obtemRoledoUsuario(IdUsuario);
      if (role.data.length < 1) {
        return null;
      }
      return role.data[0].name;
    } catch (error) {
      console.log(error);
    }
  }

  async atualizarSenhaUsuario(IdUsuario, senhaUsuarioAuth) {
    try {
      const senhaASerAtualizada = this.montaCampoParaAtualizarSenha(senhaUsuarioAuth);
      const senhaAtualizada = await Auth0Service.atualizaUsuarioAuth(
        IdUsuario,
        senhaASerAtualizada
      );
      return senhaAtualizada;
    } catch (error) {
      console.log(error);
    }
  }
}
export default new Auth0Manager();