import _ from 'lodash';
import { requisicaoComSucesso } from '../../shared/util/utils';
import Auth0Manager from '../usuarioAuth0/auth0Manager';
import UsuarioService from './usuarioService';

export const MSG = {
  ERRO_OBTER_USUARIO: 'Ocorreu um erro ao tentar retornar usuários.',
  ERRO_SALVAR_USUARIO: 'Ocorreu erro ao tentar salvar dados do usuário',
  ERRO_SALVAR_VINCULOS_USUARIO_RETIRO: 'Ocorreu erro ao tentar salvar vínculos',
  ERRO_SALVAR_VINCULOS_USUARIO_FAZENDA: 'Ocorreu erro ao tentar salvar vínculos',
  ERRO_EXCLUIR_USUARIO: 'Ocorreu erro ao tentar exlcuir o usuário',
  ERRO_OBTER_USUARIO_FAZENDAS: 'Ocorreu erro ao tentar retornar fazendas usuario',
  ERRO_OBTER_USUARIO_POR_EMAIL: 'Ocorreu um erro ao tentar obter dados do usuário',
  EMAIL_NAO_CADASTRADO: 'Email não cadastrado',
  ERRO_OBTER_RETIROS_USUARIO: 'Ocorreu um erro ao tentar retornar retiros do usuário'
};

class UsuarioManager {
  async obtemUsuarios() {
    try {
      const respostaServicoUsuario = await UsuarioService.obtemUsuarios();
      const usuarios = respostaServicoUsuario.data.filter(usuario => usuario.Excluido === false);
      return usuarios;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_USUARIO);
    }
  }

  async obtemUsuarioPorEmail(email) {
    try {
      const respostaServicoUsuario = await UsuarioService.obtemUsuarioPorEmail(email);
      const usuarios = respostaServicoUsuario.data.filter(usuario => usuario.Excluido === false);
      const usuarioLogado = {};
      if (usuarios.length === 1) {
        usuarioLogado.IdUsuario = usuarios[0].IdUsuario;
        usuarioLogado.Nome = usuarios[0].Nome;
        usuarioLogado.Email = usuarios[0].Email;
      } else {
        throw new Error(MSG.EMAIL_NAO_CADASTRADO);
      }
      return usuarioLogado;
    } catch (error) {
      throw new Error(
        error.message === MSG.EMAIL_NAO_CADASTRADO
          ? error.message
          : MSG.ERRO_OBTER_USUARIO_POR_EMAIL
      );
    }
  }

  async salvaUsuario(IdUsuario, usuarioSalvar) {
    try {
      let respostaRequisicoes;

      if (IdUsuario) {
        respostaRequisicoes = await this.atualizarUsuario(IdUsuario, usuarioSalvar);
      } else {
        respostaRequisicoes = await this.criarUsuario(usuarioSalvar);
      }

      return requisicaoComSucesso(respostaRequisicoes.status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_USUARIO);
    }
  }

  async criarUsuario(usuarioSalvar) {
    try {
      let respostaServicoUsuario = await UsuarioService
        .cadastraUsuario(usuarioSalvar);
      return respostaServicoUsuario;
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_USUARIO);
    }
  }

  async atualizarUsuario(IdUsuario, usuarioSalvar) {
    try {
      let respostaServicoUsuario = await UsuarioService.salvaUsuario(
        IdUsuario,
        _.omit(usuarioSalvar, ['Senha'])
      );
      return respostaServicoUsuario;
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_USUARIO);
    }
  }

  async atualizarSenhaUsuario(senhaUsuarioAuth0, loginUsuarioAuth0) {
    try {
      let respostaSerivcoUsuarioAuth0;
      let usuarioAuth0 = await Auth0Manager.obtemUsuarioAuthPeloUsername(loginUsuarioAuth0);
      if (usuarioAuth0) {
        respostaSerivcoUsuarioAuth0 = await Auth0Manager.atualizarSenhaUsuario(
          usuarioAuth0.user_id,
          senhaUsuarioAuth0
        );
      } else {
        throw new Error(MSG.ERRO_SALVAR_USUARIO);
      }
      return respostaSerivcoUsuarioAuth0;
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_USUARIO);
    }
  }

  async excluiUsuario(IdUsuario, Email) {
    try {
      let respostaAuth0;
      let respostaServicoUsuario;
      const usuarioAuth = await Auth0Manager.obtemUsuarioAuthPeloEmail(Email, IdUsuario);
      if (usuarioAuth) {
        respostaAuth0 = await Auth0Manager.excluiUsuarioAuth0(usuarioAuth.user_id);
      }
      if (!usuarioAuth || respostaAuth0) {
        respostaServicoUsuario = await UsuarioService.excluiUsuario(IdUsuario);
      } else {
        throw new Error(MSG.ERRO_EXCLUIR_USUARIO);
      }
      return requisicaoComSucesso(respostaServicoUsuario.status);
    } catch (error) {
      throw new Error(MSG.ERRO_EXCLUIR_USUARIO);
    }
  }

  async obtemFazendasUsuario(IdUsuario) {
    try {
      const respostaServicoUsuario = await UsuarioService.obtemFazendasUsuario(IdUsuario);
      const fazendasUsuario = respostaServicoUsuario.data.filter(
        fazenda => fazenda.Excluido === false
      );
      return fazendasUsuario;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_USUARIO_FAZENDAS);
    }
  }

  async obtemRetirosUsuario(IdUsuario) {
    try {
      const respostaServicoUsuario = await UsuarioService.obtemRetirosUsuario(IdUsuario);
      const retirosUsuario = respostaServicoUsuario.data.filter(
        retiroUsuario => retiroUsuario.Excluido === false
      );
      return retirosUsuario;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_RETIROS_USUARIO);
    }
  }

  async salvaVinculosUsuarioRetiro(IdUsuario, vinculosUsuarioRetiro, fazendaComTodosRetiros) {
    try {
      fazendaComTodosRetiros.forEach(async (vinculo) => {
        await UsuarioService.salvaVinculosUsuarioRetiroPeloIdFazenda(IdUsuario, vinculo.IdFazenda);
      });

      const respostaServicoUsuario = await UsuarioService.salvaVinculosUsuarioRetiro(
        IdUsuario,
        vinculosUsuarioRetiro
      );
      return requisicaoComSucesso(respostaServicoUsuario.status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_VINCULOS_USUARIO_RETIRO);
    }
  }

  async obtemClientesUsuario(IdUsuario) {
    try {
      const respostaUsuarioCliente = await UsuarioService.obtemClientesUsuario(IdUsuario);
      return respostaUsuarioCliente.data;
    } catch (error) {
      throw new Error();
    }
  }
}
export default new UsuarioManager();
