import * as UIActions from '../../../shared/redux/UIActions';
import { notificacaoActions } from '../../notificacao/redux';
import RelatorioTableauManager from '../relatorioManager';
import { retornaIdRelatorio } from '../relatorioUtils';
import { atualizarRelatorio, criaRelatorio, deletarRelatorio, obterRelatorio } from './relatorioTableauActions';
export const obterRelatorios = (IdRelatorio = null) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const respostaRelatorio = await RelatorioTableauManager.retornaRelatorios(retornaIdRelatorio(IdRelatorio));
    dispatch(obterRelatorio(respostaRelatorio.data));
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro('Ocorreu um erro ao obter o relatório.')
    );
  }
  finally {
    dispatch(UIActions.removeLoading());
  }
};
export const deletarRelatorios = (IdRelatorio) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    await RelatorioTableauManager.deletaRelatorio(retornaIdRelatorio(IdRelatorio));
    dispatch(
      notificacaoActions.notificaSucesso('Relatório excluído com sucesso.')
    )
    dispatch(deletarRelatorio([]));
  } catch (error) {
    console.log(error)
    dispatch(
      notificacaoActions.notificaErro('Ocorreu um erro ao deletar o relatório.')
    );
  }
  finally {
    dispatch(UIActions.removeLoading());
  }
};
export const atualizarRelatorios = (IdRelatorio, Relatorio) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    await RelatorioTableauManager.atualizaRelatorio(retornaIdRelatorio(IdRelatorio), Relatorio);
    dispatch(atualizarRelatorio([]));
    dispatch(
      notificacaoActions.notificaSucesso('Relatório alterado com sucesso.')
    )

  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro('Ocorreu um erro ao atualizar o relatório.')
    );
  }
  finally {
    dispatch(UIActions.removeLoading());
  }
}
export const criarRelatorio = (Relatorio) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    await RelatorioTableauManager.criaRelatorio(Relatorio);
    dispatch(criaRelatorio([]));
    dispatch(
      notificacaoActions.notificaSucesso('Relatório criado com sucesso.')
    )

  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro('Ocorreu um erro ao criar o relatório.')
    )
  }
  finally {
    dispatch(UIActions.removeLoading());
  }
}
