import * as usuarioClienteActions from './usuarioClienteVinculoActions';

export const INITIAL_STATE = {
  clientesUsuario: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case usuarioClienteActions.SET_CLIENTES_USUARIO:
      return { ...state, clientesUsuario: [...action.payload] };

    default:
      return state;
  }
};
