import { createSelector } from 'reselect';

const retirosUsuarioSelector = state =>
  state.usuarioRetiroVinculo.retirosUsuario;

export const getRetirosUsuario = createSelector(
  retirosUsuarioSelector,
  retirosUsuario =>
    retirosUsuario.map(retiroUsuario => {
      return {
        IdRetiro: retiroUsuario.IdRetiro,
        IdFazenda: retiroUsuario.IdFazenda
      };
    })
);
