import axios from 'axios';

const URI_RELATORIO = '/relatorios-tableau/api/v1/relatorios-tableau/relatorio';

export class RelatorioTableauService {
  async retornaRelatorios(IdRelatorio = '') {
    return axios.get(URI_RELATORIO + IdRelatorio);
  }
  async deletaRelatorios(IdRelatorio) {
    return axios.delete(`${URI_RELATORIO}/${IdRelatorio}`);
  }
  async atualizaRelatorios(IdRelatorio, Relatorio) {
    return axios.patch(`${URI_RELATORIO}/${IdRelatorio}`, Relatorio);
  }
  async criaRelatorio(Relatorio) {
    return axios.post(URI_RELATORIO, Relatorio);
  }
}
export default new RelatorioTableauService();
