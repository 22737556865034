import {
  Button,
  Checkbox,
  CircularProgress,
  ExpansionPanelActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  USUARIO_NAO_EXISTE_AUTH0,
  colunaDeAcessos,
  divideArrayPermissoes
} from "../usuarioAuth0Utils";

export class UsuarioAuth0VinculoTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate(prevProps) {
    if (this.props.usuarioAuth0 !== prevProps.usuarioAuth0) {
      const { usuarioAuth0 } = this.props;
      const { permissoes } = usuarioAuth0;

      this.setState({
        acessa_aplicativo: permissoes.acessa_aplicativo,
        acessa_plataforma: permissoes.acessa_plataforma,
        importar_mapas: permissoes.importar_mapas,
        cadastrar_ciclo_forragem: permissoes.cadastrar_ciclo_forragem,
        exportar_importar_csv_pasto: permissoes.exportar_importar_csv_pasto,
        acessa_portal_configuracoes: permissoes.acessa_portal_configuracoes,
        estoque_final: permissoes.estoque_final,
        semiconfinamento: permissoes.semiconfinamento,
        usuarioTableau: permissoes.usuario_analytics,
        usuario_Master: !permissoes.usuario_tableau && !permissoes.usuario_analytics,
        usuario_Master_Tableau: !permissoes.usuario_analytics && permissoes.usuario_tableau,
      });
    }
  }

  handleChangeAcesso = (acesso) => (event) => {
    this.setState({ [acesso]: event.target.checked });
    switch (acesso) {
      case "usuarioTableau": {
        this.setState({
          usuario_Master: false,
          usuario_Master_Tableau: false,
          usuario_prodap_hands: false,
          usuario_coleta_altura: false,
          usuarioImplantacao: false,
          usuarioPortalConfiguracoes: false,
          usuario_prodap_hands_bombona: false,
          usuario_prodap_hands_semi_confinamento: false,
          usuarioGDA: false,
        });
        break;
      }
      case "usuario_Master": {
        this.setState({ usuarioTableau: false, usuario_Master_Tableau: false });
        break;
      }
      case "usuario_Master_Tableau": {
        this.setState({ usuario_Master: false, usuarioTableau: false });
        break;
      }
      default: {
      }
    }
  };

  handleChangePermissao = (permissao) => (event) => {
    this.setState({ [permissao]: event.target.checked });
  };

  mapeiaPermissoesEmCheckBox(permissoes) {
    return (
      <FormControl component="fieldset" style={{ marginLeft: "47px" }}>
        <FormGroup>
          {permissoes.map((permissao) => (
            <FormControlLabel
              key={permissao.value}
              control={
                <Checkbox
                  checked={this.state[permissao.value] || false}
                  onChange={this.handleChangePermissao(permissao.value)}
                  value={permissao.value}
                />
              }
              onChange={this.handleChangePermissao(permissao.value)}
              label={permissao.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }

  renderPermissoes() {
    const { chamadaUsuarioAuthConcluida, usuarioAuth0 } = this.props;
    if (!chamadaUsuarioAuthConcluida) {
      return <CircularProgress size={20} />;
    } else if (!usuarioAuth0.email) {
      return <Typography variant="subtitle1">{USUARIO_NAO_EXISTE_AUTH0}</Typography>;
    }
    const { colunaPermissoesEsquerda, colunaPermissoesDireita } = divideArrayPermissoes();

    return (
      <React.Fragment style={{ left: "50px" }}>
        <div style={{ position: "relative", right: "90px" }}>
          <Typography style={{ marginBottom: "25px" }}>Tipo de acesso:</Typography>
          <FormControl component="fieldset">
            <RadioGroup>
              {colunaDeAcessos.map((permissao) => (
                <FormControlLabel
                  key={permissao.value}
                  control={
                    <Radio
                      checked={this.state[permissao.value] || false}
                      onChange={this.handleChangeAcesso(permissao.value)}
                      value={permissao.value}
                    />
                  }
                  onChange={this.handleChangeAcesso(permissao.value)}
                  label={permissao.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        {this.state.usuarioTableau ? null : (
          <div>
            <Typography style={{ marginLeft: "47px", marginBottom: "25px" }}>
              Este usuário terá acesso a:
            </Typography>
            {this.mapeiaPermissoesEmCheckBox(colunaPermissoesEsquerda)}
            {this.mapeiaPermissoesEmCheckBox(colunaPermissoesDireita)}
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom align="center" style={{ padding: "2%" }}>
          {this.props.usuario ? this.props.usuario.Email : ""}
        </Typography>
        <Grid
          style={{ padding: "2%", position: "relative", paddingLeft: "150px" }}
          container
          justify="center"
          alignItems="stretch"
          direction="row"
        >
          {this.renderPermissoes()}
        </Grid>
        <ExpansionPanelActions>
          <Button
            disabled={!this.props.usuarioAuth0.email}
            id="btnCriarNovo2"
            color="primary"
            variant="contained"
            onClick={() => this.props.atualizaPermissoesUsuarioAuth0(this.state)}
          >
            Salvar
          </Button>
        </ExpansionPanelActions>
      </React.Fragment>
    );
  }
}

export default UsuarioAuth0VinculoTab;
