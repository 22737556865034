import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  IconButton,
  InputAdornment
} from "@material-ui/core/";

import { Visibility, VisibilityOff } from "@material-ui/icons";

export const dialogForm = props => {
  let {
    classes,
    tituloDialog,
    textoDialog,
    abreDialog,
    fechaDialog,
    cancelarAcaoDialog,
    confirmarAcaoDialog,
    tipoEntradaDados,
    tituloCampo,
    valorCampo,
    setValor
  } = props;

  let mostrarSenha = false;

  /* istanbul ignore next */
  function mostrarOcultarSenha(){
    mostrarSenha = !mostrarSenha;
    let pwd = document.getElementById("txtSenha");
    pwd.setAttribute(
      "type",
      mostrarSenha ? "text" : "password"
    );
  }
  return (
    <Dialog
      open={abreDialog}
      onClose={fechaDialog}
      aria-labelledby="dialogTitle"
      aria-describedby="dialogDescription"
    >
      <div className={classes.conteudoDialog}>
        <DialogTitle id="dialogTitle">{tituloDialog}</DialogTitle>
        {textoDialog && (
          <DialogContent>
            <DialogContentText id="dialogDescription">
              {textoDialog}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="txtSenha"
              defaultValue={valorCampo}
              label={tituloCampo}
              type={tipoEntradaDados}
              onChange={e => setValor(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      id={"botaoMostrarSenha"}
                      aria-label="Mostra a senha"
                      onClick={mostrarOcultarSenha}
                    >
                      {
                        /* istanbul ignore next */
                        mostrarSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button
            id="btnCancelaAcaoDialog"
            onClick={cancelarAcaoDialog}
            autoFocus
          >
            Não, Obrigado.
          </Button>
          <Button
            id="btnConfirmaAcaoDialog"
            onClick={confirmarAcaoDialog}
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

dialogForm.defaultProps = {
  tituloDialog: "",
  abreDialog: false,
  tipoEntradaDados: "text",
  tituloCampo: "",
  aoFecharDialog: () => {},
  cancelarAcaoDialog: () => {},
  confirmarAcaoDialog: () => {},
  setValor: () => {}
};
const styles = theme => ({
  conteudoDialog: {
    maxWidth: 640
  }
});
export default withStyles(styles)(dialogForm);
