import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import toggleActions from '../../toggle/redux/toggleFeatureActions';

export class FazendaCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fazendaSelecionada: this.props.selecionarTodas || this.props.fazendaHabilitada
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      fazendaSelecionada: nextProps.selecionarTodas || nextProps.fazendaHabilitada
    });
  }

  handleChange = () => {
    this.props.selecionaFazenda(this.props.fazenda.IdFazenda);
  };

  render() {
    const { fazenda } = this.props;
    return (
      <FormGroup row>
        <FormControlLabel
          id="fazendaNome"
          control={<Checkbox checked={this.props.fazendaHabilitada} />}
          label={`${fazenda.Nome} - ${fazenda.NomeCliente}`}
          onChange={this.handleChange}
        />
      </FormGroup>
    );
  }
}

const mapDispatchToProps = {
  selecionaFazenda: toggleActions.selecionaFazenda
};

export default connect(
  null,
  mapDispatchToProps
)(FazendaCheckbox);
