export default (theme) => ({
  backdrop: {
    display: "none",
    zIndex: 99999,
    position: "fixed",
    backgroundColor: "rgba(0,0, 0, 0.7)",
    minWidth: "100%",
    minHeight: "100%",
  },
  gif: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "10%",
    marginTop: "20px",
  },
  corpoCarregandoPequeno: {
    marginTop: "15%",
    margin: "auto",
    textAlign: "center",
    Heigth: "auto",
    width: "25%",
    paddingBottom: "15px",
  },
  corpoCarregando: {
    marginTop: "15%",
    margin: "auto",
    textAlign: "center",
    Heigth: "auto",
    width: "25%",
    paddingBottom: "15px",
  },
  barraProgresso: {
    width: "80%",
    margin: "auto",
    marginTop: "25px",
    marginBottom: "25px",
  },
  iconeSucesso: {
    marginTop: "19px",
  },
  Titulo: {
    marginTop: "20px",
  },
  logo: {
    width: "202px",
    height: "70px",
    align: "left !important",
    marginBottom: "20px",
    marginTop: "20px",
  },
  botaoFechar: {
    float: "right",
  },
  imgParabens: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    width: "48px",
    height: "48px",
    marginBottom: "20px",
  },
  corParabens: {
    color: "green",
  },
  mensagem: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.38",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#999999",
    marginBottom: "19px",
  },
});
