import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import validator from "email-validator";
import React from "react";
import ContainerAbreAnimado from "../../../shared/components/containerAbreAnimado";

export function UsuarioForm({
  classes,
  formularioAberto,
  nome,
  login,
  email,
  usuarioTableau,
  usuarioInterno,
  salvaUsuario,
  abreFechaFormNovoUsuario,
  setCamposFormUsuario,
  loginRepetido,
  emailRepetido,
  editando = false,
}) {
  const validarLogin = (login) => {
    const regexMaiusculasMinusculas = /^[a-zA-Z0-9_+-.]+$/;
    return regexMaiusculasMinusculas.test(String(login));
  };

  const validarEmail = (email) => {
    const resultadoValidacao = validator.validate(email);
    return resultadoValidacao;
  };

  const erroLogin = login && !validarLogin(login);
  const erroEmail = email && !validarEmail(email);

  const isButtonDisabled =
    !nome.length || !validarLogin(login) || !validarEmail(email);

  if (!formularioAberto) {
    return null;
  }

  return (
    <ContainerAbreAnimado isVisible={formularioAberto}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.container}>
            <div className={classes.inputContainer}>
              <TextField
                value={nome}
                autoFocus
                id={"txtNomeUsuario"}
                label={"Nome completo"}
                onChange={(e) =>
                  setCamposFormUsuario(
                    e.target.value.toUpperCase().trimLeft(),
                    "nome"
                  )
                }
                margin="normal"
                inputProps={{
                  maxLength: 50,
                }}
              />
              <TextField
                value={login}
                id={"txtLoginUsuario"}
                label={"Login do usuário"}
                margin="normal"
                error={erroLogin || loginRepetido}
                disabled={editando}
                helperText={
                  erroLogin ? (
                    `O login só pode conter caracteres alfanuméricos e '_', '+', '-', ou '.' `
                  ) : loginRepetido ? (
                    <div>{loginRepetido}</div>
                  ) : (
                    ""
                  )
                }
                onChange={(e) => {
                  setCamposFormUsuario(
                    e.target.value.toLowerCase().trimLeft(),
                    "login"
                  );
                }}
                inputProps={{
                  maxLength: 30,
                }}
              />
              <TextField
                value={email}
                id={"txtEmailUsuario"}
                label={"E-mail"}
                type="email"
                autoComplete="email"
                margin="normal"
                error={erroEmail || emailRepetido}
                helperText={
                  erroEmail ? (
                    "Digite um e-mail válido."
                  ) : emailRepetido ? (
                    <div>{emailRepetido}</div>
                  ) : (
                    ""
                  )
                }
                onChange={(e) =>
                  setCamposFormUsuario(
                    e.target.value.toLowerCase().trimLeft(),
                    "email"
                  )
                }
                inputProps={{
                  maxLength: 50,
                }}
              />
            </div>

            <div className={classes.switchContainer}>
              <FormControlLabel
                id="formAtivoUsuarioTableau"
                control={
                  <Switch
                    id="switchUsuarioTableau"
                    checked={usuarioTableau}
                    onChange={(e) =>
                      setCamposFormUsuario(e.target.checked, "usuarioTableau")
                    }
                  />
                }
                label="Usuário Tableau"
              />

              <FormControlLabel
                id="formAtivoUsuarioInterno"
                control={
                  <Switch
                    id="switchUsuarioInterno"
                    checked={usuarioInterno}
                    onChange={(e) =>
                      setCamposFormUsuario(e.target.checked, "usuarioInterno")
                    }
                  />
                }
                label="Usuário Interno"
              />
            </div>

            <div className={classes.footerContainer}>
              <Button
                id="btnCancelarFormUsuario"
                size="small"
                onClick={abreFechaFormNovoUsuario}
                style={{ color: "#307297" }}
              >
                Cancelar
              </Button>
              <Button
                id="btnSalvarFormUsuario"
                size="small"
                color="primary"
                style={{
                  backgroundColor: "#307297",
                  color: "#ffffff",
                  padding: "8px 28px",
                  borderRadius: "18px",
                }}
                onClick={salvaUsuario}
                disabled={isButtonDisabled}
              >
                Salvar
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </ContainerAbreAnimado>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "16px 8px 0 8px",
  },
  inputContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "32px",
  },
  switchContainer: {
    display: "flex",
    gap: "32px",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "30px",
  },
};

export default withStyles(styles)(UsuarioForm);
