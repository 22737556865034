import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FazendaPage from './FazendaPage';
import * as fazendaOperations from '../redux/fazendaOperations';
import * as clienteOperations from '../../cliente/redux/clienteOperations';

export class FazendaContainer extends PureComponent {
  async componentDidMount() {
    await this.props.obtemClientes();
    await this.props.obtemFazendas();
  }

  render() {
    return <FazendaPage id="fazendaPage" {...this.props.reduxProps} />;
  }
}

const mapDispatchToProps = {
  obtemClientes: clienteOperations.obtemClientes,
  obtemFazendas: fazendaOperations.obtemFazendas
};

FazendaContainer.defaultProps = {
  reduxProps: {
    fazendasCadastro: [],
    clientes: []
  }
};
const mapStateToProps = ({ fazenda, cliente }) => {
  return {
    reduxProps: {
      fazendasCadastro: [...fazenda.fazendasCadastro].reverse(),
      clientes: [...cliente.clientes]
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FazendaContainer);
