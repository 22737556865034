import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  atualizaPermissoesUsuarioAuth0,
  obtemUsuarioAuth0,
} from '../redux/usuarioAuth0VinculoOperations';
import UsuarioAuth0VinculoTab from './usuarioAuth0VinculoTab';
export class UsuarioAuth0VinculoTabContainer extends Component {
  state = {
    chamadaUsuarioAuthConcluida: false,
  };


  async componentDidMount() {
    const { usuario } = this.props;
    this.props.obtemUsuarioAuth0(usuario.Email, usuario.IdUsuario);
    this.setState({
      chamadaUsuarioAuthConcluida: true,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.chamadaUsuarioAuthConcluida === true;
  }

  render() {
    const {
      usuario,
      usuarioAuth0,
      atualizaPermissoesUsuarioAuth0
    } = this.props;

    return (
      <UsuarioAuth0VinculoTab
        usuario={usuario}
        usuarioAuth0={usuarioAuth0}
        chamadaUsuarioAuthConcluida={this.state.chamadaUsuarioAuthConcluida}
        atualizaPermissoesUsuarioAuth0={atualizaPermissoesUsuarioAuth0}
      />
    );
  }
}

const mapsDispatchToProps = {
  obtemUsuarioAuth0: obtemUsuarioAuth0,
  atualizaPermissoesUsuarioAuth0: atualizaPermissoesUsuarioAuth0,
};

export const mapStateToProps = ({ usuarioAuth }) => {
  return { usuarioAuth0: usuarioAuth.usuarioAuth0 };
};

UsuarioAuth0VinculoTabContainer.defaultProps = {
  usuario: {},
  usuarioAuth0: {
    permissoes: {},
  },
};
export default connect(
  mapStateToProps,
  mapsDispatchToProps
)(UsuarioAuth0VinculoTabContainer);
