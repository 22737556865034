import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';

export const selectVinculoLinha = props => {
  const { selects, excluirLinha } = props;
  return (
    <React.Fragment>
      <Grid item container direction="row">
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          id="selectsVinculo"
        >
          {selects}
          <IconButton aria-label="Exclui" onClick={excluirLinha}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
    </React.Fragment>
  );
};

selectVinculoLinha.propTypes = {
  selects: PropTypes.array.isRequired,
  excluirLinha: PropTypes.func.isRequired
};
const styles = theme => {
  return {
    margin: {
      margin: 20
    }
  };
};
export default withStyles(styles)(selectVinculoLinha);
