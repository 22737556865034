import { useState } from "react";
import history from "../../app/history";
import auth from "../Auth";

export function useForgotPassword({ notifyError, notifySuccess }) {
  const [error, setError] = useState(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setError(false);
      const email = ((event.target.email && event.target.email.value) || "")
        .toLowerCase()
        .trim();

      const regex = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]+(\.[a-z]+)?$/;
      const isValid = regex.test(email);

      if (!isValid) {
        setError(true);
        notifyError("Informe um e-mail válido");
        return;
      }

      await auth.resetPassword(email);

      notifySuccess("E-mail enviado com sucesso!");
    } catch (error) {
      const userNotFound = error.message.includes("404");
      setError(true);
      notifyError(
        userNotFound
          ? "Usuário não encontrado"
          : "Erro ao enviar e-mail de recuperação de senha"
      );
    }
  };

  const navigateToLogin = () => {
    history.replace("/login");
  };

  return { onSubmit, navigateToLogin, error };
}
