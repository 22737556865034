import { requisicaoComSucesso } from '../../shared/util/utils';
import UsuarioClienteService from './usuarioClienteVinculoService';
import { RetornaDadosBulkFinanceiro } from './usuarioClienteVinculoUtil';

export const MSG = {
  ERRO_OBTER_CLIENTES_USUARIO: 'Ocorreu um erro ao obter clientes do usuário',
  ERRO_SALVAR_VINCULOS_USUARIO_CLIENTES: 'Ocorreu um erro tentar salvar vínculos'
};
class UsuarioClienteVinculoManager {
  async obtemClientesUsuario(IdUsuario) {
    try {
      const respostaServico = await UsuarioClienteService.obtemClientesUsuario(IdUsuario);
      return respostaServico.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_CLIENTES_USUARIO);
    }
  }

  async salvarVinculosUsuarioCliente(IdUsuario, vinculosSalvar) {
    try {
      const idsClientesVincular = vinculosSalvar
        .filter(vinculo => vinculo.usuarioVinculado === true)
        .map(vinculoFiltrado => vinculoFiltrado.IdCliente);

      const respostaServico = await UsuarioClienteService.salvarVinculosUsuarioCliente(
        IdUsuario,
        idsClientesVincular
      );
      const { status } = respostaServico;
      return requisicaoComSucesso(status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_VINCULOS_USUARIO_CLIENTES);
    }
  }
  async salvarVinculosUsuarioClienteComFinanceiro(IdUsuario, vinculosSalvar, senha) {
    try {
      const dados = RetornaDadosBulkFinanceiro(vinculosSalvar, senha)

      const respostaServico = await UsuarioClienteService.salvarVinculosUsuarioClienteComFinanceiro(
        IdUsuario,
        dados
      );
      const { status } = respostaServico;
      return requisicaoComSucesso(status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_VINCULOS_USUARIO_CLIENTES);
    }
  }
}

export default new UsuarioClienteVinculoManager();
