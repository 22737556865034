import history from '../app/history';
import * as AuthService from './authService';

class Auth {
  async login(username, password) {
    try {
      const authServiceResponse = await AuthService.login(username, password);

      if (!authServiceResponse || !authServiceResponse.data) {
        throw new Error('Erro ao autenticar usuário');
      }

      await this.handleAuthentication(authServiceResponse.data);
    } catch (error) {
      const loginOrPasswordInvalid = error.message.includes("401");
      const notAllowed = error.message.includes("403");
      const userNotFound = error.message.includes("404");

      if (loginOrPasswordInvalid) {
        throw new Error("Usuário ou senha inválidos");
      }
      if (notAllowed) {
        throw new Error("Usuário não está autorizado a acessar essa aplicação");
      }
      if (userNotFound) {
        throw new Error("Usuário não encontrado");
      }
      throw new Error("Erro ao autenticar usuário");
    }
  }

  async resetPassword(email) {
    try {
      const authServiceResponse = await AuthService.resetPassword(email);

      if (!authServiceResponse || !authServiceResponse.data) {
        throw new Error('Erro ao enviar e-mail de recuperação de senha');
      }
    } catch (error) {
      console.error('[Auth.resetPassword] - ', error.message);
      throw error;
    }
  }

  async handleAuthentication(authResult) {
    const acessa_portal_configuracoes = authResult.permissions && authResult.permissions.acessa_portal_configuracoes;
    if (!acessa_portal_configuracoes) {
      throw new Error(
        'Usuário não possui permissão no portal de configurações'
      );
    }

    this.guardaDadosSessao(authResult);
    history.push('/funcionalidades');
  }

  guardaDadosSessao(authResult) {
    localStorage.setItem('access_token', authResult.access_token);
    localStorage.setItem('Email', authResult.email);
    localStorage.setItem('Nome', authResult.name);
    localStorage.setItem(
      'acessa_portal_configuracoes',
      authResult.permissions.acessa_portal_configuracoes
    );
  }

  isAuthenticated() {
    return !!localStorage.getItem('access_token');
  }

  emailCadastrado() {
    return localStorage.getItem('Email');
  }

  obtemDadosSessao() {
    return {
      accessToken: localStorage.getItem('access_token'),
    };
  }

  obtemDadosUsuario() {
    return {
      email: localStorage.getItem('Email'),
      nome: localStorage.getItem('Nome'),
      acessa_portal_configuracoes: localStorage.getItem(
        'acessa_portal_configuracoes'
      ),
    };
  }

  logout() {
    localStorage.clear();
    history.replace('/');
  }

  removeToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('acessa_portal_configuracoes');
  }
}

export default new Auth();
