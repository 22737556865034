import axios from 'axios';
import Auth from '../authentication/Auth';
export const URI_SERVICO_GERENCIADOR_AUTH =
  'usuarios/api/v1/token-management-auth';
export const URL_API_AUTH0 = `https://${process.env.REACT_APP_ENDPOINT_AUTH0}/api/v2`;

export const instance = axios.create({
  baseURL: URL_API_AUTH0,
});

class Auth0Service {
  async montaHeaderAuth0() {
    const userToken = Auth.obtemDadosSessao().accessToken;
    const { access_token } = await this.obtemTokenManagementAuth(
      userToken
    ).then((r) => r.data);
    return {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      qs: { search_engine: 'v3' },
    };
  }

  async obtemTokenManagementAuth(tokenAcessoUsuario) {
    try {
      const resposta = await axios.get(`${URI_SERVICO_GERENCIADOR_AUTH}`, {
        headers: {
          Authorization: `Bearer ${tokenAcessoUsuario}`,
        },
      });
      return resposta;
    } catch (error) {
      console.log('obtemTokenManagementAuth :', error);
    }
  }

  async obtemUsuarioAuthPeloEmail(emailUsuario) {
    const headers = await this.montaHeaderAuth0();
    const respostaAPIAuth0 = await instance.get(
      `/users?q=email:${emailUsuario}`,
      headers
    );
    return respostaAPIAuth0.data[0];
  }

  async obtemUsuarioAuthPeloUsername(usernameUsuario) {
    const headers = await this.montaHeaderAuth0();
    const respostaAPIAuth0 = await instance.get(
      `/users?q=username:${usernameUsuario}`,
      headers
    );
    return respostaAPIAuth0.data[0];
  }

  async atualizaPermissoesUsuarioAuth0(usuarioAuth, permissoes) {
    const headers = await this.montaHeaderAuth0();
    const respostaAPIAuth = await instance.patch(
      `/users/${usuarioAuth.user_id}`,
      {
        permissoes
      },
      headers
    );
    return respostaAPIAuth;
  }

  async retornaRole() {
    const headers = await this.montaHeaderAuth0();
    const respostaAPIAuth0 = await instance.get(`/roles`, headers);
    return respostaAPIAuth0;
  }

  async atualizaRole(idUsuario, idRole, deletaOuAdiciona) {
    const roles = { roles: [idRole] };
    if (deletaOuAdiciona === true) {
      const headers = await this.montaHeaderAuth0();
      const respostaAPIAuth0 = await instance.post(
        `/users/${idUsuario}/roles`,
        roles,
        headers
      );
      return respostaAPIAuth0;
    } else {
      const token = await this.obtemTokenManagementAuth();
      const respostaAPIAuth0 = await instance.delete(
        `/users/${idUsuario}/roles`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: roles,
        }
      );
      return respostaAPIAuth0;
    }
  }

  async excluiUsuarioAuth0(userId) {
    const headers = await this.montaHeaderAuth0();
    const respostaAuth0 = await instance.delete(`/users/${userId}`, headers);
    return respostaAuth0.status === 204;
  }

  async atualizaUsuarioAuth(userId, usuarioAuth) {
    const headers = await this.montaHeaderAuth0();
    const respostaAuth0 = await instance.patch(
      `/users/${userId}`,
      usuarioAuth,
      headers
    );
    return respostaAuth0.status === 200;
  }
}
export default new Auth0Service();
