import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as clienteActions from '../../cliente/redux/clienteActions';
import * as clienteOperations from '../../cliente/redux/clienteOperations';
import * as usuarioClienteVinculoOperations from '../redux/usuarioClienteVinculoOperations';
import { mapeiaClientesComVinculosUsuario } from '../redux/usuarioClienteVinculoSelectors';
import UsuarioClienteVinculoTab from './usuarioClienteVinculoTab';

function UsuarioClienteVinculoTabContainer(props) {
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const { variant } = props.reduxProps;
  const tabUsuarioClienteSelecionada = props.location.hash === '#usuariocliente';
  const IdUsuario = props.match.params.IdUsuario;
  const Usuario = props.usuario;

  const obtemVinculosClientes = async () => {
    await Promise.all([
      props.obtemClientesUsuario(IdUsuario),
      props.obtemClientes()
    ])
    setDadosCarregados(true);
  };

  const salvarVinculosUsuarioCliente = async vinculosSalvar => {
    props.salvarVinculosUsuarioCliente(IdUsuario, vinculosSalvar);
  };

  const salvarVinculosUsuarioClienteComFinanceiro = async (vinculosSalvar, senha) => {
    props.salvarVinculosUsuarioClienteComFinanceiro(IdUsuario, vinculosSalvar, senha);
  };

  useEffect(() => {
    if (tabUsuarioClienteSelecionada) {
      obtemVinculosClientes();
    } else {
      setDadosCarregados(false);
      props.setClientes([]);
    }
  }, [props.location.hash]);

  useEffect(() => {
    if (variant !== 'success') {
      props.setClientes([]);
    }
  }, [variant]);


  return (
    <React.Fragment>
      {!tabUsuarioClienteSelecionada ? null : (
        <UsuarioClienteVinculoTab
          clientes={props.reduxProps.clientes}
          dadosCarregados={dadosCarregados}
          salvarVinculosUsuarioCliente={salvarVinculosUsuarioCliente}
          salvarVinculosUsuarioClienteComFinanceiro={salvarVinculosUsuarioClienteComFinanceiro}
          senhaFinanceiro={Usuario.SenhaFinanceiro}
        />
      )}
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  obtemClientes: clienteOperations.obtemClientes,
  obtemClientesUsuario: usuarioClienteVinculoOperations.obtemClientesUsuario,
  salvarVinculosUsuarioCliente: usuarioClienteVinculoOperations.salvarVinculosUsuarioCliente,
  salvarVinculosUsuarioClienteComFinanceiro: usuarioClienteVinculoOperations.salvarVinculosUsuarioClienteComFinanceiro,
  setClientes: clienteActions.setClientes
};

export const mapStateToProps = state => {
  return {
    reduxProps: {
      clientes: mapeiaClientesComVinculosUsuario(state),
      variant: state.notificacao.variant
    }
  };
};

export { UsuarioClienteVinculoTabContainer };
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UsuarioClienteVinculoTabContainer)
);
