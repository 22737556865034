import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { withStyles } from '@material-ui/core';
export const containerAbreAnimado = props => {
  const { isVisible, classes } = props;
  return (
    <PoseGroup>
      {isVisible && (
        <DivAnimada
          key="divAnimada"
          className={classes.divAnimada}
          id="divAnimada"
        >
          {props.children}
        </DivAnimada>
      )}
    </PoseGroup>
  );
};
const styles = theme => {
  return {
    divAnimada: {
      borderRadius: 16,
      position: 'relative',
      width: '100%',
      height: 'auto',
      marginBottom: 30
    }
  };
};

const DivAnimada = posed.div({
  enter: {
    opacity: 1,
    beforeChildren: true,
    height: 'auto',
    transition: {
      default: { ease: 'easeOut', duration: 300 }
    }
  },
  exit: {
    opacity: 0,
    afterChildren: true,
    height: 0,
    transition: {
      default: { ease: 'easeOut', duration: 500 }
    }
  }
});

export default withStyles(styles)(containerAbreAnimado);
