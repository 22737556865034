import React from 'react';
import PropTypes from 'prop-types';
import {
  mapeiaFazendasEmOpcoesSelect,
  agruparRetirosPorFazenda,
  indexesVinculosRepetidos,
  marcaVinculosRepetidosNaLista,
  omiteVinculosRepetidos
} from '../usuarioRetiroVinculoUtils';
import UsuarioRetiroVinculoList from './usuarioRetiroVinculoList';
import { removeItemEmListaPeloIndex } from '../../../shared/util/utils';
import { ConteudoTabVinculo, AcoesVinculoTab } from '../../vinculos/';
import withNotificacaoHOC from '../../../shared/components/withNotificacao';
const MENSAGEM_VINCULOS_REPETIDOS = 'Existem vínculos repetidos na lista';

export class UsuarioRetiroVinculoTab extends React.Component {
  state = {
    retirosUsuario: [],
    listaFoiEditada: false
  };

  criaNovoVinculo = () => {
    const { retirosFazendas } = this.props;
    if (retirosFazendas.length) {
      const novoVinculo = {
        IdRetiro: -1,
        IdFazenda: -1
      };
      this.salvaAlteracoesVinculo(novoVinculo);
    }
  };

  salvaAlteracoesVinculo = (retiro, index) => {
    const copiaRetirosUsuario = this.retornaRetirosListaEditada();
    if (index >= 0) {
      copiaRetirosUsuario[index] = { ...retiro };
    } else {
      copiaRetirosUsuario.unshift(retiro);
    }
    this.setListaEditada(copiaRetirosUsuario);
  };

  setListaEditada = copiaRetirosUsuario => {
    this.setState({
      listaFoiEditada: true,
      retirosUsuario: omiteVinculosRepetidos(copiaRetirosUsuario)
    });
  };

  setVinculosRepetidos = idexesVinculosRepetidos => {
    this.setState({
      retirosUsuario: marcaVinculosRepetidosNaLista(
        idexesVinculosRepetidos,
        this.state.retirosUsuario
      )
    });
  };

  salvaVinculosUsuarioRetiro = () => {
    let vinculosRepetidos = [];
    const { notificaErro, usuario } = this.props;
    if (this.state.listaFoiEditada) {
      vinculosRepetidos = indexesVinculosRepetidos(this.state.retirosUsuario);
      if (vinculosRepetidos.length) {
        notificaErro(MENSAGEM_VINCULOS_REPETIDOS, false);
        this.setVinculosRepetidos(vinculosRepetidos);
      } else {
        this.setState({
          listaFoiEditada: false
        });
        this.props.salvaVinculosUsuarioRetiro(usuario.IdUsuario, this.state.retirosUsuario);
      }
    }
  };

  excluiUsuarioRetiroVinculo = index => {
    let copiaRetiros = this.retornaRetirosListaEditada();
    this.setListaEditada(removeItemEmListaPeloIndex(copiaRetiros, index));
  };

  retornaRetirosListaEditada = () => {
    const { listaFoiEditada, retirosUsuario } = this.state;
    return listaFoiEditada ? retirosUsuario : this.props.retirosUsuario;
  };

  renderVinculos = () => {
    const { retirosFazendas, fazendasUsuario } = this.props;
    const retirosUsuarioRenderizar = this.retornaRetirosListaEditada();
    let opcoesSelectFazenda = mapeiaFazendasEmOpcoesSelect(fazendasUsuario);
    const retirosFazendasAgrupadosPorFazenda = agruparRetirosPorFazenda(retirosFazendas);
    return (
      <UsuarioRetiroVinculoList
        excluiUsuarioRetiroVinculo={this.excluiUsuarioRetiroVinculo}
        retirosUsuario={retirosUsuarioRenderizar}
        opcoesSelectFazenda={opcoesSelectFazenda}
        retirosAgrupadosPorFazenda={retirosFazendasAgrupadosPorFazenda}
        salvaAlteracoesVinculo={this.salvaAlteracoesVinculo}
      />
    );
  };
  render() {
    const { listaFoiEditada } = this.state;
    const { chamadaDadosConcluida, dadosListaCarregados } = this.props;
    return (
      <ConteudoTabVinculo
        acoesVinculoTab={
          <AcoesVinculoTab
            funcaoCriarNovo={this.criaNovoVinculo}
            funcaoSalvarAlteracoes={this.salvaVinculosUsuarioRetiro}
            desabilitaAcaoSalvar={listaFoiEditada}
          />
        }
        chamadaDadosConcluida={chamadaDadosConcluida}
        dadosListaCarregados={dadosListaCarregados}
        listaVinculos={this.renderVinculos()}
      />
    );
  }
}

UsuarioRetiroVinculoTab.displayName = 'UsuarioRetiroVinculoTab';
UsuarioRetiroVinculoTab.propTypes = {
  retirosFazendas: PropTypes.arrayOf(
    PropTypes.shape({
      IdFazenda: PropTypes.number,
      IdRetiro: PropTypes.number,
      Nome: PropTypes.string
    })
  ).isRequired,
  retirosUsuario: PropTypes.arrayOf(
    PropTypes.shape({
      IdFazenda: PropTypes.number,
      IdRetiro: PropTypes.number,
      repetido: PropTypes.bool
    })
  ).isRequired,
  fazendasUsuario: PropTypes.arrayOf(
    PropTypes.shape({
      IdFazenda: PropTypes.number,
      Nome: PropTypes.string
    })
  ).isRequired,
  chamadaDadosConcluida: PropTypes.bool.isRequired,
  dadosListaCarregados: PropTypes.bool.isRequired,
  notificaErro: PropTypes.func.isRequired,
  salvaVinculosUsuarioRetiro: PropTypes.func.isRequired
};
export default withNotificacaoHOC(UsuarioRetiroVinculoTab);
