import IngredienteService from './ingredienteService';
import _ from 'lodash';

export const MSG = {
  ERRO_OBTER_INGREDIENTES: 'Ocorreu um erro ao tentar retornar ingredientes.',
  ERRO_SALVAR_INGREDIENTE: 'Ocorreu erro ao tentar salvar dados do ingrediente',
  ERRO_EXCLUIR_INGREDIENTE: 'Ocorreu erro ao tentar exlcuir ingrediente'
};

class IngredienteManager {
  async obtemIngredientesIdNome(filtro = {}) {
    try {
      const filter = {
        ...filtro,
        fields: ['IdIngrediente', 'Nome']
      };
      const ingredientes = await IngredienteService.obtemIngredientes(
        '?filter=' + JSON.stringify(filter)
      );
      return ingredientes.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_INGREDIENTES);
    }
  }

  async obtemIngredientes() {
    try {
      const respostaServicoIngrediente = await IngredienteService.obtemIngredientes();
      const ingredientes = respostaServicoIngrediente.data;
      const ingredientesUpperCase = ingredientes.map(ingrediente => {
        ingrediente.Nome = ingrediente.Nome.toUpperCase();
        return ingrediente;
      });
      const ingredientesOrdenados = _.orderBy(ingredientesUpperCase, ['Nome'], ['desc']);
      return ingredientesOrdenados;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_INGREDIENTES);
    }
  }

  async salvaIngrediente(IdIngrediente, ingredienteSalvar) {
    try {
      let respostaServicoIngrediente;
      if (IdIngrediente) {
        respostaServicoIngrediente = await IngredienteService.salvaIngrediente(
          IdIngrediente,
          ingredienteSalvar
        );
      } else {
        respostaServicoIngrediente = await IngredienteService.cadastraIngrediente(
          ingredienteSalvar
        );
      }
      const ingredienteSalvo = respostaServicoIngrediente.status === 200;
      return ingredienteSalvo;
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_INGREDIENTE);
    }
  }

  async excluiIngrediente(IdIngrediente) {
    try {
      const respostaServicoIngrediente = await IngredienteService.excluiIngrediente(IdIngrediente);
      const ingredienteExcluida = respostaServicoIngrediente.status === 204;
      return ingredienteExcluida;
    } catch (error) {
      throw new Error(MSG.ERRO_EXCLUIR_INGREDIENTE);
    }
  }
  async obtemTipoIngredientes() {
    try {
      const respostaServicoTipoIngrediente = await IngredienteService.obtemTipoIngredientes();
      const tiposIngredientes = respostaServicoTipoIngrediente.data;
      const tiposIngredientesUpperCase = tiposIngredientes.map(tipoIngrediente => {
        tipoIngrediente.Nome = tipoIngrediente.Nome.toUpperCase();
        return tipoIngrediente;
      });
      const tiposIngredientesOrdenados = _.orderBy(tiposIngredientesUpperCase, ['Nome'], ['desc']);
      return tiposIngredientesOrdenados;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_INGREDIENTES);
    }
  }
}
export default new IngredienteManager();
