import {
  FormControl,
  Input,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import React from "react";

const CountryStatePickerPage = ({
  estado,
  pais,
  listaPaises,
  listaEstados,
  estadoBloqueado,
  setEstado,
  setPais,
}) => {
  return (
    <>
      <FormControl style={{ padding: "0 0 30px 0" }}>
        <InputLabel htmlFor="age-native-label-placeholder">País *</InputLabel>
        <NativeSelect
          id="paisFazenda"
          value={pais}
          onChange={(e) => setPais(e.target.value)}
          input={<Input name="pais" id="paisFazenda" />}
        >
          <option value="" />
          {listaPaises.map((pais) => (
            <option key={pais.code} value={pais.code}>
              {pais.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormControl disabled={estadoBloqueado} style={{ padding: "0 0 30px 0" }}>
        <InputLabel htmlFor="age-native-label-placeholder">Estado *</InputLabel>
        <NativeSelect
          id="estadoFazenda"
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
          input={<Input name="estado" id="estadoFazenda" />}
        >
          <option value="" />

          {listaEstados.map((estado) => (
            <option key={estado.code} value={estado.code}>
              {estado.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </>
  );
};

export default CountryStatePickerPage;
