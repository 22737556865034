import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Typography,
  Grid
} from '@material-ui/core';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, info) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, info);
  // }

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          container
          style={{ padding: '25%' }}
          justify="center"
          alignItems="center"
        >
          <Grid item style={{ padding: '20px 0' }}>
            <Typography variant="h4">Ocorreu um erro inesperado</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="default"
              onClick={() => this.props.history.replace('/')}
            >
              Voltar para página inicial
            </Button>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
