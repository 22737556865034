export const SET_FAZENDAS_USUARIO = 'usuariofazendaretiro/SET_FAZENDAS_USUARIO';
export const SET_RETIROS_FAZENDAS = 'usuariofazendaretiro/SET_RETIROS_FAZENDAS';
export const SET_RETIROS_USUARIO = 'usuariofazendaretiro/SET_RETIROS_USUARIO';
export const RESET_RETIROS_USUARIO = 'usuariofazendaretiro/RESET_RETIROS_USUARIO';

export const setFazendasUsuario = fazendasUsuario => {
  return {
    type: SET_FAZENDAS_USUARIO,
    payload: fazendasUsuario
  };
};

export const setRetirosFazendas = retirosFazendas => {
  return {
    type: SET_RETIROS_FAZENDAS,
    payload: retirosFazendas
  };
};

export const setRetirosUsuario = retirosUsuario => {
  return {
    type: SET_RETIROS_USUARIO,
    payload: retirosUsuario
  };
};

export const resetRetirosUsuario = () => {
  return {
    type: RESET_RETIROS_USUARIO
  };
};
