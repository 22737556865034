import { combineReducers } from 'redux';
import { clienteReducers } from '../features/cliente/redux';
import { fabricanteReducers } from '../features/fabricante/redux';
import { fazendaReducers } from '../features/fazenda/redux';
import { ingredienteReducers } from '../features/ingrediente/redux';
import { notificacaoReducers } from '../features/notificacao/redux';
import { relatorioReducer } from '../features/relatorioTableau/redux';
import toggleReducers from '../features/toggle/redux/toggleFeatureReducers';
import { usuarioReducers } from '../features/usuario/redux';
import usuarioAuthReducers from '../features/usuarioAuth0/redux/usuarioAuth0VinculoReducers';
import { usuarioClienteVinculoReducers } from '../features/usuarioClienteVinculo/redux';
import { usuarioFazendaVinculoReducers } from '../features/usuarioFazendaVinculo/redux';
import { usuarioRetiroVinculoReducers } from '../features/usuarioRetiroVinculo/redux';
import loadingReducer from '../shared/components/Loading/redux/loadingReducer';
import UIReducers from '../shared/redux/UIReducers';
const reducer = combineReducers({
  toggleFeature: toggleReducers,
  notificacao: notificacaoReducers,
  fazenda: fazendaReducers,
  interface: UIReducers,
  cliente: clienteReducers,
  usuario: usuarioReducers,
  ingrediente: ingredienteReducers,
  fabricante: fabricanteReducers,
  usuarioAuth: usuarioAuthReducers,
  usuarioFazendaVinculo: usuarioFazendaVinculoReducers,
  usuarioRetiroVinculo: usuarioRetiroVinculoReducers,
  usuarioClienteVinculo: usuarioClienteVinculoReducers,
  relatorios: relatorioReducer,
  loading: loadingReducer
});
export default reducer;
