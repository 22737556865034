import { Grid, IconButton } from "@material-ui/core/";
import { Delete, Edit } from "@material-ui/icons";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  DialogConfirmacaoAcao,
  ExpansionItem,
} from "../../../shared/components";
import { excluiFazenda, salvaFazenda } from "../redux";
import FazendaForm from "./fazendaForm";
import FazendaView from "./fazendaView";
import {
  TEXTO_DIALOG_EXLUSAO,
  TITULO_DIALOG_EXLUSAO,
} from "./textosFazenda.ig";

export class FazendaItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmacaoExclusaoAberto: false,
    };
  }

  componentDidMount() {
    const { fazenda, clientes } = this.props;
    const ClienteFinanceiro = _.find(clientes, function (c) {
      return (
        Number(c.IdCliente) === Number(fazenda.IdCliente) &&
        c.ClienteFinanceiro === true
      );
    });

    this.setState({
      nome: fazenda.Nome,
      nomeReduzido: fazenda.NomeReduzido,
      codigoFazendaProtheus: fazenda.CodigoProtheus,
      pais: fazenda.Pais,
      estado: fazenda.Estado,
      cidade: fazenda.Cidade,
      nomeCliente: fazenda.NomeCliente,
      idCliente: fazenda.IdCliente,
      ativo: fazenda.Ativo,
      numeroLeituraAlturaPasto: fazenda.NumeroLeituraAlturaPasto,
      idContaFinanceiro: fazenda.IdContaFinanceiro,
      idClienteFinanceiro: 0,
      permitirAcessoFinanceiro:
        ClienteFinanceiro !== null && ClienteFinanceiro !== undefined,
      acessoFinanceiro: fazenda.ClienteFinanceiro === 1,
      CNPJ: fazenda.CNPJ,
    });
  }

  abreFechaFormNovaFazenda = () => {
    const { fazenda, clientes } = this.props;
    const ClienteFinanceiro = _.find(clientes, function (c) {
      return (
        Number(c.IdCliente) === Number(fazenda.IdCliente) &&
        c.ClienteFinanceiro === true
      );
    });

    this.setState({
      nome: fazenda.Nome,
      nomeReduzido: fazenda.NomeReduzido,
      codigoFazendaProtheus: fazenda.CodigoProtheus,
      pais: fazenda.Pais,
      estado: fazenda.Estado,
      cidade: fazenda.Cidade,
      nomeCliente: fazenda.NomeCliente,
      idCliente: fazenda.IdCliente,
      ativo: fazenda.Ativo,
      numeroLeituraAlturaPasto: fazenda.NumeroLeituraAlturaPasto,
      idContaFinanceiro: fazenda.idContaFinanceiro,
      idClienteFinanceiro: 0,
      permitirAcessoFinanceiro:
        ClienteFinanceiro !== null && ClienteFinanceiro !== undefined,
      acessoFinanceiro: fazenda.ClienteFinanceiro === 1,
      CNPJ: fazenda.CNPJ,
    });
    this.props.expandeFazendaItem(fazenda.IdFazenda);
  };

  abreDialogExlcusao = () => {
    this.setState((prevState) => {
      return {
        confirmacaoExclusaoAberto: !prevState.confirmacaoExclusaoAberto,
      };
    });
  };

  fechaDialogExlcusao = () => {
    this.setState((prevState) => {
      return {
        confirmacaoExclusaoAberto: !prevState.confirmacaoExclusaoAberto,
      };
    });
  };

  setExpanded = () => {
    this.props.expandeFazendaItem(this.props.fazenda.IdFazenda);
  };

  salvaFazenda = async () => {
    const { fazenda } = this.props;

    if (this.state.ativo === false) {
      await this.props.salvaFazenda(fazenda.IdFazenda, {
        Nome: this.state.nome,
        NomeReduzido: this.state.nomeReduzido,
        CodigoProtheus: this.state.codigoFazendaProtheus,
        Pais: this.state.pais,
        Estado: this.state.estado,
        Cidade: this.state.cidade,
        Ativo: this.state.ativo,
        IdCliente: this.state.idCliente,
        NumeroLeituraAlturaPasto: this.state.numeroLeituraAlturaPasto,
        ClienteFinanceiro: this.state.acessoFinanceiro ? 1 : 0,
        CNPJ: this.state.CNPJ,
        IdContaFinanceiro: this.state.idContaFinanceiro,
        IdUsuarioFinanceiro: this.state.idClienteFinanceiro,
        Coordenadas: [],
      });
      await this.props.excluiFazenda(fazenda.IdFazenda);
    } else {
      await this.props.salvaFazenda(fazenda.IdFazenda, {
        Nome: this.state.nome,
        NomeReduzido: this.state.nomeReduzido,
        CodigoProtheus: this.state.codigoFazendaProtheus,
        Pais: this.state.pais,
        Estado: this.state.estado,
        Cidade: this.state.cidade,
        Ativo: this.state.ativo,
        IdCliente: this.state.idCliente,
        NumeroLeituraAlturaPasto: this.state.numeroLeituraAlturaPasto,
        ClienteFinanceiro: this.state.acessoFinanceiro ? 1 : 0,
        CNPJ: this.state.CNPJ,
        IdContaFinanceiro: this.state.idContaFinanceiro,
        IdUsuarioFinanceiro: this.state.idClienteFinanceiro,
        Coordenadas: [],
        Excluido: false,
        DataExclusao: null,
        UsuarioExclusao: null,
      });
    }
    this.props.expandeFazendaItem(this.props.fazenda.IdFazenda);
  };

  excluiFazenda = () => {
    const { fazenda } = this.props;
    this.props.excluiFazenda(fazenda.IdFazenda);
    this.fechaDialogExlcusao();
  };

  setCamposFormFazenda = (value, campo) => {
    this.setState({
      [campo]: value,
    });
  };

  /* istanbul ignore next */
  verificaClienteUsaFinanceiro = (value, campo) => {
    /* istanbul ignore next */
    this.setCamposFormFazenda(value, campo);
    const { clientes } = this.props;

    /* istanbul ignore next */
    const ClienteFinanceiro = _.find(clientes, function (c) {
      return (
        Number(c.IdCliente) === Number(value) && c.ClienteFinanceiro === true
      );
    });

    /* istanbul ignore next */
    if (ClienteFinanceiro) {
      /* istanbul ignore next */
      this.setCamposFormFazenda(true, "permitirAcessoFinanceiro");
      /* istanbul ignore next */
      this.setCamposFormFazenda(
        ClienteFinanceiro.IdUsuarioFinanceiro,
        "idClienteFinanceiro"
      );
      /* istanbul ignore next */
    } else {
      /* istanbul ignore next */
      this.setCamposFormFazenda(false, "permitirAcessoFinanceiro");
      /* istanbul ignore next */
      this.setCamposFormFazenda(false, "acessoFinanceiro");
      /* istanbul ignore next */
      this.setCamposFormFazenda("", "CNPJ");
      /* istanbul ignore next */
      this.setCamposFormFazenda(0, "idClienteFinanceiro");
      /* istanbul ignore next */
    }
  };

  renderFazendaFormView = () => {
    const { fazenda, clientes, idFazendaSelecionada, classes } = this.props;
    const {
      nome,
      nomeReduzido,
      codigoFazendaProtheus,
      pais,
      estado,
      cidade,
      nomeCliente,
      ativo,
      idCliente,
      numeroLeituraAlturaPasto,
      permitirAcessoFinanceiro,
      acessoFinanceiro,
      CNPJ,
    } = this.state;

    if (idFazendaSelecionada === fazenda.IdFazenda) {
      return (
        <Grid
          container
          direction="column"
          style={{ padding: "16px 8px 0 8px" }}
        >
          <FazendaForm
            nome={nome}
            nomeReduzido={nomeReduzido}
            codigoFazendaProtheus={codigoFazendaProtheus}
            pais={pais}
            estado={estado}
            cidade={cidade}
            nomeCliente={nomeCliente}
            ativo={ativo}
            clientes={clientes}
            idCliente={idCliente}
            numeroLeituraAlturaPasto={numeroLeituraAlturaPasto}
            setCamposFormFazenda={this.setCamposFormFazenda}
            salvaFazenda={this.salvaFazenda}
            abreFechaFormNovaFazenda={this.abreFechaFormNovaFazenda}
            verificaClienteUsaFinanceiro={this.verificaClienteUsaFinanceiro}
            permitirAcessoFinanceiro={permitirAcessoFinanceiro}
            acessoFinanceiro={acessoFinanceiro}
            CNPJ={CNPJ}
          />
        </Grid>
      );
    }
    return (
      <FazendaView
        fazenda={fazenda}
        classes={classes}
        expandeFazendaItem={this.setExpanded}
      />
    );
  };

  renderAcoesItem = () => {
    return (
      <React.Fragment>
        <IconButton
          aria-label="Edit"
          onClick={this.setExpanded}
          id="btnExpandeItem"
        >
          <Edit />
        </IconButton>
        <IconButton
          aria-label="Delete"
          onClick={this.abreDialogExlcusao}
          id="btnExluiFazenda"
        >
          <Delete />
        </IconButton>
      </React.Fragment>
    );
  };

  render() {
    const { fazenda, idFazendaSelecionada } = this.props;
    const { confirmacaoExclusaoAberto } = this.state;
    const itemAberto = idFazendaSelecionada === fazenda.IdFazenda;
    return (
      <React.Fragment>
        <DialogConfirmacaoAcao
          id={`dialogConfirmaExclusaoFazenda`}
          tituloDialog={TITULO_DIALOG_EXLUSAO}
          textoDialog={
            <span id="mensagemConfirmaExclusaoFazenda">
              {TEXTO_DIALOG_EXLUSAO.replace(/<nome>/g, fazenda.Nome)}
            </span>
          }
          dialogAberto={confirmacaoExclusaoAberto}
          cancelarAcaoDialog={this.fechaDialogExlcusao}
          confirmarAcaoDialog={this.excluiFazenda}
        />
        <ExpansionItem
          id={`expansionItemFazenda${fazenda.IdFazenda}`}
          conteudo={this.renderFazendaFormView()}
          acoesItem={this.renderAcoesItem()}
          itemAberto={itemAberto}
          onChange={this.abreFechaFormNovaFazenda}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  salvaFazenda: salvaFazenda,
  excluiFazenda: excluiFazenda,
};

export default compose(connect(null, mapDispatchToProps))(FazendaItem);
