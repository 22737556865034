import React from "react";
import { withStyles } from "@material-ui/core";

export const SkeletonLoading = ({ classes, ...props }) => {
  return (
    <div>
      <div className={classes.animatedBackground} style={{ ...props }} />
    </div>
  );
};

const styles = () => ({
  "@keyframes placeHolderShimmer": {
    "0%": {
      backgroundPosition: "-468px 0",
    },
    "100%": {
      backgroundPosition: "468px 0",
    },
  },
  animatedBackground: {
    animationDuration: "1s",
    animationFillMode: "forwards",
    animationIterationCount: "infinite",
    animationName: "placeHolderShimmer",
    animationTimingFunction: "linear",
    background:
      "linear-gradient(to right, #cccccc 9%, #bbbbbb 19%, #cccccc 34%)",
    backgroundSize: "800px 104px",
    width: "1200px",
    height: "650px",
    position: "relative",
    display: "inline-block",
  },
});

export default withStyles(styles)(SkeletonLoading);
