import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { uniqueId } from "lodash";
import React, { useMemo } from "react";
import { RegexDate } from "../../../constants/regex";

export const CustomTableBody = ({
  classes,
  linhas,
  colunas,
  aoClicarNaLinha,
  propriedadesLinhas,
  aoClicarNaLinhaCursor,
  isPrinting,
  hover = true,
}) => {
  const idUnico = useMemo(() => uniqueId(), []);
  const geradorDeChavesEIds = (indice, sufixo) =>
    `${idUnico}_${indice}_${sufixo}`;

  const selecionarClasses = (linha, coluna) => {
    const value = coluna.pegaValor
      ? coluna.pegaValor(linha)
      : coluna.valor || "";

    if (RegexDate.test(value)) {
      return classes.dateCell;
    }
    return classes.bodyCell;
  };

  const calculaPropriedadesCustomizadas = (coluna, linha) => {
    if (coluna.propriedadesCustomizadas) {
      if (typeof coluna.propriedadesCustomizadas === "function") {
        return coluna.propriedadesCustomizadas(linha);
      }
      return coluna.propriedadesCustomizadas;
    }
    return {};
  };

  const getCellClasses = (linha, coluna) =>
    [selecionarClasses(linha, coluna), coluna.cellClass].join(" ");

  const getPropriedadesLinhas = (linha) => {
    if (propriedadesLinhas && propriedadesLinhas.style) {
      return {
        ...propriedadesLinhas,
        style: propriedadesLinhas.style(linha)
      };
    }
    return propriedadesLinhas;
  };

  return (
    <TableBody>
      {linhas.map((linha, indiceLinha) => {
        if (!linha) return null;
        return (
          <TableRow
            hover={hover}
            onClick={() => aoClicarNaLinha(linha)}
            tabIndex={-1}
            key={geradorDeChavesEIds(indiceLinha, "", "chave")}
            {...getPropriedadesLinhas(linha)}
            className={`${isPrinting ? "page-break" : ""} 
            ${aoClicarNaLinhaCursor && classes.aoClicarNaLinhaCursor
              }`}
          >
            {colunas.map((coluna, indiceColuna) => {
              if (coluna.hidden && coluna.hidden(linha)) return null;
              return (
                <TableCell
                  key={geradorDeChavesEIds(indiceLinha, indiceColuna, "celula")}
                  component={coluna.component || "td"}
                  id={geradorDeChavesEIds(indiceLinha, indiceColuna, "celula")}
                  scope="linha"
                  className={getCellClasses(linha, coluna)}
                  style={{
                    ...(coluna.estilo || {
                      padding: "4px 0 4px 24px",
                      maxWidth: "140px",
                      wordWrap: "break-word",
                    }),
                  }}
                  {...calculaPropriedadesCustomizadas(coluna, linha)}
                >
                  {coluna.pegaValor
                    ? coluna.pegaValor(linha, indiceLinha)
                    : coluna.valor || "-"}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};