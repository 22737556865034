import memoize from 'memoize-one';
import _ from 'lodash';

export const mapeiaFazendasEmOpcoesSelect = memoize(fazendasUsuario => {
  return [...fazendasUsuario].map(fazenda => {
    return {
      label: `${fazenda.Nome} (${fazenda.IdFazenda})`,
      value: fazenda.IdFazenda,
    };
  });
});

export const indexesVinculosRepetidos = vinculos => {
  return _.filter(
    _.uniq(
      _.map(vinculos, (item, index) => {
        if (
          _.filter(vinculos, {
            IdFazenda: item.IdFazenda,
          }).length > 1
        ) {
          return index;
        }

        return false;
      })
    ),
    value => {
      return value;
    }
  );
};

export const omiteVinculosRepetidos = copiaFazendasUsuario => {
  return [...copiaFazendasUsuario].map(fazenda => _.omit(fazenda, 'repetido'));
};

export const marcaVinculosRepetidosNaLista = (indexesVinculosRepetidos, fazendasUsuario) => {
  const copiaFazendasUsuario = [...fazendasUsuario];
  indexesVinculosRepetidos.forEach(indexVinculoRepetido => {
    const copiaFazendaUsuario = {
      ...copiaFazendasUsuario[indexVinculoRepetido],
    };
    copiaFazendaUsuario.repetido = true;
    copiaFazendasUsuario[indexVinculoRepetido] = copiaFazendaUsuario;
  });
  return copiaFazendasUsuario;
};

export const montaFiltroFazendasUsuario = idsFazendasUsuario => {
  const filtroFazendas = {
    where: {
      IdFazenda: { $in: idsFazendasUsuario },
      Excluido: false,
    },
  };
  return filtroFazendas;
};

export const mapeiaFazendasUsuarioParaIdsFazendasUnicos = fazendas => {
  return Array.from(new Set([...fazendas].map(fazenda => fazenda.IdFazenda)));
};

export const RetornaDadosBulkFinanceiro = (Clientes, Senha) => {
  const CLientesVinculados = _.filter(Clientes, function (cliente) {
    return cliente.usuarioVinculado === true;
  });

  const dadosCliente = _.map(CLientesVinculados, function (dado) {
    return {
      IdFazenda: dado.IdFazenda,
      ClienteFinanceiro: dado.ClienteFinanceiro === 1,
      IdContaFinanceiro: dado.IdContaFinanceiro
    };
  });
  return {
    Fazendas: dadosCliente,
    Usuario: {
      SenhaFinanceiro: Senha
    }
  };

};
export const VerificaClienteAcessoFinanceiro = Clientes => {
  const CLientesComAcessoAoFinanceiro = _.filter(Clientes, function (cliente) {
    return cliente.ClienteFinanceiro === 1 && cliente.usuarioVinculado === true;
  });
  return CLientesComAcessoAoFinanceiro.length > 0;
}
