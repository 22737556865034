import { useState } from 'react';
export const useSelecionarTodosVinculos = (vinculosSalvar = []) => {
  const [todosSelecionados, setTodosSelecionados] = useState(false);
  const selecionarTodos = event => {
    vinculosSalvar.forEach(vinculoSalvar => {
      vinculoSalvar.usuarioVinculado = event.target.checked;
    });
    setTodosSelecionados(event.target.checked);
  };
  return { todosSelecionados, setTodosSelecionados, selecionarTodos, vinculosSalvar };
};
