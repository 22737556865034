import axios from 'axios';
import auth from '../../features/authentication/Auth';

axios.defaults.baseURL = `${process.env.REACT_APP_ENDPOINT_API}`;

axios.interceptors.request.use(
  function (config) {
    config.headers = {
      Authorization: `Bearer ${auth.obtemDadosSessao().accessToken}`,
      usuario: auth.obtemDadosUsuario().email
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/login';
      localStorage.clear();
      return Promise.resolve({});
    }
    return Promise.reject(error);
  }
);
