import axios from 'axios';

const URI_USUARIO = '/usuarios/api/v1/usuarios';
const URL_USUARIO_V2 = '/usuarios/api/v2/usuarios';

class UsuarioFazendaService {
  async salvaVinculosUsuarioFazenda(IdUsuario, idsFazendasVincular) {
    return axios.post(`${URL_USUARIO_V2}/${IdUsuario}/fazendas/bulk`, idsFazendasVincular)
  }
  async salvaVinculosUsuarioFazendaFinanceiro(IdUsuario, fazendasVincular) {
    return axios.post(`${URI_USUARIO}/${IdUsuario}/fazendas/financeiro/bulk`, fazendasVincular)
  }
}

export default new UsuarioFazendaService();
