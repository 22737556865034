import _ from 'lodash';
export const incluiIdsFazendasHabilitadas = (IdFazenda, fazendasHabilitadas) => {
  const index = _.findIndex(fazendasHabilitadas, fazenda => {
    return fazenda.IdFazenda === IdFazenda;
  });
  if (index >= 0) {
    fazendasHabilitadas = fazendasHabilitadas.filter((fazenda, idx) => idx !== index);
    return fazendasHabilitadas;
  } else {
    fazendasHabilitadas.push({
      IdFazenda: IdFazenda
    });
    return fazendasHabilitadas;
  }
};

export const mapeiaIdsFazendasHabilitadastoggle = fazendasHabilitadas => {
  return fazendasHabilitadas.map(fazenda => {
    return { IdFazenda: fazenda.IdFazenda };
  });
};
