/* eslint-disable react/display-name */
import { withStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Page } from "../../../shared/components";
import CustomTable from "../../../shared/components/CustomTable";
import {
  ClienteAcaoEnum,
  EVENTO_CRIACAO,
  MOTIVO_CRIACAO,
  columnsHistory,
  formatToDate,
  formatToHour,
} from "../clienteUtils";
import * as clienteOperations from "../redux/clienteOperations";

const columns = [
  {
    id: columnsHistory.USER.id,
    titulo: <span>{columnsHistory.USER.title}</span>,
    ordenavel: false,
    pegaValor: (linha) => (
      <div>
        <span>{linha.UsuarioCriacao}</span>
      </div>
    ),
  },
  {
    id: columnsHistory.ACTION.id,
    titulo: <span>{columnsHistory.ACTION.title}</span>,
    ordenavel: false,
    pegaValor: (linha) => (
      <div>
        <span>{ClienteAcaoEnum[linha.Evento]}</span>
      </div>
    ),
  },
  {
    id: columnsHistory.DATE.id,
    titulo: <span>{columnsHistory.DATE.title}</span>,
    ordenavel: false,
    pegaValor: (linha) => (
      <div>
        <span>{formatToDate(linha.DataCriacao)}</span>
      </div>
    ),
  },
  {
    id: columnsHistory.HOUR.id,
    titulo: <span>{columnsHistory.HOUR.title}</span>,
    ordenavel: false,
    pegaValor: (linha) => (
      <div>
        <span>{formatToHour(linha.DataCriacao)}</span>
      </div>
    ),
  },
  {
    id: columnsHistory.MOTIVE.id,
    titulo: <span>{columnsHistory.MOTIVE.title}</span>,
    ordenavel: false,
    pegaValor: (linha) => (
      <div>
        <span>{linha.Motivo}</span>
      </div>
    ),
  },
];

const ClientHistory = ({
  classes,
  obtemClientePorIdIncluindoExcluidos,
  obtemHistoricoCliente,
  history,
  match,
}) => {
  const [clientData, setClientData] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  const clientName = clientData ? clientData.NomeReduzido : "Cliente";
  const ClientId = match.params.IdCliente
    ? parseInt(match.params.IdCliente)
    : null;

  const allData = useMemo(() => {
    if (!clientData) return historyData;

    const clientCreation = {
      DataCriacao: clientData.DataCriacao,
      UsuarioCriacao: clientData.UsuarioCriacao,
      Motivo: MOTIVO_CRIACAO,
      Evento: EVENTO_CRIACAO,
    };

    return [...historyData, clientCreation];
  }, [clientData, historyData]);

  const getHistory = async () => {
    try {
      if (ClientId) {
        const page = 0;
        const quantity = 100;
        const response = await obtemHistoricoCliente(ClientId, page, quantity);
        if (response.data) {
          setHistoryData(response.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getClient = async () => {
    try {
      if (ClientId) {
        const response = await obtemClientePorIdIncluindoExcluidos(ClientId);

        if (response) {
          setClientData(response);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getHistory();
    getClient();
  }, []);

  return (
    <Page
      titulo="Histórico do Cliente"
      goBackArea={{
        action: handleGoBack,
        text: clientName,
      }}
      conteudo={
        <div className={classes.wrapperTable}>
          <CustomTable
            classes={classes}
            modoOrdenacao={"LOCAL"}
            linhas={allData}
            colunas={columns}
            tabelaEsquerda={true}
            ativarPaginacao={false}
          />
        </div>
      }
    />
  );
};

const styles = () => ({
  wrapperTable: {
    width: "100%",
  },
});

const mapDispatchToProps = {
  obtemClientePorIdIncluindoExcluidos:
    clienteOperations.obtemClientePorIdIncluindoExcluidos,
  obtemHistoricoCliente: clienteOperations.obtemHistoricoCliente,
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(ClientHistory);
