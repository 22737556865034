export const fazendasUsuarioSomenteIdsMock = [{ IdFazenda: 16 }, { IdFazenda: 74 }];

export const fazendasUsuarioMock = [
  { IdFazenda: 16, Nome: 'Fazenda Modelo 1' },
  { IdFazenda: 74, Nome: 'Fazenda Modelo 3' }
];

export const retirosFazendaMock = [
  {
    IdRetiro: 29,
    IdFazenda: 16,
    Nome: 'Retiro Nelore',
    NomeReduzido: 'Nelore',
    DataCriacao: '2018-08-14T20:42:19.000Z',
    UsuarioCriacao: 'marco@dti',
    DataAlteracao: '2018-10-26T18:08:37.000Z',
    UsuarioAlteracao: 'marco@dti',
    DataExclusao: null,
    UsuarioExclusao: null,
    Excluido: false
  },
  {
    IdRetiro: 31,
    IdFazenda: 16,
    Nome: 'Retiro Guzerá',
    NomeReduzido: 'Guzera',
    DataCriacao: '2018-08-23T14:05:26.000Z',
    UsuarioCriacao: 'Gerry Sousa',
    DataAlteracao: '2018-09-03T18:09:06.000Z',
    UsuarioAlteracao: 'marco@dti',
    DataExclusao: null,
    UsuarioExclusao: null,
    Excluido: false
  },
  {
    IdRetiro: 271,
    IdFazenda: 74,
    Nome: 'Retiro 1 Automatizado',
    NomeReduzido: 'Retiro 1 Automatizad',
    DataCriacao: '2018-12-21T11:24:03.000Z',
    UsuarioCriacao: 'gerry.sousa@prodap.com.br',
    DataAlteracao: null,
    UsuarioAlteracao: null,
    DataExclusao: null,
    UsuarioExclusao: null,
    Excluido: false
  },
  {
    IdRetiro: 272,
    IdFazenda: 74,
    Nome: 'Retiro 2 Automatizado',
    NomeReduzido: 'Retiro 2 Automatizad',
    DataCriacao: '2018-12-21T11:24:03.000Z',
    UsuarioCriacao: 'gerry.sousa@prodap.com.br',
    DataAlteracao: null,
    UsuarioAlteracao: null,
    DataExclusao: null,
    UsuarioExclusao: null,
    Excluido: false
  },
  {
    IdRetiro: 273,
    IdFazenda: 74,
    Nome: 'Retiro 3 Automatizado',
    NomeReduzido: 'Retiro 3 Automatizad',
    DataCriacao: '2018-12-21T11:24:03.000Z',
    UsuarioCriacao: 'gerry.sousa@prodap.com.br',
    DataAlteracao: null,
    UsuarioAlteracao: null,
    DataExclusao: null,
    UsuarioExclusao: null,
    Excluido: false
  }
];

export const retirosRepetidosUsuarioMock = [
  { IdRetiro: 31, IdFazenda: 16, repetido: true },
  { IdRetiro: 29, IdFazenda: 16 },
  { IdRetiro: 271, IdFazenda: 74 },
  { IdRetiro: 272, IdFazenda: 74 },
  { IdRetiro: 273, IdFazenda: 74 },
  { IdRetiro: 31, IdFazenda: 16, repetido: true }
];

export const retirosUsuarioFiltrados = [
  { IdFazenda: 16, IdRetiro: 31 },
  { IdFazenda: 16, IdRetiro: 29 },
  { IdFazenda: 74, IdRetiro: 271 },
  { IdFazenda: 74, IdRetiro: 272 },
  { IdFazenda: 74, IdRetiro: 273 }
];
export const retirosUsuarioMock = [
  { IdRetiro: 31, IdFazenda: 16 },
  { IdRetiro: 29, IdFazenda: 16 },
  { IdRetiro: 271, IdFazenda: 74 },
  { IdRetiro: 272, IdFazenda: 74 },
  { IdRetiro: 273, IdFazenda: 74 }
];

export const opcoesSelectFazendaMock = [
  { value: 16, label: 'Fazenda Modelo 1' },
  { value: 74, label: 'Fazenda Modelo 3' }
];

export const opcoesSelectRetiroMock = [
  {
    value: retirosFazendaMock[0].IdRetiro,
    label: `${retirosFazendaMock[0].Nome} (${retirosFazendaMock[0].IdRetiro})`
  },
  {
    value: retirosFazendaMock[1].IdRetiro,
    label: `${retirosFazendaMock[1].Nome} (${retirosFazendaMock[1].IdRetiro})`
  },
  {
    value: 0,
    label: 'TODOS OS RETIROS'
  }
];

export const retirosAgrupadosPorFazendaMock = {
  '16': [
    {
      IdRetiro: 29,
      IdFazenda: 16,
      Nome: 'Retiro Nelore',
      NomeReduzido: 'Nelore',
      DataCriacao: '2018-08-14T20:42:19.000Z',
      UsuarioCriacao: 'marco@dti',
      DataAlteracao: '2018-10-26T18:08:37.000Z',
      UsuarioAlteracao: 'marco@dti',
      DataExclusao: null,
      UsuarioExclusao: null,
      Excluido: false
    },
    {
      IdRetiro: 31,
      IdFazenda: 16,
      Nome: 'Retiro Guzerá',
      NomeReduzido: 'Guzera',
      DataCriacao: '2018-08-23T14:05:26.000Z',
      UsuarioCriacao: 'Gerry Sousa',
      DataAlteracao: '2018-09-03T18:09:06.000Z',
      UsuarioAlteracao: 'marco@dti',
      DataExclusao: null,
      UsuarioExclusao: null,
      Excluido: false
    }
  ]
};

export const stateToPropsTabContainerMock = {
  usuarioRetiroVinculo: {
    fazendasUsuario: [],
    retirosFazendas: [],
    retirosUsuario: []
  }
};
