import { requisicaoComSucesso } from '../../shared/util/utils';
import { FazendaManager } from '../fazenda';
import { UsuarioManager } from '../usuario';
import {
  RetornaDadosBulkFinanceiro
} from '../usuarioFazendaVinculo/usuarioFazendaVinculoUtils';
import {
  mapeiaFazendasUsuarioParaIdsFazendasUnicos,
  montaFiltroFazendasUsuario,
} from '../usuarioRetiroVinculo/usuarioRetiroVinculoUtils';
import UsuarioFazendaService from './usuarioFazendaVinculoService';

export const MSG = {
  ERRO_SALVAR_VINCULOS_USUARIO_FAZENDA: 'Ocorreu um erro tentar salvar vínculos'
};

class UsuarioFazendaVinculoManager {
  constructor() {
    this.idsFazendasUsuario = [];
  }

  async obtemFazendasUsuario(IdUsuario) {
    try {
      const fazendasUsuario = await UsuarioManager.obtemFazendasUsuario(IdUsuario);

      this.idsFazendasUsuario = mapeiaFazendasUsuarioParaIdsFazendasUnicos(fazendasUsuario);
      const filtroFazendas = montaFiltroFazendasUsuario(this.idsFazendasUsuario);

      const fazendasExistentesComNome = await FazendaManager.obtemFazendasIdNome(filtroFazendas);

      this.idsFazendasUsuario = mapeiaFazendasUsuarioParaIdsFazendasUnicos(
        fazendasExistentesComNome
      );
      return fazendasExistentesComNome;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async salvaVinculosUsuarioFazenda(IdUsuario, vinculosSalvar, vincularTodos = false) {
    try {
      const idsFazendasVincular = vinculosSalvar
        .filter(vinculo => vinculo.usuarioVinculado === true || vincularTodos)
        .map(vinculoFiltrado => vinculoFiltrado.IdFazenda);

      const respostaServico = await UsuarioFazendaService.salvaVinculosUsuarioFazenda(
        IdUsuario,
        idsFazendasVincular
      );
      const { status } = respostaServico;
      return requisicaoComSucesso(status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_VINCULOS_USUARIO_FAZENDA);
    }
  }

  async salvarVinculosUsuarioFazendasComFinanceiro(IdUsuario, vinculosSalvar, senha) {
    try {
      const dados = RetornaDadosBulkFinanceiro(vinculosSalvar, senha)

      const respostaServico = await UsuarioFazendaService.salvaVinculosUsuarioFazendaFinanceiro(
        IdUsuario,
        dados
      );
      const { status } = respostaServico;

      return requisicaoComSucesso(status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_VINCULOS_USUARIO_CLIENTES);
    }
  }
}
export default new UsuarioFazendaVinculoManager();
