import * as usuarioFazendaVinculoActions from './usuarioFazendaVinculoActions';
import { notificacaoActions } from '../../notificacao/redux';
import UsuarioFazendaVinculoManager from '../usuarioFazendaVinculoManager';
import * as UIActions from '../../../shared/redux/UIActions';

export const MSG_VINCULO_USUARIO_FAZENDA = {
  SALVA_VINCULOS_USUARIO_FAZENDA_SUCESSO: 'Os vínculos do usuário foram salvos',
  SALVA_VINCULOS_USUARIO_FAZENDA_FALHA: 'Os vínculos do usuário não foram salvos',
  SALVA_VINCULOS_USUARIO_FAZENDA_ERROR: 'Ocorreu um erro ao salvar vínculos usuário'
};

const obtemFazendasUsuario = IdUsuario => async dispatch => {
  try {
    const fazendasUsuario = await UsuarioFazendaVinculoManager.obtemFazendasUsuario(IdUsuario);
    dispatch(usuarioFazendaVinculoActions.setFazendasUsuario(fazendasUsuario));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};

const salvaVinculosUsuarioFazenda = (
  IdUsuario,
  vinculosUsuarioFazenda,
  vincularTodos = false
) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const vinculosForamSalvos = await UsuarioFazendaVinculoManager.salvaVinculosUsuarioFazenda(
      IdUsuario,
      vinculosUsuarioFazenda,
      vincularTodos
    );
    if (vinculosForamSalvos) {
      await obtemFazendasUsuario(IdUsuario)(dispatch);
      dispatch(
        notificacaoActions.notificaSucesso(
          MSG_VINCULO_USUARIO_FAZENDA.SALVA_VINCULOS_USUARIO_FAZENDA_SUCESSO
        )
      );
    } else {
      dispatch(
        notificacaoActions.notificaErro(
          MSG_VINCULO_USUARIO_FAZENDA.SALVA_VINCULOS_USUARIO_FAZENDA_FALHA
        )
      );
    }
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(
        MSG_VINCULO_USUARIO_FAZENDA.SALVA_VINCULOS_USUARIO_FAZENDA_ERROR
      )
    );
  } finally {
    dispatch(UIActions.removeLoading());
  }
};

const salvarVinculosUsuarioFazendaFinanceiro = (
  IdUsuario,
  vinculosUsuarioFazenda,
  vincularTodos = false
) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const vinculosForamSalvos = await UsuarioFazendaVinculoManager.salvarVinculosUsuarioFazendasComFinanceiro(
      IdUsuario,
      vinculosUsuarioFazenda,
      vincularTodos
    );
    if (vinculosForamSalvos) {
      await obtemFazendasUsuario(IdUsuario)(dispatch);
      dispatch(
        notificacaoActions.notificaSucesso(
          MSG_VINCULO_USUARIO_FAZENDA.SALVA_VINCULOS_USUARIO_FAZENDA_SUCESSO
        )
      );
    } else {
      dispatch(
        notificacaoActions.notificaErro(
          MSG_VINCULO_USUARIO_FAZENDA.SALVA_VINCULOS_USUARIO_FAZENDA_FALHA
        )
      );
    }
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(
        MSG_VINCULO_USUARIO_FAZENDA.SALVA_VINCULOS_USUARIO_FAZENDA_ERROR
      )
    );
  } finally {
    dispatch(UIActions.removeLoading());
  }
};

export { obtemFazendasUsuario, salvaVinculosUsuarioFazenda, salvarVinculosUsuarioFazendaFinanceiro };
