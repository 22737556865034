import {
  incluiIdsFazendasHabilitadas,
  mapeiaIdsFazendasHabilitadastoggle
} from "../toggleUtils";
import * as toggleFeatureActions from "./toggleFeatureActions";
export const INITIAL_STATE = {
  toggles: [],
  toggleSelecionada: {
    FazendasHabilitadas: [],
  },
  nomeToggleFeature: null,
  toggleModalAberta: false,
};

const toggleReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case toggleFeatureActions.SET_TOGGLES:
      return { ...state, toggles: action.payload };

    case toggleFeatureActions.SET_NOME_TOGGLE_ATUALIZANDO:
      return { ...state, nomeToggleFeature: action.payload };

    case toggleFeatureActions.SET_TOGGLE_SELECIONADA:
      return {
        ...state,
        toggleSelecionada: {
          ...action.payload,
          FazendasHabilitadas: mapeiaIdsFazendasHabilitadastoggle(
            action.payload.FazendasHabilitadas || []
          ),
        },
        toggleModalAberta: true,
      };

    case toggleFeatureActions.SELECIONA_FAZENDA:
      /* istanbul ignore next */
      const fazendasToggleSelecionada = incluiIdsFazendasHabilitadas(
        action.payload,
        [...state.toggleSelecionada.FazendasHabilitadas]
      );
      /* istanbul ignore next */
      return {
        ...state,
        toggleSelecionada: {
          ...state.toggleSelecionada,
          FazendasHabilitadas: fazendasToggleSelecionada,
        },
      };

    case toggleFeatureActions.SELECIONA_TODAS_FAZENDAS_PARA_TOGGLE:
      /* istanbul ignore next */
      const fazendasSelecionadas = mapeiaIdsFazendasHabilitadastoggle(
        action.payload
      );
      /* istanbul ignore next */
      return {
        ...state,
        toggleSelecionada: {
          ...state.toggleSelecionada,
          FazendasHabilitadas: fazendasSelecionadas,
        },
      };

    case toggleFeatureActions.FECHA_MODAL_TOGGLE:
      return {
        ...state,
        toggleModalAberta: false,
        toggleSelecionada: INITIAL_STATE.toggleSelecionada,
      };
    default:
      return state;
  }
};

export default toggleReducers;