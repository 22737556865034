export const styles = (theme) => {
  const getPropertyTheme = (...params) => {
    let current = { ...theme };
    for (let i = 0; i < params.length; i++) {
      if (current[params[i]]) {
        current = current[params[i]];
      } else {
        return null;
      }
    }
    return current;
  };

  return {
    table: {
      width: "100%",
      backgroundColor: "white",
      ...theme.tabela.scrollBar,
    },
    headerCellsCenter: {
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Roboto",
      color: getPropertyTheme("palette", "primary", "main") || "black",
      backgroundColor:
        getPropertyTheme("palette", "default2", "light") || "#e1e1e1",
      fontSize: "0.875rem",
      textAlign: "center",
      padding: "4px 40px 4px 24px",
    },
    headerCellsLeft: {
      fontFamily: "Roboto",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.default2.light,
      fontSize: "0.875rem",
      textAlign: "left",
    },
    paddingAdjustTitle: {
      paddingLeft: "20px",
    },
    paddingAdjustTitleWithTolltip: {
      paddingLeft: "43px",
    },
    paddingAdjustTitleLeft: {
      paddingLeft: "0",
    },
    paddingAdjustTitleWithTolltipLeft: {
      paddingLeft: "0",
    },
    bodyCell: {
      color: getPropertyTheme("palette", "default2", "main") || "#fff",
      fontSize: "0.75rem",
      textalign: "center",
      padding: "4px 40px 4px 24px",
    },
    dateCell: {
      textAlign: "center",
    },
    tooltip: {
      maxWidth: 60,
      verticalAlign: "middle",
      fontSize: "0.875rem",
      color: getPropertyTheme("palette", "default1", "light") || "#fff",
      marginLeft: "5px",
    },
    tooltipContent: {
      fontSize: "0.875rem",
    },
    tableContainer: {
      maxHeight: "635px",
      overflow: "auto",
      ...getPropertyTheme("tabela", "scrollBar"),
      borderRadius: getPropertyTheme("tabela", "borderRadius") || "0px",
    },
    containerTable: {
      borderRadius: getPropertyTheme("tabela", "borderRadius") || "0px",
      overflow: "auto",
    },
    aoClicarNaLinhaCursor: {
      cursor: "pointer",
    },
  };
};
