import memoizeOne from 'memoize-one';
import { mapeiaIdsParaVinculo, mapeiaVinculosUsuario } from '../../../shared/util';

const mapeiaFazendasComVinculosUsuario = memoizeOne(state => {
  const { usuarioFazendaVinculo, fazenda } = state;
  const tabSelecionada = state.interface ? state.interface.tabSelecionada : '';
  const retornarFazendas =
    fazenda.fazendasCadastro.length > 0 &&
    tabSelecionada === '#usuariofazenda'
  if (retornarFazendas) {
    const idsFazendasUsuario = mapeiaIdsParaVinculo(
      usuarioFazendaVinculo.fazendasUsuario,
      'IdFazenda'
    );
    const fazendasMapeadas = mapeiaVinculosUsuario(
      idsFazendasUsuario,
      'IdFazenda',
      fazenda.fazendasCadastro
    );
    return fazendasMapeadas
      .sort(function (x, y) {
        return x.usuarioVinculado === y.usuarioVinculado ? 0 : x.usuarioVinculado ? -1 : 1;
      })
      .map((fazenda, index) => ({
        Nome: fazenda.Nome,
        IdFazenda: fazenda.IdFazenda,
        usuarioVinculado: fazenda.usuarioVinculado,
        ClienteFinanceiro: fazenda.ClienteFinanceiro,
        IdContaFinanceiro: fazenda.IdContaFinanceiro,
        index
      }));
  } else {
    return [];
  }
});
export { mapeiaFazendasComVinculosUsuario };
