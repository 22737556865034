import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';

export const acoesVinculoTab = props => {
  const {
    labelBotaoCriarNovo,
    funcaoCriarNovo,
    labelBotaoSalvar,
    funcaoSalvarAlteracoes,
    desabilitaAcaoSalvar
  } = props;
  return (
    <Grid item container direction="row" justify="space-between" style={{ width: '100%' }}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid
          container
          direction="row"
          justify="space-around"
          style={{ width: '40%' }}
        >
          <Button id="btnCriarNovo" color="primary" variant="contained" onClick={funcaoCriarNovo}>
            {labelBotaoCriarNovo}
          </Button>
          <Button
            id="btnSalvarVinculos"
            disabled={!desabilitaAcaoSalvar}
            color="primary"
            variant="contained"
            onClick={funcaoSalvarAlteracoes}
          >
            {labelBotaoSalvar}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

acoesVinculoTab.propTypes = {
  labelBotaoCriarNovo: PropTypes.string,
  labelBotaoSalvar: PropTypes.string,
  funcaoCriarNovo: PropTypes.func.isRequired,
  funcaoSalvarAlteracoes: PropTypes.func.isRequired,
  desabilitaAcaoSalvar: PropTypes.bool
};

acoesVinculoTab.defaultProps = {
  labelBotaoCriarNovo: 'Criar novo',
  labelBotaoSalvar: 'Salvar vínculos',
  desabilitaAcaoSalvar: false
};

export default acoesVinculoTab;
