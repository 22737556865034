import memoizeOne from "memoize-one";
import {
  mapeiaIdsParaVinculo,
  mapeiaVinculosUsuario,
} from "../../../shared/util";

const mapeiaClientesComVinculosUsuario = memoizeOne((state) => {
  const { usuarioClienteVinculo, cliente } = state;
  const tabSelecionada = state.interface ? state.interface.tabSelecionada : "";
  const retornarClientes =
    cliente.clientes.length > 0 && tabSelecionada === "#usuariocliente";

  if (retornarClientes) {
    const mapVinculos = usuarioClienteVinculo.clientesUsuario.reduce(
      (acc, item) => {
        acc[item.IdCliente] = item;
        return acc;
      },
      {}
    );

    const idsClientesUsuario = mapeiaIdsParaVinculo(
      usuarioClienteVinculo.clientesUsuario,
      "IdCliente"
    );
    const clientesMapeados = mapeiaVinculosUsuario(
      idsClientesUsuario,
      "IdCliente",
      cliente.clientes
    );
    return clientesMapeados
      .sort(function (x, y) {
        return x.usuarioVinculado === y.usuarioVinculado
          ? 0
          : x.usuarioVinculado
          ? -1
          : 1;
      })
      .map((cliente, index) => ({
        Nome: cliente.Nome,
        IdCliente: cliente.IdCliente,
        usuarioVinculado: cliente.usuarioVinculado,
        ClienteFinanceiro: cliente.ClienteFinanceiro,
        IdContaFinanceiro: cliente.IdContaFinanceiro,
        index,
        Ativo: mapVinculos[cliente.IdCliente]
          ? mapVinculos[cliente.IdCliente].Ativo
          : undefined,
        UsuarioAdministrador: mapVinculos[cliente.IdCliente]
          ? mapVinculos[cliente.IdCliente].UsuarioAdministrador
          : undefined,
      }));
  } else {
    return [];
  }
});

export { mapeiaClientesComVinculosUsuario };
