import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { removeLoading, setLoading } from "../../../../shared/redux/UIActions";
import * as notificacaoActions from "../../../notificacao/redux/notificacaoActions";
import fazendaManager from "../../fazendaManager";

import CountryStatePickerPage from "./paisEstadoPickerPage";

let cachePaises = [];

const CountryStatePickerContainer = ({
  estado,
  pais,
  setEstado,
  setPais,
  enableLoading,
  disableLoading,
  notificaErro,
}) => {
  const [listaPaises, setListaPaises] = useState([]);
  const [listaEstados, setListaEstados] = useState([]);

  const estadoBloqueado = listaEstados.length === 0 || pais === "";

  const populaPaises = async () => {
    if (cachePaises.length !== 0) {
      setListaPaises(cachePaises);
      return;
    }

    try {
      enableLoading();
      const paises = await fazendaManager.obtemPaises();
      cachePaises = paises;
      setListaPaises(paises);
    } catch (error) {
      notificaErro(error.message);
    } finally {
      disableLoading();
    }
  };

  const populaEstados = async (pais) => {
    if (pais === "" || pais === undefined) return;
    try {
      enableLoading();
      const estados = await fazendaManager.obtemEstados(pais);
      setListaEstados(estados);
    } catch (error) {
      notificaErro(error.message);
    } finally {
      disableLoading();
    }
  };

  useEffect(() => {
    populaEstados(pais);
  }, [pais]);

  useEffect(() => {
    populaPaises();
  }, []);

  return (
    <CountryStatePickerPage
      estado={estado}
      pais={pais}
      listaPaises={listaPaises}
      listaEstados={listaEstados}
      estadoBloqueado={estadoBloqueado}
      setEstado={setEstado}
      setPais={setPais}
    />
  );
};

export const mapDispatchToProps = {
  enableLoading: setLoading,
  disableLoading: removeLoading,
  notificaErro: notificacaoActions.notificaErro,
};

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryStatePickerContainer);
