export const SET_INGREDIENTES = 'ingrediente/SET_INGREDIENTES';
export const SET_TIPO_INGREDIENTES = 'ingrediente/SET_TIPO_INGREDIENTES';

const setIngredientes = ingredientes => {
  return {
    type: SET_INGREDIENTES,
    payload: ingredientes
  };
};

const setTipoIngredientes = tipoIngredientes => {
  return {
    type: SET_TIPO_INGREDIENTES,
    payload: tipoIngredientes
  };
};

export { setIngredientes, setTipoIngredientes };
