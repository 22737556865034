import _ from 'lodash';
import * as usuarioRetiroVinculoActions from './usuarioRetiroVinculoActions';
import { notificacaoActions } from '../../notificacao/redux';
import UsuarioRetiroVinculoManager from '../usuarioRetiroVinculoManager';
import * as UIActions from '../../../shared/redux/UIActions';

export const MSG_VINCULO_USUARIO_RETIRO = {
  SALVA_VINCULOS_USUARIO_RETIRO_SUCESSO: 'Os vínculos do usuário foram salvos',
  SALVA_VINCULOS_USUARIO_RETIRO_FALHA: 'Os vínculos do usuário não foram salvos',
  SALVA_VINCULOS_USUARIO_RETIRO_ERROR: 'Ocorreu um erro ao salvar vínculos usuário'
};

const obtemVinculosUsuarioRetiro = IdUsuario => async dispatch => {
  try {
    /* istanbul ignore next */
    dispatch(UIActions.setLoading());
    /* istanbul ignore next */
    await obtemFazendasUsuario(IdUsuario)(dispatch);
    /* istanbul ignore next */
    await obtemRetirosFazendas()(dispatch);
    /* istanbul ignore next */
    await obtemRetirosUsuario(IdUsuario)(dispatch);
  } catch (error) {
    /* istanbul ignore next */
    dispatch(notificacaoActions.notificaErro(error.message));
  } finally {
    /* istanbul ignore next */
    dispatch(UIActions.setLoading());
  }
};

const obtemFazendasUsuario = IdUsuario => async dispatch => {
  try {
    const fazendasUsuario = await UsuarioRetiroVinculoManager.obtemFazendasUsuario(IdUsuario);
    dispatch(usuarioRetiroVinculoActions.setFazendasUsuario(fazendasUsuario));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};

const obtemRetirosFazendas = () => async dispatch => {
  try {
    const retirosFazendas = await UsuarioRetiroVinculoManager.obtemRetirosFazendas();
    dispatch(usuarioRetiroVinculoActions.setRetirosFazendas(retirosFazendas));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};

const obtemRetirosUsuario = IdUsuario => async dispatch => {
  try {
    const retirosUsuario = await UsuarioRetiroVinculoManager.obtemRetirosUsuario(IdUsuario);
    dispatch(usuarioRetiroVinculoActions.setRetirosUsuario(retirosUsuario));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};

const salvaVinculosUsuarioRetiro = (IdUsuario, vinculosUsuarioRetiro) => async dispatch => {
  try {
    const fazendaComTodosRetiros = _.remove(vinculosUsuarioRetiro, vinculo => {
      return vinculo.IdRetiro === 0;
    });
    dispatch(UIActions.setLoading());
    const vinculosForamSalvos = await UsuarioRetiroVinculoManager.salvaVinculosUsuarioRetiro(
      IdUsuario,
      vinculosUsuarioRetiro,
      fazendaComTodosRetiros
    );
    if (vinculosForamSalvos) {
      await obtemFazendasUsuario(IdUsuario)(dispatch);
      await obtemRetirosFazendas()(dispatch);
      await obtemRetirosUsuario(IdUsuario)(dispatch);
      dispatch(
        notificacaoActions.notificaSucesso(
          MSG_VINCULO_USUARIO_RETIRO.SALVA_VINCULOS_USUARIO_RETIRO_SUCESSO
        )
      );
    } else {
      dispatch(
        notificacaoActions.notificaErro(
          MSG_VINCULO_USUARIO_RETIRO.SALVA_VINCULOS_USUARIO_RETIRO_FALHA
        )
      );
    }
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(
        MSG_VINCULO_USUARIO_RETIRO.SALVA_VINCULOS_USUARIO_RETIRO_ERROR
      )
    );
  } finally {
    dispatch(UIActions.setLoading());
  }
};

export {
  obtemFazendasUsuario,
  obtemRetirosFazendas,
  obtemRetirosUsuario,
  salvaVinculosUsuarioRetiro,
  obtemVinculosUsuarioRetiro
};
