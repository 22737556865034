import { requisicaoComSucesso } from "../../shared/util/utils";
import ClienteManager from "../cliente/clienteManager";
import { UsuarioClienteVinculoManager } from "../usuarioClienteVinculo/";
import FazendaService from "./fazendaService";

export const MSG = {
  ERRO_OBTER_FAZENDAS: "Ocorreu um erro ao tentar retornar todas as fazendas.",
  ERRO_SALVAR_FAZENDAS: "Ocorreu erro ao tentar salvar dados da fazenda",
  ERRO_EXCLUIR_FAZENDAS: "Ocorreu erro ao tentar exlcuir fazenda",
  ERRO_OBTER_PAISES: "Ocorreu um erro ao tentar retornar todos os paises",
  ERRO_OBTER_ESTADOS: "Ocorreu um erro ao tentar retornar todos os estados",
};

class FazendaManager {
  async montaFiltroFazendasCliente(idsClientes) {
    const filtroFazendas = {
      where: { IdCliente: idsClientes },
    };
    return filtroFazendas;
  }
  async obtemFazendasIdNome(filtro = {}) {
    try {
      const filter = {
        ...filtro,
        fields: ["IdFazenda", "Nome"],
      };
      const fazendas = await FazendaService.obtemFazendas(
        "?filter=" + JSON.stringify(filter)
      );
      return fazendas.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_FAZENDAS);
    }
  }

  async obtemFazendasIdCliente(IdUsuario) {
    try {
      const vinculosUsuarioCliente =
        await UsuarioClienteVinculoManager.obtemClientesUsuario(IdUsuario);
      const idsClientes = vinculosUsuarioCliente.map(
        (vinculo) => vinculo.IdCliente
      );
      const filter = await this.montaFiltroFazendasCliente(idsClientes);
      const fazendas = await FazendaService.obtemFazendasIdCliente(
        "?filter=" + JSON.stringify(filter)
      );
      return fazendas.data;
    } catch (error) {
      throw new Error("#### error", error.message);
    }
  }

  async obtemFazendas() {
    try {
      const respostaServicoFazenda = await FazendaService.obtemFazendas();
      const clientes = await ClienteManager.obtemClientes();
      const fazendasCadastro = respostaServicoFazenda.data;
      const fazendasCadastroMapeadas = fazendasCadastro.map(
        (fazendaCadastro) => {
          const clienteFazenda = clientes.find(
            (cliente) => cliente.IdCliente === fazendaCadastro.IdCliente
          );
          if (clienteFazenda) {
            fazendaCadastro["NomeCliente"] = clienteFazenda.Nome;
            fazendaCadastro["IdCliente"] = clienteFazenda.IdCliente;
          } else {
            fazendaCadastro["NomeCliente"] = "SEM CLIENTE ATRIBUÍDO";
            fazendaCadastro["IdCliente"] = null;
          }
          return fazendaCadastro;
        }
      );
      return fazendasCadastroMapeadas;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_FAZENDAS);
    }
  }

  async salvaFazenda(IdFazenda, fazendaSalvar) {
    try {
      let respostaServicoFazenda;
      if (IdFazenda) {
        respostaServicoFazenda = await FazendaService.salvaFazenda(
          IdFazenda,
          fazendaSalvar
        );
      } else {
        respostaServicoFazenda = await FazendaService.cadastraFazenda(
          fazendaSalvar
        );
      }
      return requisicaoComSucesso(respostaServicoFazenda.status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_FAZENDAS);
    }
  }

  async excluiFazenda(IdFazenda) {
    try {
      const respostaServicoFazenda = await FazendaService.excluiFazenda(
        IdFazenda
      );
      return requisicaoComSucesso(respostaServicoFazenda.status);
    } catch (error) {
      throw new Error(MSG.ERRO_EXCLUIR_FAZENDAS);
    }
  }

  async obtemPaises() {
    try {
      const paises = await FazendaService.obtemPaises();
      return paises.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_PAISES);
    }
  }

  async obtemEstados(pais) {
    try {
      const estados = await FazendaService.obtemEstados(pais);
      return estados.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_ESTADOS);
    }
  }
}
export default new FazendaManager();
