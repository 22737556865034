
export const OBTER_RELATORIOS_TABLEAU = 'OBTER_RELATORIOS_TABLEAU';
export const DELETAR_RELATORIO_TABLEAU = 'DELETAR_RELATORIO_TABLEAU'
export const ATUALIZAR_RELATORIO_TABLEAU = 'ATUALIZAR_RELATORIO_TABLEAU'
export const CRIAR_RELATORIO_TABLEAU = 'CRIAR_RELATORIO_TABLEAU'

export const obterRelatorio = relatorios => {
  return {
    type: OBTER_RELATORIOS_TABLEAU,
    payload: { relatorios: relatorios }
  }
};
export const deletarRelatorio = relatorios => {
  return {
    type: DELETAR_RELATORIO_TABLEAU,
    payload: { relatorios: relatorios }
  }
};
export const atualizarRelatorio = relatorios => {
  return {
    type: ATUALIZAR_RELATORIO_TABLEAU,
    payload: { relatorios: relatorios }
  }
};
export const criaRelatorio = relatorio => {
  return {
    type: CRIAR_RELATORIO_TABLEAU,
    payload: { relatorios: relatorio }
  }
};


