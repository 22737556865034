import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  obtemFazendasUsuario,
  salvaVinculosUsuarioFazenda,
  salvarVinculosUsuarioFazendaFinanceiro
} from '../redux/usuarioFazendaVinculoOperations';
import UsuarioFazendaVinculoTab from './usuarioFazendaVinculoTab';

import { setTabSelecionada } from '../../../shared/redux/UIActions';
import { setFazendasCadastro } from '../../fazenda/redux/fazendaActions';
import { obtemFazendasIdCliente } from '../../fazenda/redux/fazendaOperations';
import { mapeiaFazendasComVinculosUsuario } from '../redux/usuarioFazendaVinculoSelectors';

export function UsuarioFazendaVinculoTabContainer(props) {
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const { location } = props;
  const { variant } = props.reduxProps;
  const tabUsuarioFazendaSelecionada = location.hash === '#usuariofazenda';
  const IdUsuario = props.match.params.IdUsuario;


  const obtemDados = async () => {
    await Promise.all([
      props.obtemFazendasIdCliente(IdUsuario),
      props.obtemFazendasUsuario(IdUsuario)
    ])
    setDadosCarregados(true);
  };

  const salvaVinculosUsuarioFazenda = (vinculosSalvar, vincularTodos) => {
    props.salvaVinculosUsuarioFazenda(IdUsuario, vinculosSalvar, vincularTodos);
  };

  const salvarVinculosUsuarioFazendaFinanceiro = (vinculosSalvar, vincularTodos) => {
    props.salvarVinculosUsuarioFazendaFinanceiro(IdUsuario, vinculosSalvar, vincularTodos);
  };

  const {
    reduxProps: { fazendas }
  } = props;

  useEffect(() => {
    if (tabUsuarioFazendaSelecionada) {
      obtemDados();
    } else {
      setDadosCarregados(false);
      props.setFazendasCadastro([]);
    }
  }, [props.location.hash]);

  useEffect(() => {
    if (variant !== 'success') {
      props.setFazendasCadastro([]);
    }
  }, [variant]);

  return (
    <React.Fragment>
      {tabUsuarioFazendaSelecionada ? (
        <UsuarioFazendaVinculoTab
          salvaVinculosUsuarioFazenda={salvaVinculosUsuarioFazenda}
          salvarVinculosUsuarioFazendaFinanceiro={salvarVinculosUsuarioFazendaFinanceiro}
          fazendas={fazendas}
          usuario={props.usuario}
          dadosCarregados={dadosCarregados}
          senhaFinanceiro={props.usuario.SenhaFinanceiro}
        />
      ) : null}
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  obtemFazendasUsuario: obtemFazendasUsuario,
  obtemFazendasIdCliente: obtemFazendasIdCliente,
  salvaVinculosUsuarioFazenda: salvaVinculosUsuarioFazenda,
  salvarVinculosUsuarioFazendaFinanceiro: salvarVinculosUsuarioFazendaFinanceiro,
  setFazendasCadastro: setFazendasCadastro,
  setTabSelecionada: setTabSelecionada
};

export const mapStateToProps = state => {
  return {
    reduxProps: {
      fazendas: mapeiaFazendasComVinculosUsuario(state),
      variant: state.notificacao.variant
    }
  };
};

UsuarioFazendaVinculoTabContainer.displayName = 'UsuarioFazendaVinculoTabContainer';

UsuarioFazendaVinculoTabContainer.propTypes = {
  obtemFazendasUsuario: PropTypes.func.isRequired,
  obtemFazendasIdCliente: PropTypes.func.isRequired,
  usuario: PropTypes.shape({
    IdUsuario: PropTypes.number,
    Nome: PropTypes.string
  }).isRequired
};

UsuarioFazendaVinculoTabContainer.defaultProps = {
  reduxProps: {
    fazendasUsuario: [],
    fazendas: []
  },
  usuario: {}
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UsuarioFazendaVinculoTabContainer)
);
