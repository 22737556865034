import * as ingredienteActions from './ingredienteActions';

export const INITIAL_STATE = {
  ingredientes: [],
  tipoIngredientes: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ingredienteActions.SET_INGREDIENTES:
      return { ...state, ingredientes: action.payload };
    case ingredienteActions.SET_TIPO_INGREDIENTES:
      return { ...state, tipoIngredientes: action.payload };
    default:
      return state;
  }
};
