import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import { compose } from "recompose";
import { CabecalhoLista } from "../../../shared/components";
import Page from "../../../shared/components/page";
import { filterFazendasMemoize } from "../fazendaUtils";
import * as fazendaOperations from "../redux/fazendaOperations";
import FazendaForm from "./fazendaForm";
import FazendaList from "./fazendaList";

export class FazendaPage extends PureComponent {
  state = {
    textoFiltrar: "",
    formularioAberto: false,
    confirmacaoExclusaoAberto: false,
    nome: "",
    nomeReduzido: "",
    pais: "",
    estado: "",
    cidade: "",
    nomeCliente: "",
    ativo: false,
    numeroLeituraAlturaPasto: "",
    idCliente: "",
    idClienteFinanceiro: 0,
    idContaFinanceiro: 0,
    permitirAcessoFinanceiro: false,
    acessoFinanceiro: false,
    CNPJ: "",
    codigoFazendaProtheus: "",
  };

  setCamposFormFazenda = (value, campo) => {
    this.setState({
      [campo]: value,
    });
  };

  verificaClienteUsaFinanceiro = (value, campo) => {
    /* istanbul ignore next */
    this.setCamposFormFazenda(value, campo);
    const { clientes } = this.props;

    /* istanbul ignore next */
    const ClienteFinanceiro = _.find(clientes, function (c) {
      return (
        Number(c.IdCliente) === Number(value) && c.ClienteFinanceiro === true
      );
    });

    if (ClienteFinanceiro) {
      /* istanbul ignore next */
      this.setCamposFormFazenda(true, "permitirAcessoFinanceiro");
      /* istanbul ignore next */
      this.setCamposFormFazenda(
        ClienteFinanceiro.IdUsuarioFinanceiro,
        "idClienteFinanceiro"
      );
      /* istanbul ignore next */
    } else {
      /* istanbul ignore next */
      this.setCamposFormFazenda(false, "permitirAcessoFinanceiro");
      /* istanbul ignore next */
      this.setCamposFormFazenda(false, "acessoFinanceiro");
      /* istanbul ignore next */
      this.setCamposFormFazenda("", "CNPJ");
      /* istanbul ignore next */
      this.setCamposFormFazenda(0, "idClienteFinanceiro");
      /* istanbul ignore next */
    }
  };

  alteraTextoFiltro = (event) => {
    this.setState({ textoFiltrar: event.target.value });
  };

  abreFechaFormNovaFazenda = () => {
    this.setState((prevState) => {
      return {
        formularioAberto: !prevState.formularioAberto,
        nome: "",
        nomeReduzido: "",
        codigoFazendaProtheus: "",
        pais: "",
        estado: "",
        cidade: "",
        nomeCliente: "",
        ativo: true,
        numeroLeituraAlturaPasto: "",
        idCliente: "",
        idClienteFinanceiro: 0,
        idContaFinanceiro: 0,
        permitirAcessoFinanceiro: false,
        acessoFinanceiro: false,
        CNPJ: "",
      };
    });
  };

  salvaFazenda = async () => {
    const {
      nome,
      nomeReduzido,
      codigoFazendaProtheus,
      estado,
      cidade,
      ativo,
      idCliente,
      numeroLeituraAlturaPasto,
      idClienteFinanceiro,
      idContaFinanceiro,
      acessoFinanceiro,
      CNPJ,
      pais,
    } = this.state;
    await this.props.salvaFazenda(null, {
      Nome: nome,
      NomeReduzido: nomeReduzido,
      CodigoProtheus: codigoFazendaProtheus,
      Pais: pais,
      Estado: estado,
      Cidade: cidade,
      Ativo: ativo,
      IdCliente: idCliente,
      NumeroLeituraAlturaPasto: numeroLeituraAlturaPasto,
      ClienteFinanceiro: acessoFinanceiro ? 1 : 0,
      CNPJ: CNPJ,
      IdContaFinanceiro: idContaFinanceiro,
      IdUsuarioFinanceiro: idClienteFinanceiro,
      Coordenadas: [],
    });
    this.abreFechaFormNovaFazenda();
  };

  render() {
    const { fazendasCadastro, clientes } = this.props;
    const {
      nome,
      nomeReduzido,
      codigoFazendaProtheus,
      pais,
      estado,
      cidade,
      nomeCliente,
      ativo,
      numeroLeituraAlturaPasto,
      idCliente,
      permitirAcessoFinanceiro,
      acessoFinanceiro,
      CNPJ,
    } = this.state;
    const fazendasFiltradas = filterFazendasMemoize(
      fazendasCadastro,
      this.state.textoFiltrar
    );
    return (
      <React.Fragment>
        <Page
          titulo="Fazendas"
          conteudo={
            <React.Fragment>
              <CabecalhoLista
                textoFiltrar={this.state.textoFiltrar}
                alteraTextoFiltro={this.alteraTextoFiltro}
                formularioAberto={this.state.formularioAberto}
                abreFechaFormNovoCadastro={this.abreFechaFormNovaFazenda}
                tituloBotaoCriarNovo="Nova fazenda"
                labelCampoBusca="Buscar fazendas por Nome ou Cliente"
                formulario={
                  <FazendaForm
                    nome={nome}
                    nomeReduzido={nomeReduzido}
                    codigoFazendaProtheus={codigoFazendaProtheus}
                    pais={pais}
                    estado={estado}
                    cidade={cidade}
                    nomeCliente={nomeCliente}
                    ativo={ativo}
                    idCliente={idCliente}
                    numeroLeituraAlturaPasto={numeroLeituraAlturaPasto}
                    clientes={clientes}
                    setCamposFormFazenda={this.setCamposFormFazenda}
                    abreFechaFormNovaFazenda={this.abreFechaFormNovaFazenda}
                    salvaFazenda={this.salvaFazenda}
                    verificaClienteUsaFinanceiro={
                      this.verificaClienteUsaFinanceiro
                    }
                    permitirAcessoFinanceiro={permitirAcessoFinanceiro}
                    acessoFinanceiro={acessoFinanceiro}
                    CNPJ={CNPJ}
                  />
                }
              />

              <Virtuoso
                data={fazendasFiltradas}
                style={{
                  width: "100%",
                  minHeight: "calc(100vh - 420px)",
                }}
                itemContent={(index, fazenda) => (
                  <FazendaList fazenda={fazenda} clientes={clientes} />
                )}
              />
            </React.Fragment>
          }
          exibirConteudo={fazendasCadastro && fazendasCadastro.length}
          conteudoGridProps={{ spacing: 0 }}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  salvaFazenda: fazendaOperations.salvaFazenda,
};

export default compose(connect(null, mapDispatchToProps))(FazendaPage);
