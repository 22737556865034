import memoize from 'memoize-one';
import _ from 'lodash';

export const mapeiaFazendasEmOpcoesSelect = memoize(fazendasUsuario => {
  // fazendasUsuario.unshift({
  //   label: '',
  //   value: -1
  // });
  // return [...fazendasUsuario].map(fazenda => {
  //   return {
  //     label: `${fazenda.Nome} (${fazenda.IdFazenda})`,
  //     value: fazenda.IdFazenda
  //   };
  // });
  let opcoesSelectFazenda = fazendasUsuario.map(fazenda => {
    return {
      label: `${fazenda.Nome} (${fazenda.IdFazenda})`,
      value: fazenda.IdFazenda
    };
  });
  opcoesSelectFazenda.unshift({
    label: '',
    value: -1
  });
  return opcoesSelectFazenda;
});

/* istanbul ignore next */
export const mapeiaRetirosEmOpcoesSelect = (retirosAgrupadosPorFazendas, fazendaSelecionada) => {
  const retiros = retirosAgrupadosPorFazendas[fazendaSelecionada] || [];
  let opcoesSelectRetiro = retiros.map(retiro => {
    return {
      label: `${retiro.Nome} (${retiro.IdRetiro})`,
      value: retiro.IdRetiro
    };
  });
  opcoesSelectRetiro.push({
    label: `TODOS OS RETIROS`,
    value: 0
  });

  if (opcoesSelectRetiro.length === 1) {
    /* istanbul ignore next */
    let opcoesSelectRetiro2 = [];
    /* istanbul ignore next */
    opcoesSelectRetiro2.push({
      label: `Fazenda sem Retiros`,
      value: -1
    });
    /* istanbul ignore next */
    opcoesSelectRetiro = opcoesSelectRetiro2.slice();
  }
  return opcoesSelectRetiro;
};

export const agruparRetirosPorFazenda = memoize(retirosFazendas => {
  return _.groupBy(retirosFazendas, 'IdFazenda');
});

export const indexesVinculosRepetidos = vinculos => {
  return _.filter(
    _.uniq(
      _.map(vinculos, (item, index) => {
        if (
          _.filter(vinculos, {
            IdFazenda: item.IdFazenda,
            IdRetiro: item.IdRetiro
          }).length > 1
        ) {
          return index;
        }

        return false;
      })
    ),
    value => {
      return value;
    }
  );
};

export const omiteVinculosRepetidos = copiaRetirosUsuario => {
  return [...copiaRetirosUsuario].map(retiro => _.omit(retiro, 'repetido'));
};

export const marcaVinculosRepetidosNaLista = (indexesVinculosRepetidos, retirosUsuario) => {
  const copiaRetirosUsuarios = [...retirosUsuario];
  indexesVinculosRepetidos.forEach(indexVinculoRepetido => {
    const copiaRetiroUsuario = {
      ...copiaRetirosUsuarios[indexVinculoRepetido]
    };
    copiaRetiroUsuario.repetido = true;
    copiaRetirosUsuarios[indexVinculoRepetido] = copiaRetiroUsuario;
  });
  return copiaRetirosUsuarios;
};

export const montaFiltroFazendasUsuario = idsFazendasUsuario => {
  const filtroFazendas = {
    where: { IdFazenda: idsFazendasUsuario }
  };
  return filtroFazendas;
};

export const mapeiaFazendasUsuarioParaIdsFazendasUnicos = fazendas => {
  return Array.from(new Set([...fazendas].map(fazenda => fazenda.IdFazenda)));
};

export const filtraRetirosUsuarioDasFazendasUsuario = (retirosUsuario, idsFazendasUsuario) => {
  return retirosUsuario.filter(retiroUsuario =>
    idsFazendasUsuario.includes(retiroUsuario.IdFazenda)
  );
};

export const filtraRetirosUsuariosPresentesEmRetirosFazenda = (retirosUsuario, retirosFazendas) => {
  const idsRetirosFazendas = retirosFazendas.map(retiroFazenda => retiroFazenda.IdRetiro);
  return retirosUsuario.filter(retiroUsuario =>
    idsRetirosFazendas.includes(retiroUsuario.IdRetiro)
  );
};
