import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Logo from './logo';
import DropdownUsuario from './dropdownUsuario';

export const cabecalho = props => {
  const { classes } = props;
  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Logo />
          <DropdownUsuario />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

cabecalho.displayName = 'Cabecalho';
cabecalho.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  appBar: {
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    padding: '0 1%'
  }
});

export default withStyles(styles)(cabecalho);
