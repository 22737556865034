import { Button, Grid, InputAdornment, TextField } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import AutoCompleteMultiSelect from "../../../../shared/components/AutoComplete/multiSelect";

function FiltroUsuario({
  classes,
  carregando,
  filtro,
  alterarFiltro,
  abreFechaFormNovoCadastro,
}) {
  const { busca, clientes, permissoes } = filtro;

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        className={classes.containerCabecalho}
      >
        <div className={classes.filtroContainer}>
          <TextField
            disabled={carregando}
            id="iptBuscaFiltro"
            margin="dense"
            label="Buscar usuários"
            value={busca._value}
            onChange={alterarFiltro("busca")}
            style={{
              width: 150,
            }}
            inputProps={{
              maxLength: 50,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
          <AutoCompleteMultiSelect
            disabled={carregando}
            label="Clientes"
            options={clientes.options}
            value={clientes._value}
            isLoading={clientes.isLoading}
            onChange={alterarFiltro("clientes")}
            style={{
              width: 150,
            }}
          />
          <AutoCompleteMultiSelect
            disabled={carregando}
            label="Tipo acesso"
            options={permissoes.options}
            value={permissoes._value}
            isLoading={permissoes.isLoading}
            onChange={alterarFiltro("permissoes")}
            style={{
              width: 150,
            }}
          />
        </div>
        <Grid>
          <Button
            disabled={carregando}
            className={classes.newClientBtn}
            id="btnCriarNovo"
            variant="contained"
            color="primary"
            onClick={abreFechaFormNovoCadastro}
          >
            Novo usuário
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

FiltroUsuario.defaultProps = {
  textoFiltrar: "",
  alteraTextoFiltro: () => {},
  formularioAberto: false,
  abreFechaFormNovoCadastro: () => {},
  formulario: null,
};

const styles = () => {
  return {
    card: {
      borderRadius: 0,
    },
    cardContent: {
      padding: "0 3% 3% 3%",
    },
    shade: {
      position: "relative",
      width: "100%",
      height: "auto",
      marginBottom: 30,
    },
    containerCabecalho: {
      width: "100%",
      marginBottom: "2%",
    },
    filtroContainer: {
      display: "flex",
      alignItems: "center",
      gap: "32px",
    },
  };
};
export default withStyles(styles)(FiltroUsuario);
