import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { uniqueId } from "lodash";
import React, { useMemo } from "react";
import constants from "../../../constants/global";

export const CustomTableHeader = ({
  classes,
  colunas,
  ordenarPor,
  atualizaOrdenarPor,
  tipoOrdem,
  atualizaTipoOrdem,
  propriedadesCelulasCabecalho,
  tabelaEsquerda,
  idCabecalhoTabela = "",
}) => {
  const idUnico = useMemo(() => uniqueId(), []);

  const geradorDeChave = (coluna) => `${idUnico}_${coluna.id}`;

  const handleRequestSort = (coluna) => {
    const { id } = coluna;
    const ehAscendente =
      ordenarPor === id && tipoOrdem === constants.orderBy.asc;
    const novaOrdem = ehAscendente
      ? constants.orderBy.desc
      : constants.orderBy.asc;
    atualizaTipoOrdem(novaOrdem, coluna);
    atualizaOrdenarPor(id, coluna);
  };

  const renderTooltip = (coluna) =>
    coluna.infoMessage ? (
      <Tooltip
        title={coluna.infoMessage}
        classes={{ tooltip: classes.tooltipContent }}
        className={classes.tooltip}
        placement="right"
      >
        <InfoIcon color="primary" />
      </Tooltip>
    ) : null;

  const renderizarTituloOrdenavel = (coluna) => {
    let _classes = null;
    if (tabelaEsquerda) {
      _classes = coluna.infoMessage
        ? classes.paddingAdjustTitleWithTolltipLeft
        : classes.paddingAdjustTitleLeft;
    } else {
      _classes = coluna.infoMessage
        ? classes.paddingAdjustTitleWithTolltip
        : classes.paddingAdjustTitle;
    }

    return (
      <TableSortLabel
        active={ordenarPor === coluna.id}
        direction={ordenarPor === coluna.id ? tipoOrdem : constants.orderBy.asc}
        onClick={() => handleRequestSort(coluna)}
        data-testid="test-order-label"
        className={classes.adjustOrderMargin}
      >
        <span className={_classes}>{coluna.titulo}</span>
        {renderTooltip(coluna)}
      </TableSortLabel>
    );
  };

  const renderizarTitulo = (coluna) => {
    return (
      <>
        {coluna.titulo}
        {renderTooltip(coluna)}
      </>
    );
  };

  const getHeaderCellClasses = ({ headerCellClass }) =>
    [
      tabelaEsquerda ? classes.headerCellsLeft : classes.headerCellsCenter,
      headerCellClass,
    ].join(" ");

  return (
    <TableHead id={idCabecalhoTabela}>
      <TableRow>
        {colunas.map((coluna) => (
          <TableCell
            data-testid={`${coluna.id}-header`}
            key={geradorDeChave(coluna)}
            sortDirection={ordenarPor === coluna.id ? tipoOrdem : false}
            className={getHeaderCellClasses(coluna)}
            style={{
              ...(coluna.estilo || {
                padding: "4px 0 4px 24px",
                maxWidth: "140px",
                wordWrap: "break-word",
              }),
            }}
            {...propriedadesCelulasCabecalho}
          >
            {coluna.ordenavel === false
              ? renderizarTitulo(coluna)
              : renderizarTituloOrdenavel(coluna)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
