import { useEffect, useState } from 'react';
import { debounce } from '../../../shared/util';
import ClienteService from '../../cliente/clienteService';
import FazendaService from '../../fazenda/fazendaService';
import { PERMISSOES } from '../usuarioService';

export function useFiltroUsuario() {
  const [filtro, setFiltro] = useState({
    busca: {
      value: '',
      _value: '', //for debounce
    },
    clientes: {
      value: [],
      _value: [], //for debounce
      options: [],
      isLoading: false,
    },
    fazendas: {
      value: [],
      _value: [], //for debounce
      options: [],
      isLoading: false,
    },
    permissoes: {
      value: [],
      _value: [], //for debounce
      options: PERMISSOES.map(p => ({ label: p.name, value: p.id })),
      isLoading: false,
    }
  });

  const alterarValorFiltro = (campo) => (valor) => {
    if (valor && valor.target) {
      valor = valor.target.value;
    }

    setFiltro((prevState) => ({
      ...prevState,
      [campo]: {
        ...prevState[campo],
        _value: valor,
      },
    }));

    debounce(() => {
      if (campo === 'clientes') {
        setFiltro((prevState) => ({
          ...prevState,
          clientes: {
            ...prevState.clientes,
            value: valor,
          },
          fazendas: {
            ...prevState.fazendas,
            options: [],
            value: [],
            _value: [],
          },
        }));
      } else {
        setFiltro((prevState) => ({
          ...prevState,
          [campo]: {
            ...prevState[campo],
            value: valor,
          },
        }));
      }
    })
  };

  const alterarLoading = (campo, isLoading) => {
    setFiltro((prevState) => ({
      ...prevState,
      [campo]: {
        ...prevState[campo],
        isLoading,
      },
    }));
  };

  const buscaClientes = async () => {
    alterarLoading('clientes', true);
    const { data: clientes } = await ClienteService.obtemClientes();
    const clientesMapeados = clientes
      .map(cliente => ({ label: cliente.Nome, value: cliente.IdCliente }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setFiltro((prevState) => ({
      ...prevState,
      clientes: {
        ...prevState.clientes,
        options: clientesMapeados,
        isLoading: false,
      },
      fazendas: {
        ...prevState.fazendas,
        options: [],
      }
    }));
  };

  const buscaFazendas = async (idsCliente) => {
    if (!idsCliente || !idsCliente.length) {
      return;
    }
    alterarLoading('fazendas', true);
    const { data: fazendas } = await FazendaService.obtemFazendasPorIdsCliente(idsCliente);
    const fazendasMapeadas = fazendas
      .map(fazenda => ({ label: fazenda.Nome, value: fazenda.IdFazenda }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setFiltro((prevState) => ({
      ...prevState,
      fazendas: {
        ...prevState.fazendas,
        options: fazendasMapeadas,
        isLoading: false,
      },
    }));
  }

  useEffect(() => {
    buscaClientes()
  }, []);

  useEffect(() => {
    const clientes = filtro.clientes.value;
    if (clientes.length) {
      buscaFazendas(clientes.map(cliente => cliente.value))
    }
  }, [filtro.clientes.value]);

  return { filtro, alterarValorFiltro };
}