import axios from 'axios';

const URI_USUARIO = '/usuarios/api/v1/usuarios';
const URL_USUARIO_V2 = '/usuarios/api/v2/usuarios';

class UsuarioClienteService {
  async obtemClientesUsuario(IdUsuario) {
    return axios.get(`${URI_USUARIO}/${IdUsuario}/clientes`);
  }

  async salvarVinculosUsuarioCliente(IdUsuario, idsClientesVincular) {
    return axios.post(`${URL_USUARIO_V2}/${IdUsuario}/clientes/bulk`, idsClientesVincular)
  }

  async salvarVinculosUsuarioClienteComFinanceiro(IdUsuario, clientesVincular) {
    return axios.post(`${URI_USUARIO}/${IdUsuario}/clientes/financeiro/bulk`, clientesVincular)
  }

  async excluiVinculoUsuarioPorCliente(idCliente) {
    return axios.delete(`${URI_USUARIO}/clientes/${idCliente}`);
  }
}

export default new UsuarioClienteService();
