import React from 'react';

export const expansionList = Component => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        itemSelecionado: null
      };
    }

    expandeItem = panel => {
      this.setState(prevState => {
        return {
          itemSelecionado: prevState.itemSelecionado === panel ? -1 : panel
        };
      });
    };

    render() {
      return (
        <Component
          {...this.props}
          itemSelecionado={this.state.itemSelecionado}
          expandeItem={this.expandeItem}
        />
      );
    }
  };
};

export default expansionList;
