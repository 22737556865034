import {
  EXIBIR_CARREGANDO
} from "./loadingAction";

export const initialState = {
  exibirCarregando: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXIBIR_CARREGANDO:
      return { ...state, exibirCarregando: action.payload };
    default:
      return state;
  }
};
