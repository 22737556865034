import { exibirCarregando } from "../../../shared/components/Loading/redux/loadingAction";
import * as UIActions from "../../../shared/redux/UIActions";
import { notificacaoActions } from "../../notificacao/redux";
import ClienteManager from "../clienteManager";
import * as clienteActions from "./clienteActions";

export const MSG = {
  OBTEM_CLIENTES_FALHA: "Não foi possível listar clientes",
  ATUALIZA_CLIENTE_SUCESSO: "Cliente atualizado com sucesso",
  ATUALIZA_CLIENTE_FALHA: "Não foi possível atualizar cliente",
  CRIA_CLIENTE_SUCESSO: "Cliente cadastrado com sucesso",
  CRIA_CLIENTE_FALHA: "Não foi possível cadastrar cliente",
  EXCLUI_CLIENTE_SUCESSO: "Cliente excluido com sucesso",
  EXCLUI_CLIENTE_FALHA: "Não foi possível excluir cliente",
  OBTEM_HISTORICO_FALHA: "Não foi possível listar histórico",
  SUSPENDE_CLIENTE_SUCESSO: "Cliente suspenso com sucesso",
  SUSPENDE_CLIENTE_FALHA: "Não foi possível suspender cliente",
  INATIVA_CLIENTE_SUCESSO:
    "O cliente foi inativado com sucesso. Para visualizá-lo, use o filtro de Status",
  ERRO_GENERICO_CLIENTE_FALHA: "Ocorreu um erro, por favor tente novamente",
  ATIVA_CLIENTE_SUCESSO: "Cliente ativado com sucesso.",
  RESTAURA_ACESSO_CLIENTE_SUCESSO: "Cliente restaurado com sucesso.",
};

const obtemClientes = () => async (dispatch) => {
  try {
    dispatch(UIActions.setLoading());
    const clientes = await ClienteManager.obtemClientes();
    dispatch(clienteActions.setClientes(clientes));
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(error.message || MSG.OBTEM_CLIENTES_FALHA)
    );
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const salvaCliente =
  (IdCliente, clienteSalvar, usuariosAdministradores) => async (dispatch) => {
    try {
      dispatch(UIActions.setLoading());
      const clienteSalvo = await ClienteManager.salvaCliente(
        IdCliente,
        clienteSalvar,
        usuariosAdministradores
      );
      if (clienteSalvo) {
        const clientes = await ClienteManager.obtemClientes();
        dispatch(clienteActions.setClientes(clientes));
        dispatch(
          notificacaoActions.notificaSucesso(
            IdCliente ? MSG.ATUALIZA_CLIENTE_SUCESSO : MSG.CRIA_CLIENTE_SUCESSO
          )
        );
      } else {
        dispatch(
          notificacaoActions.notificaErro(
            IdCliente ? MSG.ATUALIZA_CLIENTE_FALHA : MSG.CRIA_CLIENTE_FALHA
          )
        );
      }
    } catch (error) {
      dispatch(
        notificacaoActions.notificaErro(
          error.message || MSG.ATUALIZA_CLIENTE_FALHA
        )
      );
    } finally {
      dispatch(UIActions.setLoading());
    }
  };

const excluiCliente = (IdCliente) => async (dispatch) => {
  try {
    dispatch(UIActions.setLoading());
    const clienteExcluido = await ClienteManager.excluiCliente(IdCliente);
    if (clienteExcluido) {
      const clientes = await ClienteManager.obtemClientes();
      dispatch(clienteActions.setClientes(clientes));
      dispatch(notificacaoActions.notificaSucesso(MSG.EXCLUI_CLIENTE_SUCESSO));
    } else {
      dispatch(notificacaoActions.notificaErro(MSG.EXCLUI_CLIENTE_FALHA));
    }
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(error.message || MSG.EXCLUI_CLIENTE_FALHA)
    );
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const obtemClientePorId = (IdCliente) => async (dispatch) => {
  try {
    dispatch(UIActions.setLoading());
    const cliente = await ClienteManager.obtemClientePorId(IdCliente);
    return cliente;
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(error.message || MSG.OBTEM_CLIENTES_FALHA)
    );
  } finally {
    dispatch(UIActions.setLoading());
  }
};
const obtemClientePorIdIncluindoExcluidos = (IdCliente) => async (dispatch) => {
  try {
    dispatch(UIActions.setLoading());
    const cliente = await ClienteManager.obtemClientePorIdIncluindoExcluidos(
      IdCliente
    );
    return cliente;
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(error.message || MSG.OBTEM_CLIENTES_FALHA)
    );
  } finally {
    dispatch(UIActions.setLoading());
  }
};
const suspendeCliente = (IdCliente, motivo) => async (dispatch) => {
  try {
    dispatch(exibirCarregando(true));
    const clienteSuspenso = await ClienteManager.suspendeCliente(
      IdCliente,
      motivo
    );
    if (clienteSuspenso) {
      const clientes = await ClienteManager.obtemClientes();
      dispatch(clienteActions.setClientes(clientes));
      dispatch(
        notificacaoActions.notificaSucesso(MSG.SUSPENDE_CLIENTE_SUCESSO)
      );
    } else {
      dispatch(notificacaoActions.notificaErro(MSG.SUSPENDE_CLIENTE_FALHA));
    }
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(
        error.message || MSG.SUSPENDE_CLIENTE_FALHA
      )
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

const obtemHistoricoCliente =
  (IdCliente, pagina, quantidade) => async (dispatch) => {
    try {
      dispatch(UIActions.setLoading());
      const historico = await ClienteManager.obtemHistoricoCliente(
        IdCliente,
        pagina,
        quantidade
      );
      return historico;
    } catch (error) {
      dispatch(
        notificacaoActions.notificaErro(
          error.message || MSG.OBTEM_HISTORICO_FALHA
        )
      );
    } finally {
      dispatch(UIActions.setLoading());
    }
  };

const inativaCliente = (IdCliente, motivo) => async (dispatch) => {
  try {
    dispatch(exibirCarregando(true));
    const clienteInativado = await ClienteManager.inativaCliente(
      IdCliente,
      motivo
    );
    if (clienteInativado) {
      const clientes = await ClienteManager.obtemClientes();
      dispatch(clienteActions.setClientes(clientes));
      dispatch(notificacaoActions.notificaSucesso(MSG.INATIVA_CLIENTE_SUCESSO));
    } else {
      dispatch(
        notificacaoActions.notificaErro(MSG.ERRO_GENERICO_CLIENTE_FALHA)
      );
    }
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(
        error.message || MSG.ERRO_GENERICO_CLIENTE_FALHA
      )
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

const ativaCliente = (IdCliente, motivo) => async (dispatch) => {
  try {
    dispatch(exibirCarregando(true));
    const clienteAtivado = await ClienteManager.ativaCliente(IdCliente, motivo);
    if (clienteAtivado) {
      const clientes = await ClienteManager.obtemClientes();
      dispatch(clienteActions.setClientes(clientes));
      dispatch(notificacaoActions.notificaSucesso(MSG.ATIVA_CLIENTE_SUCESSO));
    } else {
      dispatch(
        notificacaoActions.notificaErro(MSG.ERRO_GENERICO_CLIENTE_FALHA)
      );
    }
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(
        error.message || MSG.ERRO_GENERICO_CLIENTE_FALHA
      )
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

const restauraAcessoCliente = (IdCliente, motivo) => async (dispatch) => {
  try {
    dispatch(exibirCarregando(true));
    const clienteRestaurado = await ClienteManager.restauraAcessoCliente(
      IdCliente,
      motivo
    );
    if (clienteRestaurado) {
      const clientes = await ClienteManager.obtemClientes();
      dispatch(clienteActions.setClientes(clientes));
      dispatch(
        notificacaoActions.notificaSucesso(MSG.RESTAURA_ACESSO_CLIENTE_SUCESSO)
      );
    } else {
      dispatch(
        notificacaoActions.notificaErro(MSG.ERRO_GENERICO_CLIENTE_FALHA)
      );
    }
  } catch (error) {
    dispatch(
      notificacaoActions.notificaErro(
        error.message || MSG.ERRO_GENERICO_CLIENTE_FALHA
      )
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export {
  ativaCliente,
  excluiCliente,
  inativaCliente,
  obtemClientePorId,
  obtemClientePorIdIncluindoExcluidos,
  obtemClientes,
  obtemHistoricoCliente,
  restauraAcessoCliente,
  salvaCliente,
  suspendeCliente,
};
