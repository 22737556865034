import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const contadorCaracteres = props => {
  const { maximoPermitido, totalCaracteres } = props;
  return (
    <Grid
      style={{ width: '100%', padding: '10px 0' }}
      container
      direction="row"
      justify="flex-end"
    >
      <Typography variant="subtitle2" color="default">
        <span id="txtTotalCaracteres">{totalCaracteres}</span>/
        <span id="txtMaximoPermitido">{maximoPermitido}</span>
      </Typography>
    </Grid>
  );
};
export default contadorCaracteres;
