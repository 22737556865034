import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import ContainerAbreAnimado from "./containerAbreAnimado";

export const cabecalhoLista = (props) => {
  const {
    classes,
    textoFiltrar,
    alteraTextoFiltro,
    formularioAberto,
    abreFechaFormNovoCadastro,
    tituloBotaoCriarNovo,
    labelCampoBusca,
    formulario,
  } = props;
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        className={classes.containerCabecalho}
      >
        <Grid>
          <TextField
            id="iptBuscaFiltro"
            margin="dense"
            label={labelCampoBusca}
            value={textoFiltrar}
            onChange={alteraTextoFiltro}
            style={{
              width: 360,
            }}
            inputProps={{
              maxLength: 50,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid>
          <Button
            className={classes.newClientBtn}
            id="btnCriarNovo"
            variant="contained"
            color="primary"
            onClick={abreFechaFormNovoCadastro}
          >
            {tituloBotaoCriarNovo}
          </Button>
        </Grid>
      </Grid>
      {props.formulario && (
        <ContainerAbreAnimado isVisible={formularioAberto}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid
                container
                direction="column"
                style={{ padding: "16px 8px 0 8px" }}
              >
                {formulario}
              </Grid>
            </CardContent>
          </Card>
        </ContainerAbreAnimado>
      )}
    </React.Fragment>
  );
};

cabecalhoLista.defaultProps = {
  textoFiltrar: "",
  alteraTextoFiltro: () => { },
  formularioAberto: false,
  abreFechaFormNovoCadastro: () => { },
  tituloBotaoCriarNovo: "Novo",
  labelCampoBusca: "Buscar",
  formulario: null,
};

const styles = () => {
  return {
    card: {
      borderRadius: 0,
    },
    cardContent: {
      padding: "0 3% 3% 3%",
    },
    shade: {
      position: "relative",
      width: "100%",
      height: "auto",
      marginBottom: 30,
    },
    containerCabecalho: {
      width: "100%",
      marginBottom: "2%",
    },
  };
};
export default withStyles(styles)(cabecalhoLista);
