import { MenuItem, TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import React from "react";
import SkeletonLoading from "../SkeletonLoading";

function AutoCompleteMultiSelect({
  classes,
  disabled,
  isLoading,
  style = {},
  label,
  options = [],
  value,
  onChange,
}) {
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [limit, setLimit] = React.useState(10);

  const isSelectedAll = value && value.length === options.length;

  const filteredOptions = options
    .filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    .map((option) => ({
      ...option,
      checked: isChecked(option.value),
    }))
    .sort((a, b) => {
      if (a.checked && !b.checked) {
        return -1;
      }
      if (!a.checked && b.checked) {
        return 1;
      }
      return a.label.localeCompare(b.label);
    })
    .slice(0, limit);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOptionClick = (option) => {
    setInputValue("");

    if (option.value === "all") {
      if (isSelectedAll) {
        return onChange([]);
      }
      return onChange(options);
    }

    const isAlreadySelected = value.some((v) => v.value === option.value);

    if (isAlreadySelected) {
      return onChange(value.filter((v) => v.value !== option.value));
    }

    onChange([...value, option]);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setOpen(false);
      setLimit(10);
      setInputValue("");
    }, 250);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleScroll = (event) => {
    const { target } = event;
    const bottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 10;

    if (bottom && limit < options.length) {
      setLimit(limit + 10);
    }
  };

  function isChecked(v) {
    return v && value && value.some((item) => item.value === v);
  }

  const combinedValue =
    open || inputValue
      ? inputValue
      : (value && !value.length) || (value && value.length === options.length)
      ? "TODOS"
      : value && value.map((v) => v.label).join(", ");

  if (isLoading) {
    return <SkeletonLoading height={48} width={style.width || "100%"} />;
  }

  return (
    <div style={style} className={classes.container}>
      <TextField
        disabled={disabled}
        label={label}
        value={combinedValue}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ width: "100%" }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <EndAdornment />,
        }}
      />
      {open && (
        <Options
          classes={classes}
          options={filteredOptions}
          allOptionShown={!inputValue}
          value={value}
          isSelectedAll={isSelectedAll}
          onChange={handleOptionClick}
          onScroll={handleScroll}
        />
      )}
    </div>
  );
}

function Options({
  classes,
  options,
  allOptionShown,
  value,
  isSelectedAll,
  onChange,
  onScroll,
}) {
  const onSelectOption = (value) => () => {
    onChange(value);
  };
  const onSelectAll = () => {
    onChange({ label: "Todos", value: "all" });
  };

  return (
    <div className={classes.optionsContainer} onScroll={onScroll}>
      <List
        options={options}
        allOptionShown={allOptionShown}
        value={value}
        isSelectedAll={isSelectedAll}
        onSelectOption={onSelectOption}
        onSelectAll={onSelectAll}
      />
    </div>
  );
}

function List({
  options,
  allOptionShown,
  isSelectedAll,
  onSelectOption,
  onSelectAll,
}) {
  if (options.length === 0) {
    return <MenuItem>Nenhum resultado encontrado</MenuItem>;
  }
  return (
    <>
      {allOptionShown ? (
        <MenuItem onClick={onSelectAll}>
          <Checkbox checked={isSelectedAll} />
          <span style={{ marginLeft: "4px" }}>Todos</span>
        </MenuItem>
      ) : null}
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={onSelectOption(option)}
        >
          <Checkbox checked={option.checked} />
          <span style={{ marginLeft: "4px" }}>{option.label}</span>
        </MenuItem>
      ))}
    </>
  );
}

function EndAdornment() {
  return (
    <div>
      <ArrowDropDown width={24} height={24} />
    </div>
  );
}

const styles = () => ({
  container: {
    width: "350px",
  },
  optionsContainer: {
    width: "350px",
    marginTop: "8px",
    maxHeight: "300px",
    overflowY: "auto",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",

    "& .MuiMenuItem-root": {
      fontFamily: "Roboto",
      fontSize: "16px",
    },
  },
});

export default withStyles(styles)(AutoCompleteMultiSelect);
