import { createMuiTheme } from "@material-ui/core/styles";

const tabela = {
  borderRadius: "16px",
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: 9,
      height: 10,
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "16px",
    }
  },
};

export default createMuiTheme({
  palette: {
    primary: {
      main: "#307297",
    },
    secondary: {
      main: "#4da32f",
    },
    error: {
      main: "#fa3336",
    },
    textSecondary: {
      main: "#333333",
    },
    default: {
      main: "#ffffff",
    },
    default1: {
      main: "#333333",
      light: "#757575",
    },
    default2: {
      light: "#e1e1e1",
      main: "#666666",
    },
    default3: {
      main: "#999999",
    },
    disabled: {
      main: "#c7c7cc",
      light: "#fafafa",
    },
    white: {
      main: "#ffffff",
    },
    complementary: {
      main: "#CBD938",
    },
  },
  tabela,
  typography: {
    useNextVariants: true,
    fontFamily: "Roboto, sans-serif",
  },
});
