import memoize from "memoize-one";
import { normalizarString } from "../../shared/util";

export const retornaIdRelatorio = (Id) => {
  return Id && Id.substr(Id.indexOf("-") + 1, Id.length);
};
export const retornaTextoRelatorioFiltro = (Filtro, tipoFiltro) => {
  return Filtro
    ? `Contém Filtro de ${tipoFiltro}`
    : `Não Contém Filtro de ${tipoFiltro}`;
};
export const filterRelatoriosMemoize = memoize((list, textoFiltrar) =>
  list.filter((relatorios) => {
    const valorNormalizado = normalizarString(relatorios.Valor);
    const nomeNormalizado = normalizarString(relatorios.Nome);
    const textoFiltrarFormatado = normalizarString(textoFiltrar);
    return (
      nomeNormalizado.includes(textoFiltrarFormatado) ||
      valorNormalizado.includes(textoFiltrarFormatado) ||
      normalizarString(retornaTextoRelatorioFiltro(relatorios.Filtro)).includes(
        textoFiltrarFormatado
      )
    );
  })
);
