import { Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ArrowBack } from "@material-ui/icons";
import React from "react";

export const pageCadastro = (props) => {
  const { classes, goBackArea } = props;
  return (
    <React.Fragment>
      {goBackArea && (
        <Grid item className={classes.goBackArea}>
          <Button
            onClick={goBackArea.action ? goBackArea.action : () => {}}
            className={classes.btnVoltar}
          >
            <ArrowBack />
          </Button>
          <Typography
            variant="h5"
            className={classes.txtGoBackArea}
            id="txtGoBackArea"
          >
            {goBackArea.text ? goBackArea.text : ""}
          </Typography>
        </Grid>
      )}

      <Grid item className={classes.containerTituloPagina}>
        <Typography
          variant="h5"
          className={classes.tituloPagina}
          id="txtTituloPagina"
        >
          {props.titulo}
        </Typography>
      </Grid>
      <div className={classes.containerPrincipal} id="conteudoPagina">
        <Grid
          container
          item
          justify="flex-start"
          className={classes.containerConteudoPagina}
        >
          {props.conteudo}
        </Grid>
      </div>
    </React.Fragment>
  );
};

const styles = (theme) => ({
  containerPrincipal: {
    display: "flex",
    paddingBottom: 100,
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: theme.palette.secondary.main,
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: theme.palette.primary.main,
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      // backgroundColor: 'red',
      width: "100%",
    },
  },
  btnVoltar: {
    color: theme.palette.primary.main,
  },
  goBackArea: {
    display: "flex",
    paddingBottom: "1rem",
  },
  txtGoBackArea: {
    alignContent: "center",
    fontWeight: "bold",
  },
  containerConteudoPagina: {
    width: "100%",
  },
  containerTituloPagina: {
    width: "100%",
    minWidth: 200,
  },
  tituloPagina: {
    fontWeight: "bold",
    paddingBottom: "1rem",
  },
});

pageCadastro.defaultProps = {
  titulo: "",
  conteudo: null,
};
export default withStyles(styles)(pageCadastro);
