export default (theme) => ({
  container: {
    height: "100vh",
    padding: 0,
    margin: 0,
    boxSizing: "border-box",
    backgroundColor: "#fff",
    fontFamily: '"Roboto", "sans-serif"',
  },
  loaderWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    zIndex: "10",
  },
  loader: {
    border: "3px solid #666666",
    borderRadius: "50%",
    borderTop: "3px solid #ffffff",
    width: "40px",
    height: "40px",
    "-webkit-animation": "spin 1s linear infinite",
    animation: "spin 1s linear infinite",
    zIndex: "50",
    display: "inline-block",
  },
  loginPage: {
    display: "grid",
    gridTemplateColumns: "40% 60%",
    height: "100%",
    "@media (max-width: 768px)": {
      display: "grid",
      gridTemplateColumns: "1fr",
      height: "100%",
    },
  },
  form: {
    marginTop: "80px",
  },
  loginForm: {
    width: "clamp(100px, 100%, 360px)",
    margin: "0 auto",
    padding: "0 8px",
  },
  logoWrapper: {
    marginTop: "60px",
    "@media (max-width: 768px)": {
      marginTop: "40px",
    },
  },
  logoWrapperImg: {
    width: "100%",
    maxWidth: "140px",
  },
  greetings: {
    marginTop: "60px",
    "@media (max-width: 768px)": {
      marginTop: "40px",
    },
  },
  greetingWelcome: {
    color: "#333333",
    fontSize: "28px",
    padding: 0,
    margin: 0,
  },
  greetingsLogin: {
    marginTop: "16px",
    fontSize: "1.125rem",
    color: "#757575",
  },
  greetingTitle: {
    fontSize: "18px",
    color: "#757575",
    marginTop: "16px",
  },
  greetingSubtitle: {
    fontSize: "16px",
    color: "#757575",
    marginTop: "16px",
  },
  formControlLabel: {
    position: "absolute",
    top: "-10px",
    left: "8px",
    backgroundColor: "#fff",
    padding: "0 4px",
    "&:error": {
      color: "#ff2851",
    },
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    paddingRight: "16px",

    position: "relative",
    marginBottom: "24px",
    borderRadius: "4px",
    border: "1px solid #bdbdbd",
    "&:label": {
      position: "absolute",
      top: "-10px",
      left: "8px",
      padding: "0 4px",
    },
    "&:error": {
      border: "solid 1px #ff2851 !important",
    },
    "&: + &": {
      marginBottom: "16px",
    },
  },
  onError: {
    border: "solid 1px #ff2851 !important",
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  loginInput: {
    width: "100%",
    height: "40px",
    padding: "12px 16px",
    border: "none",
    borderRadius: "4px",
    outline: "none",
    "&::placeholder": {
      color: "#9e9e9e",
      fontSize: "0.875rem",
    },
  },
  loginPasswordInput: {
    width: "100%",
    height: "40px",
    padding: "12px 16px",
    border: "none",
    borderRadius: "4px",
    outline: "none",
    "&::placeholder": {
      color: "#9e9e9e",
      fontSize: "0.875rem",
    },
    "&::-ms-reveal,&::-ms-clear": {
      display: "none",
    },
  },
  imageInicialPage: {
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    size: "50%",
  },
  loginButton: {
    width: "100%",
    maxWidth: "360px",
    height: "42px",
    padding: "6px 16px",
    border: "none",
    borderRadius: "4px",
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -2px rgba(0, 0, 0, 0.2)",
    fontSize: "1rem",
    fontWeight: "500",
    textAlign: "center",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "#307297",
    "&:hover,&:visited,&:focus,&:link,&:active": {
      backgroundColor: "#30719780",
      color: "#fff",
      borderRadius: "4px",
      width: "100%",
    },
    "&:disabled": {
      border: "1px solid #999999",
      color: "#999999",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },

  bgOxWrapper: {
    backgroundImage:
      'url("https://storage.googleapis.com/armazenamento-imagens/assets/new_image_farmtell_views.png")',
    backgroundSize: "cover",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },

  forgotPasswordWrapper: {
    marginTop: "-8px",
    marginBottom: "89px",
  },

  forgotPasswordLink: {
    textDecoration: "underline",
    color: "#0053A0",
    fontWeight: "400",
    cursor: "pointer",
    fontSize: "14px",
    "&:hover": {
      color: "#0053A0",
    },
    "&:visited, &:focus, &:link, &:active": {
      color: "#0053A0",
    },
  },

  forgotPassPageLink: {
    display: "inline-block",
    marginBottom: "60px",
    "@media (max-width: 768px)": {
      marginBottom: "40px",
    },
  },

  forgotPassPage: {
    padding: "48px 24px 0",
    height: "100%",
    maxWidth: "684px",
    margin: "0 auto",
  },

  navigateToLoginLink: {
    cursor: "pointer",
    marginTop: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    textAlign: "center",
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:active, &:visited, &:focus, &:link": {
      color: theme.palette.primary.main
    }
  },

  sendResetLinkButton: {
    width: "100%",
    maxWidth: "684px",
    marginTop: "24px",
    height: "42px",
    padding: "6px 16px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#307297",
    color: "#fff",
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -2px rgba(0, 0, 0, 0.2)",
    fontSize: "1rem",
    fontWeight: "500",
    textAlign: "center",
    cursor: "pointer",
    "&:hover,&:visited,&:focus,&:link,&:active": {
      backgroundColor: "#30719780",
      color: "#fff",
      borderRadius: "4px",
      width: "100%",
    },
    "&:disabled": {
      border: "1px solid #999999",
      color: "#999999",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },

  forgotPassPagelogoWrapper: {
    marginTop: 0,
  },

  imgForgotPassPage: {
    width: "100%",
    maxWidth: "274px",
  },

  forgotPassPageHeaderInfo: {
    margin: "64px 0 24px",
  },

  resetPassword: {
    fontWeight: 500,
    color: "#6e6e6e",
    fontSize: "1.125rem",
  },

  emailInput: {
    width: "100%",
    height: "40px",
    padding: "12px 16px",
    border: "none",
    borderRadius: "4px",
    outline: "none",
    "&:placeholder": {
      color: "#9e9e9e",
      fontSize: "0.875rem",
    },
  },
  resetPasswordInfo: {
    color: "#757575",
    fontSize: "13px",
    marginTop: 0,
    marginBottom: "102px",
  },
  phoneNumber: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  arrowIcon: {
    marginRight: "16px",
    width: "20px",
    height: "20px"
  },
  submitButton: {
    width: "100%",
    padding: "14px 0",
    border: "none",
    borderRadius: "4px",
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -2px rgba(0, 0, 0, 0.2)",
    fontSize: "14px",
    textAlign: "center",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "#307297",
    "&:hover,&:visited,&:focus,&:link,&:active": {
      backgroundColor: "#30719780",
      color: "#fff",
    },
    "&:disabled": {
      border: "1px solid #999999",
      color: "#999999",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  formFooter: {
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  formFooterInfo: {
    fontSize: "0.937rem",
  },
  formFooterA: {
    fontSize: "0.875rem",
  },
  sendEmailSuccess: {
    visibility: "hidden",
    minWidth: "200px",
    backgroundColor: "#2d9842",
    color: "#fff",
    textAlign: "center",
    borderRadius: "2px",
    padding: "16px",
    zIndex: 1,
    bottom: "5%",
    left: "50%",
    marginRight: "-50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    "&:show": {
      visibility: "visible",
      "-webkit-animation": "fadein 0.5s, fadeout 0.5s 5s",
      animation: "fadein 0.5s, fadeout 0.5s 5s",
    },
  },
  sendEmailError: {
    visibility: "hidden",
    minWidth: "200px",
    backgroundColor: "#ff2851",
    color: "#fff",
    textAlign: "center",
    borderRadius: "2px",
    padding: "16px",
    zIndex: 1,
    bottom: "5%",
    left: "50%",
    marginRight: "-50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    "&:show": {
      visibility: "visible",
      "-webkit-animation": "fadein 0.5s, fadeout 0.5s 5s",
      animation: "fadein 0.5s, fadeout 0.5s 5s",
    },
  },
});
