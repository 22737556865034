import React from 'react';
import { Grid } from '@material-ui/core';
const contentTabLayout = props => {
  return (
    <Grid container direction="column" style={{ minHeight: '50vh', padding: '2% 0' }}>
      {props.children}
    </Grid>
  );
};

export default contentTabLayout;
