import * as UIActions from "../../../shared/redux/UIActions";
import { notificacaoActions } from "../../notificacao/redux";
import FazendaManager from "../fazendaManager";
import * as fazendasActions from "./fazendaActions";

export const MSG = {
  OBTEM_FAZENDAS_FALHA: "Não foi possível listar fazendas",
  ATUALIZA_FAZENDA_SUCESSO: "Fazenda atualizada com sucesso",
  ATUALIZA_FAZENDA_FALHA: "Não foi possível atualizar fazenda",
  CRIA_FAZENDA_SUCESSO: "Fazenda cadastrada com sucesso",
  CRIA_FAZENDA_FALHA: "Não foi possível cadastrar fazenda",
  EXCLUI_FAZENDA_SUCESSO: "Fazenda excluida com sucesso",
  EXCLUI_FAZENDA_FALHA: "Não foi possível excluir fazenda",
};

const obtemFazendasIdNome = () => async (dispatch) => {
  try {
    const fazendas = await FazendaManager.obtemFazendasIdNome();
    dispatch(fazendasActions.setFazendas(fazendas));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};

const obtemFazendasIdCliente = (IdUsuario) => async (dispatch) => {
  try {
    dispatch(UIActions.setLoading());
    const fazendas = await FazendaManager.obtemFazendasIdCliente(IdUsuario);
    dispatch(fazendasActions.setFazendasCadastro(fazendas));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  } finally {
    dispatch(UIActions.removeLoading());
  }
};

const obtemFazendas = () => async (dispatch) => {
  try {
    dispatch(UIActions.setLoading());
    const fazendasCadastro = await FazendaManager.obtemFazendas();
    dispatch(fazendasActions.setFazendasCadastro(fazendasCadastro));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const salvaFazenda = (IdFazenda, fazendaSalvar) => async (dispatch) => {
  try {
    dispatch(UIActions.setLoading());
    const fazendaSalva = await FazendaManager.salvaFazenda(
      IdFazenda,
      fazendaSalvar
    );
    if (fazendaSalva) {
      const fazendas = await FazendaManager.obtemFazendas();
      dispatch(fazendasActions.setFazendasCadastro(fazendas));
      dispatch(
        notificacaoActions.notificaSucesso(
          IdFazenda ? MSG.ATUALIZA_FAZENDA_SUCESSO : MSG.CRIA_FAZENDA_SUCESSO
        )
      );
    } else {
      dispatch(
        notificacaoActions.notificaErro(
          IdFazenda ? MSG.ATUALIZA_FAZENDA_FALHA : MSG.CRIA_FAZENDA_FALHA
        )
      );
    }
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(MSG.ATUALIZA_FAZENDA_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const excluiFazenda = (IdFazenda) => async (dispatch) => {
  try {
    dispatch(UIActions.setLoading());
    const fazendaExcluida = await FazendaManager.excluiFazenda(IdFazenda);
    if (fazendaExcluida) {
      const fazendas = await FazendaManager.obtemFazendas();
      dispatch(fazendasActions.setFazendasCadastro(fazendas));
      dispatch(notificacaoActions.notificaSucesso(MSG.EXCLUI_FAZENDA_SUCESSO));
    } else {
      dispatch(notificacaoActions.notificaErro(MSG.EXCLUI_FAZENDA_FALHA));
    }
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(MSG.EXCLUI_FAZENDA_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

export {
  obtemFazendasIdNome,
  obtemFazendas,
  salvaFazenda,
  excluiFazenda,
  obtemFazendasIdCliente,
};
