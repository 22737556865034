import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { notificacaoActions } from '../../features/notificacao/redux';
const withNotificacaoHOC = WrappedComponent => {
  return class extends PureComponent {
    render() {
      const propsPassarComponentFilho = {
        ...this.props
      };
      return <WrappedComponent {...propsPassarComponentFilho} />;
    }
  };
};

const mapDispatchToProps = {
  notificaErro: notificacaoActions.notificaErro,
  notificaSucesso: notificacaoActions.notificaSucesso
};

const withNotificacao = compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withNotificacaoHOC
);

export default withNotificacao;
