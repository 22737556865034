import _ from 'lodash';

export const TABS_PATH_HASH = [
  {
    index: 0,
    tituloTab: 'Usuário cliente',
    path: '#usuariocliente',
    desabilitada: false
  },
  {
    index: 1,
    tituloTab: 'Usuário fazenda',
    path: '#usuariofazenda',
    desabilitada: false
  },
  {
    index: 2,
    tituloTab: 'Usuário retiro',
    path: '#usuarioretiro',
    desabilitada: false
  },
  {
    index: 3,
    tituloTab: 'Permissões do usuário',
    path: '#permissoes',
    desabilitada: false
  }
];

export const obtemTabPeloHash = hash => {
  if (!hash.length) {
    return TABS_PATH_HASH[0];
  }
  const tab = _.find(TABS_PATH_HASH, tab => tab.path.includes(hash));
  return tab;
};
