import React from "react";
import {
  CheckboxList,
  CheckboxItem,
  CheckboxListHeader,
  FeedbackTab,
  ContentTabLayout,
  DialogForm
} from "../../../shared/components";

import { useSelecionarTodosVinculos } from "../../../shared/hooks";
import { VerificaClienteAcessoFinanceiro } from "../usuarioClienteVinculoUtil";
import { descriptografar } from '../../../shared/util/crypto';

export function UsuarioClienteVinculoTab(props) {
  const { dadosCarregados, clientes } = props;
  let { senhaFinanceiro } = '';
  const [filterText, setFilterText] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const {
    todosSelecionados,
    setTodosSelecionados,
    selecionarTodos,
    vinculosSalvar
  } = useSelecionarTodosVinculos([...props.clientes]);
  /* istanbul ignore next */
  const selecionaCliente = (item, selecionado) => {
    vinculosSalvar[item.index].usuarioVinculado = selecionado;
    if (todosSelecionados) {
      setTodosSelecionados(false);
    }
  };

  /* istanbul ignore next */
  const setPasswordDialog = (value) => {
    senhaFinanceiro = value;
  }
  /* istanbul ignore next */
  const handleClose = () => {
    setOpen(false);
  }

  /* istanbul ignore next */
  const handleConfirm = () => {
    if (senhaFinanceiro !== undefined && senhaFinanceiro.length >= 6) {
      setOpen(false);
      props.salvarVinculosUsuarioClienteComFinanceiro(vinculosSalvar, senhaFinanceiro);
    }
    else {
      alert("A senha deve ter o mínimo de 6 caracteres.")
    }
  }
  /* istanbul ignore next */
  const handleCancel = () => {
    setOpen(false);
    setPasswordDialog('');
    props.salvarVinculosUsuarioCliente(vinculosSalvar, '');
  }
  /* istanbul ignore next */
  const salvarVinculosUsuarioCliente = () => {
    if (VerificaClienteAcessoFinanceiro(vinculosSalvar)) {
      setOpen(true);
    } else {
      props.salvarVinculosUsuarioCliente(vinculosSalvar);
    }
  };


  const renderClientes = () => {
    if (dadosCarregados && clientes.length === 0) {
      return <FeedbackTab mensagem="Não foi possível listar clientes" />;
    } else if (clientes.length > 0) {
      if (props.senhaFinanceiro !== null && props.senhaFinanceiro !== undefined) {
        senhaFinanceiro = descriptografar(props.senhaFinanceiro);
      }
      return (
        <React.Fragment>
          <CheckboxListHeader
            salvarAlteracoes={salvarVinculosUsuarioCliente}
            todosSelecionados={todosSelecionados}
            selecionarTodos={selecionarTodos}
            filterText={filterText}
            setFilterText={setFilterText}
          />
          <DialogForm
            tituloDialog={'Acesso Financeiro'}
            textoDialog={'Um ou mais clientes possuem acesso o módulo financeiro. Informe uma senha caso queira acesso a esse módulo.'}
            abreDialog={open}
            fechaDialog={handleClose}
            cancelarAcaoDialog={handleCancel}
            confirmarAcaoDialog={handleConfirm}
            tipoEntradaDados={'password'}
            tituloCampo={'Senha'}
            valorCampo={senhaFinanceiro}
            setValor={setPasswordDialog}
          />
          <CheckboxList
            filterText={filterText}
            itens={vinculosSalvar}
            campoFiltrar="Nome"
            renderItem={
              /* istanbul ignore next */
              cliente => (
                <CheckboxItem
                  todosSelecionados={todosSelecionados}
                  item={cliente}
                  campoLabel="Nome"
                  campoValue="IdCliente"
                  selecionado={cliente.usuarioVinculado}
                  onChange={selecionaCliente}
                  key={cliente.IdCliente}
                />
              )}
          />
        </React.Fragment>
      );
    }
    return null;
  };
  return <ContentTabLayout>{renderClientes()}</ContentTabLayout>;
}

export default UsuarioClienteVinculoTab;
