import RetiroService from "./retiroService";

export const MSG = {
  ERRO_OBTER_RETIROS_FAZENDA: "Ocorreu um erro ao tentar retornar retiros",
};

class RetiroManager {
  async obtemRetirosFazendas(idsFazendas) {
    try {
      const filtro = {
        where: { IdFazenda: { $in: idsFazendas }, Excluido: false },
      };
      const respostaServicoRetiro = await RetiroService.obtemRetiros(filtro);
      return respostaServicoRetiro.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_RETIROS_FAZENDA);
    }
  }
}
export default new RetiroManager();
