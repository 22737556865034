import { Button, Grid, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

export const CheckboxListHeader = props => {
  const {
    salvarAlteracoes,
    classes,
    filterText,
    setFilterText
  } = props;

  return (
    <Grid container direction="column" className={classes.headerContent}>
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        className={classes.marginBottom}
      >
        <Grid item className={classes.textField}>
          <TextField
            id="pesquisarItemCheckBox"
            label="Buscar"
            margin="normal"
            onChange={e => setFilterText(e.target.value)}
            value={filterText}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={salvarAlteracoes}
            id="btnSalvarCheckboxes"
          >
            Salvar alterações
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  marginBottom: {
    margin: '0 0 5% 0'
  },
  headerContent: {
    padding: '0 3%'
  },
  textField: {
    width: '50%',
    minWidth: 360
  }
};

export default withStyles(styles)(CheckboxListHeader);
