export const SET_TOGGLES = "toggle/SET_TOGGLES";
export const SET_NOME_TOGGLE_ATUALIZANDO = "toggle/SET_NOME_TOGGLE_ATUALIZANDO";
export const SET_TOGGLE_SELECIONADA = "toggle/SET_TOGGLE_SELECIONADA";
export const FECHA_MODAL_TOGGLE = "toggle/FECHA_MODAL_TOGGLE";
export const SELECIONA_FAZENDA = "toggle/SELECIONA_FAZENDA";
export const SELECIONA_TODAS_FAZENDAS_PARA_TOGGLE =
  "toggle/SELECIONA_TODAS_FAZENDAS_PARA_TOGGLE";

const setToggles = (toggles) => {
  return {
    type: SET_TOGGLES,
    payload: toggles,
  };
};

const setNomeToggeAtualizando = (nome) => {
  return {
    type: SET_NOME_TOGGLE_ATUALIZANDO,
    payload: nome,
  };
};

const setToggleSelecionada = (toggle = {}) => {
  return {
    type: SET_TOGGLE_SELECIONADA,
    payload: toggle,
  };
};

const selecionaFazenda = (IdFazenda) => {
  return {
    type: SELECIONA_FAZENDA,
    payload: IdFazenda,
  };
};

const selecionaTodasAsFazendasParatoggle = (todasAsFazendas) => {
  return {
    type: SELECIONA_TODAS_FAZENDAS_PARA_TOGGLE,
    payload: todasAsFazendas,
  };
};

const fechaModalToggle = () => {
  return {
    type: FECHA_MODAL_TOGGLE,
  };
};

const toggleActions = {
  setToggles,
  setNomeToggeAtualizando,
  setToggleSelecionada,
  selecionaFazenda,
  selecionaTodasAsFazendasParatoggle,
  fechaModalToggle,
  SET_TOGGLES,
}

export default toggleActions;