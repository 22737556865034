import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

export const TruncatedTextWithTooltip = ({
  classes,
  childrenText = [],
  maxValues = 3,
}) => {
  const total = childrenText.length;
  const remaining = total - maxValues;
  const truncatedText = childrenText.slice(0, maxValues).join(", ");
  const tooltipText =
    total > maxValues ? childrenText.slice(3, total).join("\n") : null;
  return (
    <p>
      {truncatedText}
      {tooltipText !== null && (
        <Tooltip
          title={tooltipText}
          placement="bottom-end"
          classes={{ tooltip: classes.tooltip }}
        >
          <b> (+{remaining})</b>
        </Tooltip>
      )}
    </p>
  );
};

const tooltipStyle = () => ({
  tooltip: {
    backgroundColor: "#F6FAFE",
    padding: "16px",
    color: "#444444",
    boxShadow: "0px 2px 10px 0px #00000040",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "left",
    whiteSpace: "break-spaces",
  },
});

export default withStyles(tooltipStyle)(TruncatedTextWithTooltip);
