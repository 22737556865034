export const SET_FAZENDAS = 'fazenda/SET_FAZENDAS';
export const SET_FAZENDAS_CADASTRO = 'fazenda/SET_FAZENDAS_CADASTRO';

const setFazendas = fazendas => {
  return {
    type: SET_FAZENDAS,
    payload: fazendas
  };
};

const setFazendasCadastro = fazendasCadastro => {
  return {
    type: SET_FAZENDAS_CADASTRO,
    payload: fazendasCadastro
  };
};

export { setFazendas, setFazendasCadastro };
