import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import '../../shared/base/axiosBase.ig';
import { ErrorBoundary } from '../../shared/components';
import Loading from '../../shared/components/Loading';
import store from '../../store';
import styles from '../../styles/appStyle';
import Theme from '../../styles/theme';
import { AreaRestrita } from '../areaRestrita';
import ForgotPasswordPage from '../authentication/views/ForgotPasswordPage';
import LoginPage from '../authentication/views/LoginPage';
import { NotificacaoContainer } from '../notificacao';
import SplashScreen from '../splashScreen';
import history from './history';

export class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <React.Fragment>
            <CssBaseline />
            <MuiThemeProvider theme={Theme}>
              <Loading />
              <ErrorBoundary>
                <Switch>
                  <Route exact path="/" component={SplashScreen} />
                  <Route path="/login" component={LoginPage} />
                  <Route path="/recupera_senha" component={ForgotPasswordPage} />
                  <Route path="/(\w+)" component={AreaRestrita} />
                </Switch>
              </ErrorBoundary>

              <NotificacaoContainer />
            </MuiThemeProvider>
          </React.Fragment>
        </Router>
      </Provider>
    );
  }
}

export default withStyles(styles)(App);
