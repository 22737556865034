import { Checkbox, FormControlLabel } from "@material-ui/core/";
import React, { useEffect, useState } from "react";
import EllipsisText from "react-ellipsis-text";

const checkboxItem = (props) => {
  const { item, campoLabel, campoValue, selecionado, todosSelecionados } =
    props;
  const [checked, setChecked] = useState(selecionado);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.onChange(item, event.target.checked);
  };

  useEffect(() => {
    setChecked(selecionado);
  }, [props.selecionado]);

  const LabelName = () => {
    const isInactive =
      item[campoValue] !== undefined &&
      item[campoValue] !== null &&
      (item["Ativo"] === false || item["Ativo"] === 0);

    const isAdmin =
      item[campoValue] !== undefined &&
      item[campoValue] !== null &&
      (item["UsuarioAdministrador"] === true ||
        item["UsuarioAdministrador"] === 1);

    return (
      <span>
        <EllipsisText text={item[campoLabel]} length={40} />
        {isInactive && <span style={{ fontStyle: "italic" }}> (Inativo) </span>}
        {isAdmin && (
          <span style={{ fontStyle: "italic" }}> (Administrador) </span>
        )}
      </span>
    );
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            id={`checkbox-${item[campoValue]}`}
            key={`checkbox-${item[campoValue]}`}
            checked={checked || todosSelecionados}
            onChange={handleChange}
            value={String(item[campoValue])}
            color="secondary"
          />
        }
        label={<LabelName />}
      />
    </div>
  );
};

export default checkboxItem;
