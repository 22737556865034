import {
  ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper
} from '@material-ui/core/';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons/';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import DialogConfirmacaoAcao from '../../../shared/components/dialogConfirmacaoAcao';
import Auth from '../../authentication/Auth';


export class DropdownUsuario extends React.Component {
  state = {
    exibeOpcoesDropdown: false,
    mostraConfirmacaoSair: false
  };

  shouldComponentUpdate(nextState) {
    return this.state.exibeOpcoesDropdown !== nextState.exibeOpcoesDropdown;
  }

  fechaConfirmacaoSair = () => {
    this.setState({
      mostraConfirmacaoSair: false
    });
  };

  abreConfirmacaoSair = () => {
    this.setState({
      mostraConfirmacaoSair: true
    });
  };

  abreOpcoesDropdwonUsuario = () => {
    /* istanbul ignore next */
    this.setState(prevState => {
      /* istanbul ignore next */
      return {
        exibeOpcoesDropdown: !prevState.exibeOpcoesDropdown
      };
    });
  };

  fechaOpcoesDropdwonUsuario = () => {
    this.setState({
      exibeOpcoesDropdown: false
    });
  };

  logout = () => {
    Auth.logout();
    this.fechaOpcoesDropdwonUsuario();
    this.props.history.replace('/');
  };

  render() {
    const { exibeOpcoesDropdown, mostraConfirmacaoSair } = this.state;
    const { classes } = this.props;
    const usuarioLogado = Auth.obtemDadosUsuario();
    return (
      <div className={classes.containerDropDown}>
        <ClickAwayListener onClickAway={this.fechaOpcoesDropdwonUsuario}>
          <React.Fragment>
            <List disablePadding>
              <ListItem
                button
                disableGutters
                onClick={this.abreOpcoesDropdwonUsuario}
              >
                <ListItemAvatar>
                  <Avatar>{usuarioLogado.nome[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={usuarioLogado.nome} />
                <KeyboardArrowDown />
              </ListItem>
            </List>
            <Popper open={exibeOpcoesDropdown} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper className={classes.paper}>
                    <MenuList>
                      <MenuItem onClick={this.abreConfirmacaoSair}>
                        Sair
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <DialogConfirmacaoAcao
              tituloDialog={
                <span id="mensagemConfirmaSaida" className={classes.textoConfirmacaoSair}>
                  Deseja realmente sair ?
                </span>
              }
              texto
              dialogAberto={mostraConfirmacaoSair}
              cancelarAcaoDialog={this.fechaConfirmacaoSair}
              confirmarAcaoDialog={this.logout}
            />
          </React.Fragment>
        </ClickAwayListener>
      </div>
    );
  }
}

DropdownUsuario.displayName = 'DropdownUsuario';

DropdownUsuario.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = theme => ({
  containerDropDown: {
    minWidth: 200,
    maxWidth: 320,
  },
  paper: {
    width: 200
  },
  textoConfirmacaoSair: {
    color: '#505050',
    fontSize: '1.25rem',
    fontWeight: 300,
    lineHeight: 1.6
  }
});

export default withRouter(withStyles(styles)(DropdownUsuario));
