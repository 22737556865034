import { ExpansionPanel, ExpansionPanelSummary, Grid } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
export const expansionItem = props => {
  const { classes, conteudo, acoesItem, itemAberto } = props;

  return (
    <ExpansionPanel style={{
      width: '100%',
      borderRadius: 0,
      boxShadow: '0px 1px 3px 0px',
    }} expanded={itemAberto}>
      <ExpansionPanelSummary
        className={classes.expansionSummary}
        classes={{
          content: classes.expansionSummaryContent
        }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.containerItem}
        >
          <Grid
            id="conteudoExpansionItem"
            style={{
              width: itemAberto ? '100%' : '80%'
            }}
          >
            {conteudo}
          </Grid>
          {!itemAberto && (
            <Grid
              id="acoesExpansionItem"
              container
              item
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.containerAcoesItem}
            >
              {acoesItem}
            </Grid>
          )}
        </Grid>
      </ExpansionPanelSummary>
    </ExpansionPanel>
  );
};

const styles = theme => {
  return {
    expansionSummary: {
      padding: '0 0 0 24px'
    },
    expansionSummaryContent: {
      '& > :last-child': {
        paddingRight: '1%'
      }
    },
    containerItem: {
      width: '100%',
      padding: 0
    },
    containerAcoesItem: {
      [theme.breakpoints.down('sm')]: {
        width: '15%'
      },
      [theme.breakpoints.up('md')]: {
        width: 'auto',
        minWidth: 100
      },
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
        minWidth: 100
      }
    }
  };
};

export default withStyles(styles)(expansionItem);
