export const SET_LOADING = 'ui/SET_LOADING';
export const REMOVE_LOADING = 'ui/REMOVE_LOADING';
export const SET_TAB_SELECIONADA = 'ui/SET_TAB_SELECIONADA';

export const setLoading = () => {
  return {
    type: SET_LOADING
  };
};

export const removeLoading = () => {
  return {
    type: REMOVE_LOADING
  };
};

export const setTabSelecionada = (tabSelecionada = '') => {
  return {
    type: SET_TAB_SELECIONADA,
    payload: tabSelecionada
  };
};
