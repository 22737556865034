/* eslint-disable react/display-name */
import { Table, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import constants from "../../../constants/global";
import SkeletonLoading from "../SkeletonLoading";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CustomTableBody } from "./CustomTableBody";
import { CustomTableHeader } from "./CustomTableHeader";
import { styles } from "./styles";

function CustomTable({
  titulo,
  modoOrdenacao = constants.orderMode.SERVIDOR,
  tamanho,
  colunas = [],
  classes,
  servico,
  ordemInicial,
  aoClicarNaLinha = () => { },
  ativarPaginacao = true,
  linhas: linhasExternas,
  propriedadesLinhas = {},
  propriedadesCelulasCabecalho = {},
  tabelaEsquerda,
  ordenarAsc,
  ComponenteListaVazia,
  atualizar,
  aoClicarNaLinhaCursor,
  idCabecalhoTabela,
  isPrinting,
  noPaper = false,
  hover = true,
  className,
}) {
  const [tipoOrdem, atualizaTipoOrdem] = useState(
    ordenarAsc ? constants.orderBy.asc : constants.orderBy.desc
  );

  const [ordenarPor, atualizaOrdenarPor] = useState(
    ordemInicial || (colunas && colunas.length > 0 ? colunas[0].id : null)
  );
  const [pagina, atualizaPagina] = useState(0);
  const [linhasPorPagina, atualizaLinhasPorPagina] = useState(10);
  const [linhas, atualizaLinhas] = useState(
    linhasExternas ? [...linhasExternas] : []
  );
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [total, atualizaTotal] = useState(0);

  const _atualizaTipoOrdem = (novaOrdem, coluna) => {
    if (novaOrdem !== tipoOrdem) {
      if (modoOrdenacao === constants.orderMode.SERVIDOR) {
        _popular({
          _limite: linhasPorPagina,
          _deslocamento: pagina * linhasPorPagina,
          _ordenarPor: ordenarPor,
          _tipoOrdem: novaOrdem,
        });
      } else {
        _ordenaCliente(ordenarPor, novaOrdem, coluna);
      }
      atualizaTipoOrdem(novaOrdem);
    }
  };

  const _atualizaOrdenarPor = (novoOrdenaPor, coluna) => {
    if (novoOrdenaPor !== ordenarPor) {
      atualizaOrdenarPor(novoOrdenaPor);
      atualizaTipoOrdem(constants.orderBy.desc);
      if (modoOrdenacao === constants.orderMode.SERVIDOR) {
        _popular({
          _limite: linhasPorPagina,
          _deslocamento: pagina * linhasPorPagina,
          _ordenarPor: novoOrdenaPor,
          _tipoOrdem: constants.orderBy.desc,
        });
      } else if (modoOrdenacao === constants.orderMode.LOCAL) {
        _ordenaCliente(novoOrdenaPor, constants.orderBy.desc, coluna);
      }
    }
  };

  const _ordenaClientePadrao = (ordenarPor, tipoOrdem) => {
    const inverte = tipoOrdem === constants.orderBy.desc;
    const _linhas = [...linhas];
    const collator = new Intl.Collator('pt-BR', { sensitivity: 'base' });
    const novasLinhas = _linhas.sort((a, b) => {
      let valueA = a[ordenarPor];
      let valueB = b[ordenarPor];
      if (typeof valueA === "string") {
        valueA = valueA.toLowerCase();
      }
      if (typeof valueB === "string") {
        valueB = valueB.toLowerCase();
      }

      if (inverte) {
        return collator.compare(valueA, valueB);
      }
      return collator.compare(valueB, valueA);
    });
    setShowSkeleton(false);
    atualizaLinhas(novasLinhas);
  };

  const _ordenaClienteData = (ordenarPor, tipoOrdem) => {
    const inverte = tipoOrdem === constants.orderBy.desc;
    const novasLinhas = [...linhas].sort((a, b) => {
      const dataA = moment(a[ordenarPor]);
      const dataB = moment(b[ordenarPor]);
      let resultadoOrdem = 0;

      if (!dataA.isValid()) {
        resultadoOrdem = -1;
      } else if (!dataB.isValid()) {
        resultadoOrdem = 1;
      } else {
        resultadoOrdem = dataA.isAfter(dataB) ? -1 : 1;
      }
      return resultadoOrdem * (inverte ? -1 : 1);
    });
    setShowSkeleton(false);
    atualizaLinhas(novasLinhas);
  };

  const _ordenaCliente = (_ordenarPor, _tipoOrdem, coluna) => {
    switch (coluna.modoOrdenacao) {
      case "date":
        return _ordenaClienteData(_ordenarPor, _tipoOrdem);
      default:
        return _ordenaClientePadrao(_ordenarPor, _tipoOrdem);
    }
  };

  const controleMudancaPagina = (evento, novaPagina) => {
    atualizaPagina(novaPagina);
    _popular({
      _limite: linhasPorPagina,
      _deslocamento: novaPagina * linhasPorPagina,
    });
  };

  const controleMudancaLinhasPorPagina = (evento) => {
    atualizaLinhasPorPagina(evento.target.value);
    atualizaPagina(0);
    setShowSkeleton(true);
    _popular({
      _limite: evento.target.value,
      _deslocamento: pagina * evento.target.value,
    });
  };

  const _popular = async ({
    _limite = linhasPorPagina,
    _deslocamento = pagina * linhasPorPagina,
    _ordenarPor = ordenarPor,
    _tipoOrdem = tipoOrdem,
  }) => {
    if (servico) {
      setShowSkeleton(true);
      const resultado =
        (await servico({
          limit: _limite,
          offset: _deslocamento,
          orderBy: _ordenarPor,
          orderType: _tipoOrdem.toUpperCase(),
        })) || [];

      atualizaLinhas(resultado.itens ? resultado.itens : []);
      atualizaTotal(resultado.total ? resultado.total : 0);
      setShowSkeleton(false);
      return resultado;
    } else {
      setShowSkeleton(false);
      atualizaLinhas(linhasExternas);
      atualizaTotal(linhasExternas.length);
    }
  };

  const popular = async () => {
    _popular({
      _limite: linhasPorPagina,
      _deslocamento: pagina * linhasPorPagina,
      _ordenarPor: ordenarPor,
      _tipoOrdem: tipoOrdem,
    });
  };

  useEffect(() => {
    popular();
  }, [servico, linhasExternas, atualizar]);

  const TabelaSkeleton = React.memo(
    () => (
      <>
        <SkeletonLoading
          width="100%"
          height="56px"
          borderRadius="16px 16px 0 0"
          marginBottom="4px"
        />
        <SkeletonLoading
          width="100%"
          height="530px"
          borderRadius="0 0 16px 16px"
        />
      </>
    ),
    []
  );


  if (showSkeleton) return <TabelaSkeleton />;

  if (ComponenteListaVazia && !total) return <ComponenteListaVazia />;

  const getLinhas = (linhas, linhasPorPagina, pagina) => {
    if (ativarPaginacao && modoOrdenacao === constants.orderMode.LOCAL) {
      return linhas.slice(
        pagina * linhasPorPagina,
        (pagina + 1) * linhasPorPagina
      );
    } else {
      return linhas;
    }
  };

  const tableContent = (
    <>
      <div className={`${className} ${classes.tableContainer}`}>
        <Table
          className={classes.table}
          aria-labelledby={titulo || "tableTitle"}
          size={tamanho || "medium"}
          stickyHeader
          aria-label="enhanced table"
        >
          <CustomTableHeader
            idCabecalhoTabela={idCabecalhoTabela}
            tabelaEsquerda={tabelaEsquerda}
            colunas={colunas}
            classes={classes}
            ordenarPor={ordenarPor}
            atualizaOrdenarPor={_atualizaOrdenarPor}
            tipoOrdem={tipoOrdem}
            atualizaTipoOrdem={_atualizaTipoOrdem}
            propriedadesCelulasCabecalho={propriedadesCelulasCabecalho}
          />
          <CustomTableBody
            hover={hover}
            isPrinting={isPrinting}
            classes={classes}
            linhas={getLinhas(linhas, linhasPorPagina, pagina)}
            colunas={colunas}
            aoClicarNaLinha={aoClicarNaLinha}
            propriedadesLinhas={propriedadesLinhas}
            aoClicarNaLinhaCursor={aoClicarNaLinhaCursor}
          />
        </Table>
      </div>
      {ativarPaginacao && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={linhasPorPagina}
          page={pagina}
          onChangePage={controleMudancaPagina}
          onChangeRowsPerPage={controleMudancaLinhasPorPagina}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={(props) => {
            const { from, to, count } = props
            return `${from}-${to} de ${count}`
          }
          }
        />
      )
      }
    </>
  );
  if (noPaper) return tableContent;
  return <Paper className={classes.containerTable}>{tableContent}</Paper>;
}

export default withStyles(styles)(CustomTable);
