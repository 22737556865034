import axios from 'axios';

const URI_FABRICANTE = '/consumo/api/v1/fabricantes/';

class FabricanteService {
  async obtemFabricantes() {
    return axios.get(`${URI_FABRICANTE}`, { headers: {} });
  }

  async cadastraFabricante(fabricanteCadastrar) {
    return axios.post(`${URI_FABRICANTE}`, fabricanteCadastrar);
  }

  async salvaFabricante(IdFabricante, fabricanteSalvar) {
    return axios.patch(`${URI_FABRICANTE}${IdFabricante}`, fabricanteSalvar);
  }

  async excluiFabricante(IdFabricante) {
    return axios.delete(`${URI_FABRICANTE}${IdFabricante}`);
  }
}
export default new FabricanteService();
