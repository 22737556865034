import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_API,
  headers: {
    "Content-Type": "application/json"
  }
});

const AUTH_SERVICE_URL = `${process.env.REACT_APP_ENDPOINT_API}usuarios/api/v1/auth`

export function login(username, password) {
  if (!username || !password) {
    return;
  }

  return instance.post(`${AUTH_SERVICE_URL}/login`, {
    username,
    password
  })
}

export function resetPassword(email) {
  if (!email) {
    return;
  }

  return instance.post(`${AUTH_SERVICE_URL}/reset-password`, {
    email
  })
}
