export default theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%'
  },
  flex: {
    flex: 1
  },
  logo: {
    width: 128
  },
  typography: {
    marginLeft: 8,
    color: theme.palette.text.secondary
  },
  avatar: {
    width: 36,
    height: 36,
    color: theme.palette.avatar
  },
  gridContainer: {
    margin: 0
  },
  grid: {
    margin: 0,
    padding: '0 !important'
  },
  
});
