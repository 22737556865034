import { Typography, withStyles } from "@material-ui/core";
import React from "react";

function ToggleAlertMessage({ classes }) {
  return (
    <Typography class={classes.warningToggle}>
      <img
        src="../images/ic_warning_outlined.svg"
        alt="icone de alerta"
        className={classes.warningToggleIcon}
      />
      Ao criar uma fazenda, <b>automaticamente</b> todos os acessos/toggles
      serão liberados para ela, exceto{" "}
      <b>Semi confinamento/Massa de Forragem/Foto galeria morte</b>. Caso queira
      remover algum acesso entre no menu <b>Acessos</b> e remova para a fazenda.
    </Typography>
  );
}

const styles = () => {
  return {
    warningToggleIcon: {
      marginRight: "4px",
      marginBottom: "-3px",
      height: "20px",
      color: "red",
    },
    warningToggle: {
      margin: "8px",
      fontFamily: "Roboto",
      lineHeight: 1.71,
      color: "#666",
    },
  };
};
export default withStyles(styles)(ToggleAlertMessage);
