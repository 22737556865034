import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { Tabs, Tab, Paper, Divider } from '@material-ui/core/';
import { Page, FeedbackTab } from '../../../shared/components';
import { obtemTabPeloHash, TABS_PATH_HASH } from '../vinculosUtils';
import { UsuarioRetiroVinculoTabContainer } from '../../usuarioRetiroVinculo/';
import { UsuarioFazendaVinculoTabContainer } from '../../usuarioFazendaVinculo';
import { UsuarioAuth0VinculoTabContainer } from '../../usuarioAuth0';
import { UsuarioClienteVinculoTabContainer } from '../../usuarioClienteVinculo';
import { setTabSelecionada } from '../../../shared/redux/UIActions';

export class VinculoPage extends PureComponent {
  constructor(props) {
    super(props);
    this.mudaTab(null, 0);
    this.usuarioSelecionado = null;
  }

  mudaTab = (event, value) => {
    const tab = this.obtemTabPeloIndex(value);
    this.props.history.push(tab.path);
    setTimeout(() => {
      this.props.setTabSelecionada(tab.path);
    }, 500);
  };

  mudaTabIndex = index => {
    this.setState({ value: index });
  };

  obtemTabPeloIndex = index => {
    return TABS_PATH_HASH[index];
  };

  mapTabs = pathName => {
    return [...TABS_PATH_HASH].map((tab, index) => {
      return <Tab label={tab.tituloTab} key={`tab-${index}`} disabled={tab.desabilitada} />;
    });
  };

  render() {
    if (!this.usuarioSelecionado) {
      this.usuarioSelecionado = this.props.location.usuario;
    }
    const { classes, theme, location } = this.props;
    const tituloComNome =
      this.usuarioSelecionado && this.usuarioSelecionado.Nome ? this.usuarioSelecionado.Nome : '';
    const pathName = location.pathname;
    const tabSelecionada = obtemTabPeloHash(location.hash);
    return (
      <Page
        titulo={`Vincular usuário ${tituloComNome}`}
        exibirConteudo
        conteudo={
          <Paper className={classes.root}>
            <Tabs
              value={tabSelecionada.index}
              onChange={this.mudaTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {this.mapTabs(pathName)}
            </Tabs>
            <Divider />
            {this.usuarioSelecionado ? (
              <SwipeableViews
                id="SwipeableViews"
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tabSelecionada.index}
                onChangeIndex={this.mudaTabIndex}
              >
                <UsuarioClienteVinculoTabContainer usuario={this.usuarioSelecionado} />
                <UsuarioFazendaVinculoTabContainer usuario={this.usuarioSelecionado} />
                <UsuarioRetiroVinculoTabContainer usuario={this.usuarioSelecionado} />
                <UsuarioAuth0VinculoTabContainer usuario={this.usuarioSelecionado} />
              </SwipeableViews>
            ) : (
              <FeedbackTab mensagem="Selecione um usuário" />
            )}
          </Paper>
        }
      />
    );
  }
}

const mapDispatchToProps = {
  setTabSelecionada: setTabSelecionada
};
VinculoPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    width: '100%'
  }
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(
    null,
    mapDispatchToProps
  )
)(VinculoPage);
