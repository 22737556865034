import { isNullOrUndefined } from 'util';
import RelatorioTableauService from './relatorioService';

class RelatorioTableauManager {
    retornaRelatorios = async (IdRelatorio = null) => {
        if (!isNullOrUndefined(IdRelatorio)) {
            return await RelatorioTableauService.retornaRelatorios('?IdRelatorio=' + IdRelatorio);
        }
        else {
            return await RelatorioTableauService.retornaRelatorios();
        }
    }
    deletaRelatorio = async (IdRelatorio) => {
        try {
            return await RelatorioTableauService.deletaRelatorios(IdRelatorio);
        } catch (error) {
            throw new Error("Erro ao tentar deletar");
        }
    }
    atualizaRelatorio = async (IdRelatorio, Relatorio) => {
        try {
            return await RelatorioTableauService.atualizaRelatorios(IdRelatorio, Relatorio);
        } catch (error) {
            throw new Error("Erro ao tentar atualizar");
        }
    }
    criaRelatorio = async (Relatorio) => {
        try {
            return await RelatorioTableauService.criaRelatorio(Relatorio);
        } catch (error) {
            throw new Error("Erro ao tentar criar relatório");
        }
    }
}

export default new RelatorioTableauManager();