import {
  Button,
  Divider,
  ExpansionPanelActions,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  NativeSelect,
  Switch,
  TextField,
} from "@material-ui/core/";
import React from "react";
import { ContadorCaracteres } from "../../../shared/components";
import PaisEstadoPicker from "./paisEstadoPicker/paisEstadoPickerContainer";
import ToggleAlertMessage from "./toggleAlertMessage";

export const fazendaForm = (props) => {
  const {
    nome,
    nomeReduzido,
    codigoFazendaProtheus,
    estado,
    cidade,
    ativo,
    numeroLeituraAlturaPasto,
    idCliente,
    permitirAcessoFinanceiro,
    clientes,
    setCamposFormFazenda,
    salvaFazenda,
    abreFechaFormNovaFazenda,
    verificaClienteUsaFinanceiro,
    acessoFinanceiro,
    CNPJ,
    pais,
  } = props;

  return (
    <React.Fragment>
      <ToggleAlertMessage />
      <FormControlLabel
        id="formAtivoFazenda"
        control={
          <Switch
            id="switchAtivoFazenda"
            checked={ativo}
            onChange={(e) => setCamposFormFazenda(e.target.checked, "ativo")}
          />
        }
        label="Fazenda Ativa"
      />
      <TextField
        value={nome}
        autoFocus
        id={"txtNomeFazenda"}
        label={"Nome *"}
        onChange={(e) =>
          setCamposFormFazenda(e.target.value.trimLeft().toUpperCase(), "nome")
        }
        margin="normal"
        inputProps={{
          maxLength: 50,
        }}
      />
      <ContadorCaracteres maximoPermitido={50} totalCaracteres={nome.length} />
      <TextField
        value={nomeReduzido}
        id={"txtnomeReduzidoFazenda"}
        label={"Nome Reduzido *"}
        onChange={(e) =>
          setCamposFormFazenda(
            e.target.value.trimLeft().toUpperCase(),
            "nomeReduzido"
          )
        }
        margin="normal"
        inputProps={{
          maxLength: 20,
        }}
      />
      <ContadorCaracteres
        maximoPermitido={20}
        totalCaracteres={nomeReduzido.length}
      />
      <TextField
        value={codigoFazendaProtheus}
        id={"txtcodigoFazendaProtheus"}
        label={"Código Fazenda"}
        onChange={(e) =>
          setCamposFormFazenda(
            e.target.value.trimLeft().toUpperCase(),
            "codigoFazendaProtheus"
          )
        }
        margin="normal"
        inputProps={{
          maxLength: 30,
        }}
      />
      <ContadorCaracteres
        maximoPermitido={30}
        totalCaracteres={
          codigoFazendaProtheus ? codigoFazendaProtheus.length : 0
        }
      />
      <PaisEstadoPicker
        pais={pais}
        estado={estado}
        setEstado={(estadoSelecionado) =>
          setCamposFormFazenda(estadoSelecionado, "estado")
        }
        setPais={(paisSelecionado) =>
          setCamposFormFazenda(paisSelecionado, "pais")
        }
      />
      <TextField
        value={cidade}
        id={"txtCidadeFazenda"}
        label={"Cidade *"}
        onChange={(e) =>
          setCamposFormFazenda(e.target.value.trimLeft(), "cidade")
        }
        margin="normal"
        inputProps={{
          maxLength: 50,
        }}
      />
      <ContadorCaracteres
        maximoPermitido={50}
        totalCaracteres={cidade.length}
      />
      <TextField
        type="number"
        style={{ padding: "0 0 30px 0" }}
        value={numeroLeituraAlturaPasto}
        id={"txtnumeroLeituraAlturaPastoFazenda"}
        label={"Número Leitura Altura Pasto *"}
        inputProps={{
          min: 0,
          max: 999,
        }}
        onChange={(e) => {
          if (Number(e.target.value) < 0) e.target.value = 0;
          else e.target.value = e.target.value.substr(0, 3);

          return setCamposFormFazenda(
            e.target.value,
            "numeroLeituraAlturaPasto"
          );
        }}
        margin="normal"
      />
      <FormControl>
        <InputLabel shrink htmlFor="age-native-label-placeholder">
          Nome do Cliente *
        </InputLabel>
        <NativeSelect
          onChange={(e) =>
            verificaClienteUsaFinanceiro(e.target.value, "idCliente")
          }
          input={<Input name="idCliente" id="idClienteFazenda" />}
        >
          <option value={""}>SELECIONE UMA OPÇÃO</option>
          {clientes.map((cliente) => (
            <option
              selected={Number(idCliente) === cliente.IdCliente}
              key={cliente.IdCliente}
              value={cliente.IdCliente}
            >
              {cliente.Nome}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormControlLabel
        id="formPermiteUsarFinanceiro"
        control={
          <Switch
            id="switchAtivoFazenda"
            disabled={!permitirAcessoFinanceiro}
            checked={acessoFinanceiro}
            onChange={(e) =>
              setCamposFormFazenda(e.target.checked, "acessoFinanceiro")
            }
          />
        }
        label="Cadastro no módulo financeiro"
      />
      <TextField
        value={CNPJ}
        id={"txtCNPJ"}
        label={"CNPJ"}
        onChange={(e) => setCamposFormFazenda(e.target.value, "CNPJ")}
        style={acessoFinanceiro ? {} : { display: "none" }}
        type="number"
        margin="normal"
        inputProps={{
          maxLength: 50,
        }}
      />
      <Divider />
      <ExpansionPanelActions>
        <Button
          id="btnCancelarFormFazenda"
          size="small"
          onClick={abreFechaFormNovaFazenda}
        >
          Cancelar
        </Button>
        <Button
          id="btnSalvarFormFazenda"
          size="small"
          color="primary"
          onClick={salvaFazenda}
          disabled={
            !nome.length ||
            !nomeReduzido.length ||
            !cidade.length ||
            !estado.length ||
            (!numeroLeituraAlturaPasto &&
              numeroLeituraAlturaPasto.value !== 0) ||
            !idCliente ||
            (acessoFinanceiro && !CNPJ.length)
          }
        >
          Salvar
        </Button>
      </ExpansionPanelActions>
    </React.Fragment>
  );
};

fazendaForm.defaultProps = {
  nome: "",
  nomeReduzido: "",
  codigoFazendaProtheus: "",
  cidade: "",
  idCliente: "",
  pais: "",
  estado: "",
  ativo: false,
  numeroLeituraAlturaPasto: "",
  nomeCliente: "",
  clientes: [],
  idClienteFinanceiro: 0,
  permitirAcessoFinanceiro: false,
  acessoFinanceiro: false,
  CNPJ: "",
};
export default fazendaForm;
