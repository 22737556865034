import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
/* istanbul ignore next */
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { normalizarString } from "../../../shared/util";
import toggleActions from "../../toggle/redux/toggleFeatureActions";
import { verificaFazendaHabilitada } from "../fazendaUtils";
/* istanbul ignore next */
import FazendaCheckbox from "./fazendaCheckbox";

export class FazendasCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionarTodas: false,
      fazendasFiltradas: this.props.fazendas,
      fazendasHabilitadasTggleSelecionada:
        this.props.fazendasHabilitadasTggleSelecionada,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      selecionarTodas:
        props.fazendasHabilitadasTggleSelecionada.length ===
        props.fazendas.length,
    };
  }
  desmarcarTodos = () => {
    this.setState({
      selecionarTodas: false,
    });
  };

  handleChange = () => {
    this.setState((prevState) => {
      if (!prevState.selecionarTodas) {
        this.props.selecionaTodasAsFazendasParatoggle(this.props.fazendas);
      } else {
        this.props.selecionaTodasAsFazendasParatoggle([]);
      }
      return {
        selecionarTodas: !prevState.selecionarTodas,
      };
    });
  };

  divideArrayFazendas = () => {
    const fazendas = [...this.state.fazendasFiltradas];
    const indexMeio = Math.floor(fazendas.length / 2);
    return {
      fazendasColunaEsquerda: fazendas.slice(indexMeio),
      fazendasColunaDireita: fazendas.slice(0, indexMeio),
    };
  };

  filtraFazendas = ({ target: { value = "" } }) => {
    const fazendasComFiltroTexto = this.props.fazendas.filter((fazenda) =>
      normalizarString(fazenda.Nome).includes(normalizarString(value))
    );
    this.setState({
      fazendasFiltradas: fazendasComFiltroTexto,
    });
  };

  mapeiaFazendasEmCheckBox = (fazendas) => {
    return (
      <Grid item>
        {fazendas.map((fazenda, index) => {
          return (
            <FazendaCheckbox
              desmarcarTodos={this.desmarcarTodos}
              fazendaHabilitada={verificaFazendaHabilitada(
                fazenda.IdFazenda,
                this.props.fazendasHabilitadasTggleSelecionada
              )}
              selecionarTodas={this.state.selecionarTodas}
              fazenda={fazenda}
              key={`${fazenda.Nome}-${index}`}
            />
          );
        })}
      </Grid>
    );
  };

  renderFazendas = () => {
    const { classes } = this.props;
    const { fazendasColunaEsquerda, fazendasColunaDireita } =
      this.divideArrayFazendas();
    if (!this.props.fazendas.length) {
      return (
        <Typography
          variant="subtitle2"
          id="mensagemErro"
          className={classes.mensagemListaFazendasVazia}
        >
          Não foi possível listar fazendas
        </Typography>
      );
    }
    return (
      <React.Fragment>
        {this.mapeiaFazendasEmCheckBox(fazendasColunaEsquerda)}
        {this.mapeiaFazendasEmCheckBox(fazendasColunaDireita)}
      </React.Fragment>
    );
  };
  render() {
    const { selecionarTodas } = this.state;

    return (
      <React.Fragment>
        <Grid container direction="column" justify="space-around">
          <Grid item>
            <Typography id="tituloFazendas" variant="subtitle1">
              Fazendas
            </Typography>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                id="selecionarTodas"
                control={
                  <Checkbox
                    checked={selecionarTodas}
                    onChange={this.handleChange}
                  />
                }
                label="Selecionar todas"
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <TextField
              id="pesquisarFazenda"
              label="Pesquisar uma fazenda"
              margin="normal"
              style={{ width: 200, paddingBottom: 5 }}
              onChange={this.filtraFazendas}
            />
          </Grid>
        </Grid>
        <Grid
          id="gridFazendas"
          container
          direction="row"
          justify="space-between"
          style={{ height: 140, overflowY: "scroll", padding: "0 2%" }}
        >
          {this.renderFazendas()}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  selecionaTodasAsFazendasParatoggle:
    toggleActions.selecionaTodasAsFazendasParatoggle,
};

const styles = (theme) => {
  return {
    mensagemListaFazendasVazia: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(FazendasCheckbox);
