import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import styles from "./styles";

export function Loading({ classes, loading }) {
  const shouldShowLoading = Array.isArray(loading.exibirCarregando)
    ? loading.exibirCarregando.length > 0
    : loading.exibirCarregando;

  return (
    <div
      className={classes.backdrop}
      style={{ display: shouldShowLoading ? "inherit" : "none" }}
    >
      <Paper
        className={classes.corpoCarregandoPequeno}
        elevation={1}
        style={{ borderRadius: 16 }}
      >
        <img
          id="logoFarmTellAppBar"
          src="/images/logo-farmtell-views-color-2.png"
          alt="logo farmtell views"
          className={classes.logo}
        />
        <React.Fragment>
          <div>
            <Typography
              variant="h6"
              component="h6"
              className={classes.corpoFormulario}
            >
              Carregando...
            </Typography>
          </div>
        </React.Fragment>
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Loading);
