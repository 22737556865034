import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Delete, Edit, Link, MoreVert, Send } from "@material-ui/icons";
import React, { useState } from "react";

export const UserActionsMenu = ({
  user,
  onCopyLink,
  onSendLink,
  onEdit,
  onRemove,
  onLinkUser,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton data-testid="btn-menu" onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{ sx: { maxWidth: 250 } }}
      >
        <MenuItem
          sx={{ minHeight: 48 }}
          onClick={() => onEdit(user)}
          data-testid="editar-btn"
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Editar" />
        </MenuItem>
        <MenuItem sx={{ minHeight: 48 }} onClick={() => onRemove(user)}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Excluir" />
        </MenuItem>
        <MenuItem sx={{ minHeight: 48 }} onClick={() => onCopyLink(user)}>
          <ListItemIcon>
            <img
              alt="password icon"
              src="/images/password_icon.svg"
              style={{ marginRight: 4 }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ textWrap: "wrap" }}
            primary="Gerar e copiar link de criação de senha"
          />
        </MenuItem>
        <MenuItem sx={{ minHeight: 48 }} onClick={() => onSendLink(user)}>
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          <ListItemText
            sx={{ textWrap: "wrap" }}
            primary="Enviar link de criação de senha, por e-mail"
          />
        </MenuItem>
        <MenuItem
          sx={{ minHeight: 48 }}
          onClick={() => onLinkUser(user)}
          data-testid="vincular-btn"
        >
          <ListItemIcon>
            <Link />
          </ListItemIcon>
          <ListItemText primary="Vincular usuário" />
        </MenuItem>
      </Menu>
    </>
  );
};
