import * as UIActions from '../../../shared/redux/UIActions';
import { notificacaoActions } from '../../notificacao/redux';
import usuarioService from '../../usuario/usuarioService';
import AuthManager from '../auth0Manager';
import * as usuarioAuth0VinculoActions from './usuarioAuth0VinculoActions';

export const MSG = {
  OBTEM_USUARIO_AUTH_FALHA: 'Não foi possível obter dados usuario auth0.',
  ATUALIZA_USUARIO_AUTH_SUCESSO: 'Permissões do usuário atualizadas.',
  ATUALIZA_USUARIO_AUTH_FALHA: 'Não foi possível atualizar permissões usuário.',
};

const obtemUsuarioAuth0 = (emailUsuario, idUsuario) => async (dispatch) => {
  try {
    const usuarioAuth0 = await AuthManager.obtemUsuarioAuthPeloEmail(emailUsuario, idUsuario);
    dispatch(usuarioAuth0VinculoActions.setUsuarioAuth0(usuarioAuth0));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(MSG.OBTEM_USUARIO_AUTH_FALHA));
  }
};

const atualizaPermissoesUsuarioAuth0 = (permissoes) => async (dispatch, getState) => {
  try {
    dispatch(UIActions.setLoading());
    let arrayPermissoes = Object
      .keys(permissoes)
      .filter((key) => permissoes[key])
      .filter((key) => key !== "usuario_Master_Tableau" && key !== "usuario_Master" && key !== "usuarioTableau");

    if (permissoes.usuarioTableau) {
      arrayPermissoes = ["usuario_analytics", "usuario_tableau", "acessa_plataforma"]
    } else if (permissoes.usuario_Master_Tableau) {
      arrayPermissoes.push("usuario_tableau");
    }

    const usuario = getState().usuarioAuth.usuarioAuth0;
    await usuarioService.atualizaPermissoesUsuario(usuario.login, arrayPermissoes);
    dispatch(notificacaoActions.notificaSucesso(MSG.ATUALIZA_USUARIO_AUTH_SUCESSO));
  } catch (error) {
    console.log(error);
    dispatch(notificacaoActions.notificaErro(MSG.ATUALIZA_USUARIO_AUTH_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

export { atualizaPermissoesUsuarioAuth0, obtemUsuarioAuth0 };

