import { Grid } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { divideArray, filtraItens } from '../../shared/util';

export const CheckboxList = props => {
  const { renderItem, campoFiltrar, itens, classes, filterText } = props;
  const itensFiltrados = filtraItens(itens, filterText, campoFiltrar);
  const { itensEsquerda, itensDireita } = divideArray(itensFiltrados);

  return (
    <Grid container direction="column" className={classes.listContent}>
      <Grid container item direction="row" className={classes.list}>
        <Grid item className={classes.right} id="itensEsquerda">
          {itensDireita.map(item => {
            return renderItem(item);
          })}
        </Grid>
        <Grid item className={classes.left} id="itensDireita">
          {itensEsquerda.map(item => {
            return renderItem(item);
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  listContent: {
    padding: '0 3%',
    minHeight: '100vh'
  },
  list: {
    height: '100%',
    width: '100%'
  },
  left: {
    width: '50%'
  },
  right: {
    width: '50%'
  }
};

export default withStyles(styles)(CheckboxList);
