import FabricanteService from './fabricanteService';
import _ from 'lodash';

export const MSG = {
  ERRO_OBTER_FABRICANTES: 'Ocorreu um erro ao tentar retornar fabricantes.',
  ERRO_SALVAR_FABRICANTE: 'Ocorreu erro ao tentar salvar dados do fabricante',
  ERRO_EXCLUIR_FABRICANTE: 'Ocorreu erro ao tentar exlcuir fabricante'
};

class FabricanteManager {
  async obtemFabricantes() {
    try {
      const respostaServicoFabricante = await FabricanteService.obtemFabricantes();
      const fabricantes = respostaServicoFabricante.data;
      const fabricantesUpperCase = fabricantes.map(fabricante => {
        fabricante.Nome = fabricante.Nome.toUpperCase();
        return fabricante;
      });
      const fabricantesOrdenados = _.orderBy(fabricantesUpperCase, ['Nome'], ['desc']);
      return fabricantesOrdenados;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_FABRICANTES);
    }
  }

  async salvaFabricante(IdFabricante, fabricanteSalvar) {
    try {
      let respostaServicoFabricante;
      if (IdFabricante) {
        respostaServicoFabricante = await FabricanteService.salvaFabricante(
          IdFabricante,
          fabricanteSalvar
        );
      } else {
        respostaServicoFabricante = await FabricanteService.cadastraFabricante(fabricanteSalvar);
      }
      const fabricanteSalvo = respostaServicoFabricante.status === 200;
      return fabricanteSalvo;
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_FABRICANTE);
    }
  }

  async excluiFabricante(IdFabricante) {
    try {
      const respostaServicoFabricante = await FabricanteService.excluiFabricante(IdFabricante);
      const fabricanteExcluido = respostaServicoFabricante.status === 204;
      return fabricanteExcluido;
    } catch (error) {
      throw new Error(MSG.ERRO_EXCLUIR_FABRICANTE);
    }
  }
}
export default new FabricanteManager();
