import { metadadosAuth } from "./views/metadados";

export const USUARIO_NAO_EXISTE_AUTH0 = "Usuário não possui cadastro no Auth0";

export const divideArrayPermissoes = () => {
  const permissoes = metadadosAuth;
  const indexMeio = Math.floor(permissoes.length / 2);
  return {
    colunaPermissoesEsquerda: permissoes.slice(indexMeio),
    colunaPermissoesDireita: permissoes.slice(0, indexMeio),
  };
};

export const colunaDeAcessos = [
  { label: "FarmTell Views", value: "usuario_Master" },
  { label: "Tableau", value: "usuarioTableau" },
  { label: "FarmTell Views e Tableau", value: "usuario_Master_Tableau" },
];

export const obtemRole = (roles, nomeRole) => {
  return roles ? roles.find((r) => r.name === nomeRole) : null;
};
