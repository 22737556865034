import * as fazendaActions from './fazendaActions';

export const INITIAL_STATE = {
  fazendas: [],
  fazendasCadastro: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case fazendaActions.SET_FAZENDAS:
      return { ...state, fazendas: action.payload };
    case fazendaActions.SET_FAZENDAS_CADASTRO:
      return { ...state, fazendasCadastro: action.payload };
    default:
      return state;
  }
};
