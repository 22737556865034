
import memoizeOne from 'memoize-one';

export const removeItemEmListaPeloIndex = (lista, index) => {
  lista.splice(index, 1);
  return lista;
};

export const divideArray = arrayDividir => {
  if (arrayDividir.length > 1) {
    const itens = [...arrayDividir];
    const indexMeio = Math.floor(itens.length / 2);
    return {
      itensEsquerda: itens.slice(indexMeio),
      itensDireita: itens.slice(0, indexMeio)
    };
  }
  return { itensEsquerda: [], itensDireita: arrayDividir };
};

export const mapeiaIdsParaVinculo = memoizeOne((listaMapear = [], campoIdMapear = '') => {
  return listaMapear.map(itemLista => itemLista[campoIdMapear]);
});

export const mapeiaVinculosUsuario = (listaIds, campoIdMapear, listaMapear = []) => {
  return listaMapear.map(itemLista => {
    const usuarioVinculado = listaIds.includes(itemLista[campoIdMapear]);
    return {
      ...itemLista,
      Nome: itemLista.Nome.toUpperCase(),
      usuarioVinculado
    };
  });
};

export const normalizarString = (str) => (
  String(str || '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
);

export const filtraItens = memoizeOne((list, filterText, campoFiltrar) =>
  list.filter(item =>
    normalizarString(item[campoFiltrar])
      .includes(normalizarString(filterText))
  )
);

let timeoutRef = null;
export function debounce(fn, delay = 1000) {
  clearTimeout(timeoutRef);
  timeoutRef = setTimeout(fn, delay);
}

export const requisicaoComSucesso = (statusCode) => (
  statusCode >= 200 && statusCode < 300
);
