import { Grid } from '@material-ui/core';
import React from 'react';
import { expansionList } from '../../../shared/components';
import FazendaItem from './fazendaItem';

export const fazendaList = props => {
  const { fazenda, clientes } = props;
  return (
    <Grid container direction="column">
      <FazendaItem
        id={`fazendaItem-${fazenda.IdFazenda}`}
        key={fazenda.IdFazenda}
        fazenda={fazenda}
        clientes={clientes}
        expandeFazendaItem={props.expandeItem}
        idFazendaSelecionada={props.itemSelecionado}
      />
    </Grid>
  );
};

export default expansionList(fazendaList);