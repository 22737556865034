import * as UIActions from '../../../shared/redux/UIActions';
import { notificacaoActions } from '../../notificacao/redux';
import UsuarioManager from '../usuarioManager';
import * as usuarioActions from './usuarioActions';

export const MSG = {
  OBTEM_USUARIO_FALHA: 'Não foi possível listar usuários',
  ATUALIZA_USUARIO_SUCESSO: 'Usuário atualizado com sucesso',
  ATUALIZA_USUARIO_FALHA: 'Não foi possível atualizar usuário',
  CRIA_USUARIO_SUCESSO: 'Usuário cadastrado com sucesso',
  CRIA_USUARIO_FALHA: 'Não foi possível cadastrar usuário',
  EXCLUI_USUARIO_SUCESSO: 'Usuário excluído com sucesso',
  EXCLUI_USUARIO_FALHA: 'Não foi possível excluir usuário',
  ATUALIZA_SENHA_SUCESSO: 'Senha alterada com sucesso',
  ATUALIZA_SENHA_FALHA: 'Não foi possível alterar a senha'
};

const obtemUsuarios = () => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const usuarios = await UsuarioManager.obtemUsuarios();
    dispatch(usuarioActions.setUsuarios(usuarios));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(MSG.OBTEM_USUARIO_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const salvaUsuario = (IdUsuario, usuarioSalvar, verificaExistenciaUsuario) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const { usuarioSelectionado } = usuarioSalvar;
    if (usuarioSelectionado) {
      delete usuarioSalvar.usuarioSelectionado;
    }

    if (IdUsuario) {
      await verificaExistenciaUsuario({ email: usuarioSalvar.Email });
    } else {
      await verificaExistenciaUsuario({ login: usuarioSalvar.Login, email: usuarioSalvar.Email })
    }
    const usuarioSalvo = await UsuarioManager.salvaUsuario(IdUsuario, usuarioSalvar);
    if (usuarioSalvo) {
      dispatch(
        notificacaoActions.notificaSucesso(
          IdUsuario ? MSG.ATUALIZA_USUARIO_SUCESSO : MSG.CRIA_USUARIO_SUCESSO
        )
      );
    } else {
      dispatch(
        notificacaoActions.notificaErro(
          IdUsuario ? MSG.ATUALIZA_USUARIO_FALHA : MSG.CRIA_USUARIO_FALHA
        )
      );
    }
  } catch (error) {
    if (error.message === 'Usuário já cadastrado.') {
      throw new Error(error.message);
    }

    dispatch(
      notificacaoActions.notificaErro(
        IdUsuario ? MSG.ATUALIZA_USUARIO_FALHA : MSG.CRIA_USUARIO_FALHA
      )
    );
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const excluiUsuario = (IdUsuario, Email) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const usuarioExcluido = await UsuarioManager.excluiUsuario(IdUsuario, Email);
    if (usuarioExcluido) {
      dispatch(notificacaoActions.notificaSucesso(MSG.EXCLUI_USUARIO_SUCESSO));
    } else {
      dispatch(notificacaoActions.notificaErro(MSG.EXCLUI_USUARIO_FALHA));
    }
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(MSG.EXCLUI_USUARIO_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const atualizaSenhaUsuario = (Senha, Login) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const senhaAlterada = await UsuarioManager.atualizarSenhaUsuario(Senha, Login);
    if (senhaAlterada) {
      const usuarios = await UsuarioManager.obtemUsuarios();
      dispatch(usuarioActions.setUsuarios(usuarios));
      dispatch(notificacaoActions.notificaSucesso(MSG.ATUALIZA_SENHA_SUCESSO));
    } else {
      dispatch(notificacaoActions.notificaErro(MSG.ATUALIZA_USUARIO_FALHA));
    }
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(MSG.ATUALIZA_USUARIO_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

export { atualizaSenhaUsuario, excluiUsuario, obtemUsuarios, salvaUsuario };

