import axios from "axios";
const URI_FAZENDA = "/fazendas/api/v1/fazendas";
const URI_PAISES = "/fazendas/api/v1/countries";

class FazendaService {
  async obtemFazendas(filtro = "") {
    return axios.get(`${URI_FAZENDA}${filtro}`, {
      headers: {},
    });
  }

  async obtemPaises() {
    return axios.get(URI_PAISES);
  }

  async obtemEstados(pais) {
    return axios.get(`${URI_PAISES}/${pais}/states`);
  }

  async obtemFazendasPorIdsCliente(idsCliente) {
    let filtro = "";
    if (idsCliente) {
      filtro = {
        where: {
          IdCliente: idsCliente,
        },
      };
    }
    return axios.get(`${URI_FAZENDA}${filtro ? `?filter=${JSON.stringify(filtro)}` : ""}`);
  }

  async obtemFazendasIdCliente(filtro) {
    return axios.get(`${URI_FAZENDA}${filtro}`, {
      headers: {},
    });
  }

  async cadastraFazenda(fazendaCadastrar) {
    return axios.post(`${URI_FAZENDA}`, fazendaCadastrar);
  }

  async salvaFazenda(IdFazenda, fazendaSalvar) {
    return axios.patch(`${URI_FAZENDA}/${IdFazenda}`, fazendaSalvar);
  }
  async excluiFazenda(IdFazenda) {
    return axios.delete(`${URI_FAZENDA}/${IdFazenda}`);
  }
}
export default new FazendaService();
