/* eslint-disable react/display-name */
import { withStyles } from "@material-ui/core";
import React from "react";
import CustomTable from "../../../shared/components/CustomTable";
import TruncatedTextWithTooltip from "../../../shared/components/truncatedTextWithTooltip";
import { UserActionsMenu } from "./components/UserActionsMenu";

const UsuarioTable = ({
  classes,
  listarUsuarios,
  abreDialogoExclusao,
  editaUsuario,
  gerarLinkSenha,
  enviarLinkSenha,
  vincularUsuario,
}) => {
  const columns = [
    {
      id: "IdUsuario",
      titulo: <span className={classes.columnHeader}>Cód. Usuário</span>,
      ordenavel: false,
      pegaValor: (linha) => (
        <div>
          <span>{linha.idUsuario}</span>
        </div>
      ),
      propriedadesCustomizadas: {
        "data-testid": "UserId",
      },
    },
    {
      id: "Nome",
      titulo: <span>Nome</span>,
      ordenavel: false,
      pegaValor: (linha) => (
        <div>
          <span>{linha.nome}</span>
        </div>
      ),
      propriedadesCustomizadas: {
        "data-testid": "UserName",
      },
    },
    {
      id: "Email",
      titulo: <span>E-mail</span>,
      ordenavel: false,
      pegaValor: (linha) => (
        <div>
          <span>{linha.email}</span>
        </div>
      ),
      propriedadesCustomizadas: {
        "data-testid": "UserEmail",
      },
    },
    {
      id: "Login",
      titulo: <span>Login</span>,
      ordenavel: false,
      pegaValor: (linha) => (
        <div>
          <span>{linha.login}</span>
        </div>
      ),
      propriedadesCustomizadas: {
        "data-testid": "UserLogin",
      },
    },
    {
      id: "TipoAcesso",
      titulo: <span>Tipo de acesso</span>,
      ordenavel: false,
      pegaValor: (linha) => {
        return (
          <div>
            <TruncatedTextWithTooltip
              childrenText={linha.permissoes.map((p) => p.nome)}
            />
          </div>
        );
      },
      propriedadesCustomizadas: {
        "data-testid": "UserTipoAcesso",
      },
    },
    {
      id: "options",
      titulo: "Ações",
      ordenavel: false,
      pegaValor: (row) => (
        <UserActionsMenu
          user={row}
          onRemove={abreDialogoExclusao}
          onEdit={editaUsuario}
          onSendLink={enviarLinkSenha}
          onCopyLink={gerarLinkSenha}
          onLinkUser={vincularUsuario}
        />
      ),
      propriedadesCustomizadas: {
        "data-testid": "options",
      },
    },
  ];
  return (
    <div className={classes.wrapperTable} data-testid="UsuarioTable">
      <CustomTable
        servico={listarUsuarios}
        colunas={columns}
        tabelaEsquerda
        ativarPaginacao
      />
    </div>
  );
};

const styles = (theme) => ({
  subtitle: {
    fontWeight: "500",
    display: "block",
  },
  iconeMenu: {
    color: theme.palette.primary.main,
  },
  middleCellClass: {
    padding: "4px 24px 4px 24px",
  },
  middleCellClassWithOrder: {
    padding: "4px 28px 4px 42px",
  },
  chipSTP: {
    backgroundColor: "#57BD41",
  },
  chipLITE: {
    backgroundColor: "#ff0000",
    color: "#ffffff",
  },
  actionColumnContainer: {
    display: "flex",
    flexDirection: "row",
  },
  wrapperTable: {
    width: "100%",
  },
});

export default withStyles(styles)(UsuarioTable);
