import React from 'react';
import { Grid, Typography } from '@material-ui/core';

function feedbackTab(props) {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      id="txtListaClientesVazia"
      alignItems="center"
      style={{
        padding: '5% 0'
      }}
    >
      <Typography variant="subtitle1" id="txtMensagem">
        {props.mensagem}
      </Typography>
    </Grid>
  );
}
export default feedbackTab;
