import React from 'react';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core/';

const transitionDialog = props => {
  return <Slide direction="up" {...props} />;
};

export const dialogConfirmacaoAcao = props => {
  const {
    classes,
    tituloDialog,
    textoDialog,
    dialogAberto,
    aoFecharDialog,
    cancelarAcaoDialog,
    confirmarAcaoDialog
  } = props;
  return (
    <Dialog
      TransitionComponent={transitionDialog}
      open={dialogAberto}
      onClose={aoFecharDialog}
      aria-labelledby="dialogTitle"
      aria-describedby="dialogDescription"
    >
      <div className={classes.conteudoDialog}>
        <DialogTitle id="dialogTitle">{tituloDialog}</DialogTitle>
        {textoDialog && (
          <DialogContent>
            <DialogContentText id="dialogDescription">{textoDialog}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button id="btnCancelaAcaoDialog" onClick={cancelarAcaoDialog} autoFocus>
            Cancelar
          </Button>
          <Button id="btnConfirmaAcaoDialog" onClick={confirmarAcaoDialog} color="primary">
            Sim
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

dialogConfirmacaoAcao.defaultProps = {
  tituloDialog: '',
  dialogAberto: false,
  aoFecharDialog: () => {},
  cancelarAcaoDialog: () => {},
  confirmarAcaoDialog: () => {}
};
const styles = theme => ({
  conteudoDialog: {
    maxWidth: 340
  }
});
export default withStyles(styles)(dialogConfirmacaoAcao);
