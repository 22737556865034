import axios from 'axios';
const URI_INGREDIENTE = '/consumo/api/v1/ingredientes';
const URI_TIPO_INGREDIENTE = '/consumo/api/v1/tiposIngrediente';

class IngredienteService {
  async obtemIngredientes(filtro = '') {
    return axios.get(`${URI_INGREDIENTE}${filtro}`, { headers: {} });
  }

  async cadastraIngrediente(ingredienteCadastrar) {
    return axios.post(`${URI_INGREDIENTE}`, ingredienteCadastrar);
  }

  async salvaIngrediente(IdIngrediente, ingredienteSalvar) {
    return axios.patch(`${URI_INGREDIENTE}/${IdIngrediente}`, ingredienteSalvar);
  }
  async excluiIngrediente(IdIngrediente) {
    return axios.delete(`${URI_INGREDIENTE}/${IdIngrediente}`);
  }

  async obtemTipoIngredientes(filtro = '') {
    return axios.get(`${URI_TIPO_INGREDIENTE}${filtro}`, { headers: {} });
  }
}
export default new IngredienteService();
 