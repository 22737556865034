import _ from 'lodash';
import memoize from 'memoize-one';
import { normalizarString } from '../../shared/util';

export const verificaFazendaHabilitada = (idFazenda, fazendasHabilitadasTggleSelecionada) => {
  const index = _.findIndex(fazendasHabilitadasTggleSelecionada, fazendaHabilitada => {
    return fazendaHabilitada.IdFazenda === idFazenda;
  });
  return index >= 0 ? true : false;
};

export const filterFazendasMemoize = memoize((list, textoFiltrar) =>
  list.filter(fazenda => {
    const nomeNormalizado = normalizarString(fazenda.Nome);
    const nomeClienteNormalizado = normalizarString(fazenda.NomeCliente);
    const textoFiltrarFormatado = normalizarString(textoFiltrar);
    return (
      nomeNormalizado.includes(textoFiltrarFormatado) ||
      nomeClienteNormalizado.includes(textoFiltrarFormatado)
    );
  })
);
