import { SET_USUARIO_AUTH0 } from './usuarioAuth0VinculoActions';
const initialState = {
  usuarioAuth0: {
    permissoes: {},
    email: '',
    Role: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USUARIO_AUTH0:
      return { ...state, usuarioAuth0: action.payload };

    default:
      return { ...state };
  }
};
