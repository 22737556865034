import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const conteudoTabVinculo = props => {
  const { acoesVinculoTab, formNovoVinculo, listaVinculos, chamadaDadosConcluida, classes } = props;

  return (
    <Grid id="usuarioretiro" container className={classes.containerPage}>
      <Grid item container direction="row" justify="flex-end" style={{ paddingBottom: 20 }}>
        {acoesVinculoTab}
      </Grid>
      <Grid container direction="column" className={classes.containerConteudo}>
        {formNovoVinculo}
        {chamadaDadosConcluida && !listaVinculos ? (
          <div className={classes.containerMensagem} id="txtNaoExisteItens">
            Não existe itens para listar
          </div>
        ) : (
          listaVinculos
        )}
      </Grid>
    </Grid>
  );
};

conteudoTabVinculo.propTypes = {
  classes: PropTypes.object.isRequired,
  acoesVinculoTab: PropTypes.node.isRequired,
  formNovoVinculo: PropTypes.node.isRequired,
  listaVinculos: PropTypes.node.isRequired
};

conteudoTabVinculo.defaultProps = {
  dadosListaCarregados: false,
  chamadaDadosConcluida: true
};

const styles = theme => {
  return {
    containerPage: {
      flexGrow: 1,
      padding: '2%'
    },
    containerConteudo: {
      paddingTop: 10
    },
    containerMensagem: {
      display: 'flex',
      flexDirectio: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: 100,
      color: '#888'
    }
  };
};

export default withStyles(styles)(conteudoTabVinculo);
