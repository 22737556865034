import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

function withLogin(WrappedComponent) {
  return withRouter(
    class extends React.Component {
      constructor(props) {
        super(props);
        if (!this.tokenValido()) {
          this.removeToken();
        }
      }

      removeToken = () => {
        localStorage.removeItem('access_token');
      };

      tokenValido = () => {
        return !!localStorage.getItem('access_token');
      };

      render() {
        return this.tokenValido() ? <WrappedComponent {...this.props} /> : <Redirect to="/" />;
      }
    }
  );
}

export default withLogin;
