import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { Link, withRouter } from "react-router-dom";

export const logo = (props) => {
  const { classes } = props;
  return (
    <div className={classes.cabecalhoLogo}>
      <Link to="/" className={classes.containerLogo}>
        <img
          id="imgLogoFarmtellViewsCabecalho"
          src="../images/logo-farmtell-views-color-2.png"
          alt="logo farmtell views"
          className={classes.logo}
        />
      </Link>
      {/* <div className={classes.dividerVertical} /> */}
    </div>
  );
};

logo.displayName = "LogoProdapCabecalho";

logo.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  cabecalhoLogo: {
    width: 240,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    // justifyContent: 'space-between'
  },
  containerLogo: {
    width: "35%",
    minWidth: 120,
  },
  logo: {
    width: "100%",
    minWidth: 101,
  },
  paginaAtual: {
    fontFamily: theme.typography.fontFamily,
  },
  dividerVertical: {
    width: 0,
    height: 25,
    borderLeft: "solid",
    borderLeftWidth: 1,
    borderLeftColor: "#307297",
  },
});
export default withRouter(withStyles(styles)(logo));
