import * as tableauReportActions from './relatorioTableauActions';

export const INITIAL_STATE = {
  Relatorios: []
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case tableauReportActions.OBTER_RELATORIOS_TABLEAU:
      return { ...state, Relatorios: action.payload.relatorios }
    case tableauReportActions.DELETAR_RELATORIO_TABLEAU:
      return { ...state, Relatorios: action.payload.relatorios }
    case tableauReportActions.ATUALIZAR_RELATORIO_TABLEAU:
      return { ...state, Relatorios: action.payload.relatorios }
    case tableauReportActions.CRIAR_RELATORIO_TABLEAU:
      return { ...state, Relatorios: action.payload.relatorios }
    default:
      return state;
  }
};
