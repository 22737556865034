import React, { useState } from 'react';
import {
  CheckboxList,
  CheckboxItem,
  CheckboxListHeader,
  FeedbackTab,
  ContentTabLayout,
  DialogForm
} from '../../../shared/components';
import { useSelecionarTodosVinculos } from '../../../shared/hooks';
import { VerificaClienteAcessoFinanceiro } from "../../usuarioFazendaVinculo/usuarioFazendaVinculoUtils";
import { descriptografar } from "../../../shared/util/crypto";

export function UsuarioFazendaVinculoTab(props) {
  const { dadosCarregados, fazendas } = props;
  const [filterText, setFilterText] = useState('');
  let { senhaFinanceiro } = '';
  const [open, setOpen] = React.useState(false);

  const {
    todosSelecionados,
    setTodosSelecionados,
    selecionarTodos,
    vinculosSalvar
  } = useSelecionarTodosVinculos([...props.fazendas]);

  /* istanbul ignore next */
  const selecionaFazenda = (item, selecionado) => {
    vinculosSalvar[item.index].usuarioVinculado = selecionado;
    if (todosSelecionados) {
      setTodosSelecionados(false);
    }
  };

  /* istanbul ignore next */
  const salvaVinculosUsuarioFazenda = () => {
    // props.salvaVinculosUsuarioFazenda(vinculosSalvar, todosSelecionados);
    if (VerificaClienteAcessoFinanceiro(vinculosSalvar)) {
      setOpen(true);
    } else {
      props.salvaVinculosUsuarioFazenda(vinculosSalvar);
    }
  };

  /* istanbul ignore next */
  const setPasswordDialog = (value) => {
    senhaFinanceiro = value;
  }
  /* istanbul ignore next */
  const handleClose = () => {
    setOpen(false);
  }

  /* istanbul ignore next */
  const handleConfirm = () => {
    if (senhaFinanceiro !== undefined && senhaFinanceiro.length >= 6) {
      setOpen(false);
      props.salvarVinculosUsuarioFazendaFinanceiro(vinculosSalvar, senhaFinanceiro);
    }
    else {
      alert("A senha deve ter o mínimo de 6 caracteres.")
    }
  }
  /* istanbul ignore next */
  const handleCancel = () => {
    setOpen(false);
    setPasswordDialog('');
    props.salvaVinculosUsuarioFazenda(vinculosSalvar, '');
  }

  const renderFazendas = () => {
    if (dadosCarregados && fazendas.length === 0) {
      return <FeedbackTab mensagem="Não foi possível listar fazendas" />;
    } else if (fazendas.length > 0) {
      if (props.senhaFinanceiro !== null && props.senhaFinanceiro !== undefined) {
        senhaFinanceiro = descriptografar(props.senhaFinanceiro);
      }

      return (
        <React.Fragment>
          <CheckboxListHeader
            salvarAlteracoes={salvaVinculosUsuarioFazenda}
            todosSelecionados={todosSelecionados}
            selecionarTodos={selecionarTodos}
            filterText={filterText}
            setFilterText={setFilterText}
          />
          <DialogForm
            tituloDialog={'Acesso Financeiro'}
            textoDialog={'Um ou mais clientes possuem acesso o módulo financeiro. Informe uma senha caso queira acesso a esse módulo.'}
            abreDialog={open}
            fechaDialog={handleClose}
            cancelarAcaoDialog={handleCancel}
            confirmarAcaoDialog={handleConfirm}
            tipoEntradaDados={'password'}
            tituloCampo={'Senha'}
            valorCampo={senhaFinanceiro}
            setValor={setPasswordDialog}
          />
          <CheckboxList
            filterText={filterText}
            itens={vinculosSalvar}
            campoFiltrar="Nome"
            renderItem={fazenda => {
              return (
                <CheckboxItem
                  todosSelecionados={todosSelecionados}
                  item={fazenda}
                  campoLabel="Nome"
                  campoValue="IdFazenda"
                  selecionado={fazenda.usuarioVinculado}
                  onChange={selecionaFazenda}
                  key={fazenda.IdFazenda}
                />
              );
            }}
          />
        </React.Fragment>
      );
    }
    return null;
  };
  return <ContentTabLayout>{renderFazendas()}</ContentTabLayout>;
}

export default UsuarioFazendaVinculoTab;
