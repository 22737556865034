import React from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';
import EllipsisText from 'react-ellipsis-text';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');
export const fazendaView = props => {
  const { fazenda, classes } = props;
  return (
    <React.Fragment>
      <Grid container item justify="space-between" direction="row" alignItems="center">
        <Grid container item alignItems="center" direction="row" style={{ width: '60%' }}>
          <Avatar className={classes.avatar}>
            <span className={classes.avatarSpan}>{fazenda.IdFazenda}</span>
          </Avatar>
          <Grid item>
            <Typography className={classes.heading}>
              <EllipsisText
                id="txtNomeFazendaView"
                text={fazenda.Nome}
                length={37}
                onClick={props.expandeFazendaItem}
              />
            </Typography>
            <Typography
              id="txtNomeClienteFazendaView"
              className={classes.heading}
              onClick={props.expandeFazendaItem}
            >
              <EllipsisText id="txtNomeClienteView" text={fazenda.NomeCliente} length={37} />
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Typography id="DataFazendaView" className={classes.heading}>
            {moment(fazenda.DataAlteracao || fazenda.DataCriacao).format('lll')}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  middle: {
    position: 'absolute',
    right: '25%',
    bottom: '30%'
  },
  avatar: {
    marginRight: '5%',
    width: 30,
    height: 30
  },
  avatarSpan: {
    fontSize: theme.typography.pxToRem(12)
  }
});

export default withStyles(styles)(fazendaView);
