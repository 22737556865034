import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  FormHelperText
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const selectForm = props => {
  const {
    labelSelect,
    nomeInput,
    opcoesSelect,
    valorOpcaoSelecionada,
    textoAjuda,
    alteraSelecao,
    classes,
    selectComError
  } = props;

  const listaOpcoesVazia = !opcoesSelect || !opcoesSelect.length;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink htmlFor={nomeInput}>
        {labelSelect}
      </InputLabel>
      <Select
        error={selectComError}
        disabled={listaOpcoesVazia}
        native
        value={valorOpcaoSelecionada}
        onChange={alteraSelecao}
        input={<Input name={nomeInput} />}
      >
        {opcoesSelect.map(opcao => (
          <option key={opcao.value} value={opcao.value}>{opcao.label}</option>
        ))}
      </Select>
      <FormHelperText>{textoAjuda}</FormHelperText>
    </FormControl>
  );
};
const styles = theme => ({
  formControl: {
    margin: '2% 0',
    minWidth: 260
  }
});
export default withStyles(styles)(selectForm);
