import * as usuarioRetiroVinculoActions from './usuarioRetiroVinculoActions';

export const INITIAL_STATE = {
  fazendasUsuario: [],
  retirosFazendas: [],
  retirosUsuario: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case usuarioRetiroVinculoActions.SET_FAZENDAS_USUARIO:
      return { ...state, fazendasUsuario: action.payload };

    case usuarioRetiroVinculoActions.SET_RETIROS_FAZENDAS:
      return { ...state, retirosFazendas: action.payload };

    case usuarioRetiroVinculoActions.SET_RETIROS_USUARIO:
      return { ...state, retirosUsuario: action.payload };

    case usuarioRetiroVinculoActions.RESET_RETIROS_USUARIO:
      return { ...state, retirosUsuario: [] };

    default:
      return state;
  }
};
