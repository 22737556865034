import * as UIActions from '../../../shared/redux/UIActions';
import { notificacaoActions } from '../../notificacao/redux';
import UsuarioClienteVinculoManager from '../usuarioClienteVinculoManager';
import * as usuarioClienteVinculoActions from './usuarioClienteVinculoActions';

export const MSG = {
  ID_USUARIO_NAO_FORNECIDO: 'Informe um usuário válido',
  SALVA_VINCULOS_USUARIO_CLIENTE_SUCESSO: 'Vínculos salvos com sucesso',
  SALVA_VINCULOS_USUARIO_CLIENTE_FALHA: 'Não foi possível salvar vínculos'
};

const obtemClientesUsuario = IdUsuario => async (dispatch, getState) => {
  try {
    if (!IdUsuario || !Number(IdUsuario)) {
      throw new Error(MSG.ID_USUARIO_NAO_FORNECIDO);
    }
    const clientesUsuario = await UsuarioClienteVinculoManager.obtemClientesUsuario(IdUsuario);
    dispatch(usuarioClienteVinculoActions.setClientesUsuario(clientesUsuario));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};

const salvarVinculosUsuarioCliente = (IdUsuario, vinculosSalvar) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const vinculosSalvos = await UsuarioClienteVinculoManager.salvarVinculosUsuarioCliente(
      IdUsuario,
      vinculosSalvar
    );
    if (vinculosSalvos) {
      const clientesUsuario = await UsuarioClienteVinculoManager.obtemClientesUsuario(IdUsuario);
      dispatch(usuarioClienteVinculoActions.setClientesUsuario(clientesUsuario));
      dispatch(UIActions.setLoading());
      dispatch(notificacaoActions.notificaSucesso(MSG.SALVA_VINCULOS_USUARIO_CLIENTE_SUCESSO));
    } else {
      dispatch(notificacaoActions.notificaErro(MSG.SALVA_VINCULOS_USUARIO_CLIENTE_FALHA));
    }
  } catch (error) {
    dispatch(UIActions.removeLoading());
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};

const salvarVinculosUsuarioClienteComFinanceiro = (IdUsuario, vinculosSalvar, senha) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const vinculosSalvos = await UsuarioClienteVinculoManager.salvarVinculosUsuarioClienteComFinanceiro(
      IdUsuario,
      vinculosSalvar,
      senha
    );
    if (vinculosSalvos) {
      const clientesUsuario = await UsuarioClienteVinculoManager.obtemClientesUsuario(IdUsuario);
      dispatch(usuarioClienteVinculoActions.setClientesUsuario(clientesUsuario));
      dispatch(UIActions.setLoading());
      dispatch(notificacaoActions.notificaSucesso(MSG.SALVA_VINCULOS_USUARIO_CLIENTE_SUCESSO));
    } else {
      dispatch(notificacaoActions.notificaErro(MSG.SALVA_VINCULOS_USUARIO_CLIENTE_FALHA));
    }
  } catch (error) {
    dispatch(UIActions.removeLoading());
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};


export { obtemClientesUsuario, salvarVinculosUsuarioCliente, salvarVinculosUsuarioClienteComFinanceiro };
