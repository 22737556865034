import React, { PureComponent } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const withLoading = WrappedComponent => {
  return class extends PureComponent {
    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <div className={this.props.loading ? classes.loadingContainer : ''}>
            {this.props.loading && (
              <div className={classes.circularProgressConatiner}>
                <CircularProgress color="primary" />
              </div>
            )}

            <WrappedComponent {..._.omit(this.props, 'classes')} />
          </div>
        </React.Fragment>
      );
    }
  };
};

export const mapStateToProps = state => {
  return {
    loading: state.interface.loading
  };
};

const styles = theme => {
  return {
    loadingContainer: {
      width: '100%',
      height: '100%',
      zIndex: 99999,
      position: 'absolute',
      pointerEvents: 'none',
      touchAction: 'none',
      opacity: 0.5
    },
    circularProgressConatiner: {
      position: 'fixed',
      width: '100%',
      zIndex: 1,
      top: '50%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }
  };
};
const withLoadingHOC = compose(
  withStyles(styles),
  connect(mapStateToProps),
  withLoading
);

export default withLoadingHOC;
