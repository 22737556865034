import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UsuarioRetiroVinculoTab from './usuarioRetiroVinculoTab';
import {
  salvaVinculosUsuarioRetiro,
  obtemVinculosUsuarioRetiro
} from '../redux/usuarioRetiroVinculoOperations';
import { resetRetirosUsuario, setRetirosUsuario } from '../redux/usuarioRetiroVinculoActions';
import * as usuarioRetiroVinculoSelector from '../redux/usuarioRetiroVinculoSelector';
import { setTabSelecionada } from '../../../shared/redux/UIActions';

export function UsuarioRetiroVinculoTabContainer(props) {
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const { location } = props;
  const { variant } = props.reduxProps;
  const tabUsuarioRetiroSelecionada = location.hash === '#usuarioretiro';
  const IdUsuario = props.match.params.IdUsuario;

  const obtemDados = async () => {
    /* istanbul ignore next */
    await props.obtemVinculosUsuarioRetiro(IdUsuario);
/* istanbul ignore next */
    setDadosCarregados(true);
  };

  /* istanbul ignore next */
  const salvaVinculosUsuarioRetiro = (IdUsuario, vinculosSalvar) => {
    /* istanbul ignore next */
    props.salvaVinculosUsuarioRetiro(IdUsuario, vinculosSalvar);
  };

  const {
    reduxProps: { fazendasUsuario, retirosFazendas, retirosUsuario }
  } = props;

  useEffect(() => {
    if (tabUsuarioRetiroSelecionada) {
      /* istanbul ignore next */
      obtemDados();
    } else {
      /* istanbul ignore next */
      setDadosCarregados(false);
      /* istanbul ignore next */
      props.setRetirosUsuario([]);
    }
  }, [props.location.hash]);

  /* istanbul ignore next */
  useEffect(() => {
    if (variant !== 'success') {
      /* istanbul ignore next */
      props.setRetirosUsuario([]);
    }
  }, [variant]);

  return (
    <React.Fragment>
      {tabUsuarioRetiroSelecionada ? (
        <UsuarioRetiroVinculoTab
          salvaVinculosUsuarioRetiro={salvaVinculosUsuarioRetiro}
          dadosListaCarregados={
            !!(fazendasUsuario.length && retirosFazendas.length && retirosUsuario.length)
          }
          fazendasUsuario={fazendasUsuario}
          retirosFazendas={retirosFazendas}
          retirosUsuario={retirosUsuario}
          usuario={props.usuario}
          dadosCarregados={dadosCarregados}
        />
      ) : null}
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  salvaVinculosUsuarioRetiro: salvaVinculosUsuarioRetiro,
  obtemVinculosUsuarioRetiro: obtemVinculosUsuarioRetiro,
  setRetirosUsuario: setRetirosUsuario,
  setTabSelecionada: setTabSelecionada,
  resetRetirosUsuario: resetRetirosUsuario
};

/* istanbul ignore next */
export const mapStateToProps = state => {
  const { usuarioRetiroVinculo } = state;
  /* istanbul ignore next */
  return {
    reduxProps: {
      fazendasUsuario: usuarioRetiroVinculo.fazendasUsuario,
      retirosFazendas: usuarioRetiroVinculo.retirosFazendas,
      retirosUsuario: usuarioRetiroVinculoSelector.getRetirosUsuario(state),
      variant: state.notificacao.variant
    }
  };
};

UsuarioRetiroVinculoTabContainer.displayName = 'UsuarioRetiroVinculoTabContainer';

UsuarioRetiroVinculoTabContainer.propTypes = {
  resetRetirosUsuario: PropTypes.func.isRequired,
  obtemVinculosUsuarioRetiro: PropTypes.func.isRequired,
  usuario: PropTypes.shape({
    IdUsuario: PropTypes.number,
    Nome: PropTypes.string
  }).isRequired
};

UsuarioRetiroVinculoTabContainer.defaultProps = {
  reduxProps: {
    fazendasUsuario: [],
    retirosFazendas: [],
    retirosUsuario: []
  },
  usuario: {}
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UsuarioRetiroVinculoTabContainer)
);
