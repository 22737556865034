import { UsuarioManager } from '../usuario';
import { FazendaManager } from '../fazenda';
import { RetiroManager } from '../retiro';
import {
  montaFiltroFazendasUsuario,
  mapeiaFazendasUsuarioParaIdsFazendasUnicos,
  filtraRetirosUsuarioDasFazendasUsuario,
  filtraRetirosUsuariosPresentesEmRetirosFazenda
} from './usuarioRetiroVinculoUtils';

class UsuarioRetiroVinculoManager {
  constructor() {
    this.idsFazendasUsuario = [];
    this.retirosFazendas = [];
  }

  async obtemFazendasUsuario(IdUsuario) {
    try {
      const fazendasUsuario = await UsuarioManager.obtemFazendasUsuario(IdUsuario);

      this.idsFazendasUsuario = mapeiaFazendasUsuarioParaIdsFazendasUnicos(fazendasUsuario);
      const filtroFazendas = montaFiltroFazendasUsuario(this.idsFazendasUsuario);

      const fazendasExistentesComNome = await FazendaManager.obtemFazendasIdNome(filtroFazendas);

      this.idsFazendasUsuario = mapeiaFazendasUsuarioParaIdsFazendasUnicos(
        fazendasExistentesComNome
      );
      return fazendasExistentesComNome;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async obtemRetirosFazendas() {
    const retirosFazendas = await RetiroManager.obtemRetirosFazendas(this.idsFazendasUsuario);
    this.retirosFazendas = retirosFazendas;
    return retirosFazendas;
  }

  async obtemRetirosUsuario(IdUsuario) {
    try {
      const retirosUsuario = await UsuarioManager.obtemRetirosUsuario(IdUsuario);
      const retirosUsuarioDasFazendasUsuario = filtraRetirosUsuarioDasFazendasUsuario(
        retirosUsuario,
        this.idsFazendasUsuario
      );
      const retirosUsuariosDosRetirosDasFazendas = filtraRetirosUsuariosPresentesEmRetirosFazenda(
        retirosUsuarioDasFazendasUsuario,
        this.retirosFazendas
      );
      const retirosComNome = this.mapeiaNomesRetirosFazendasRetirosUsuario(
        retirosUsuariosDosRetirosDasFazendas
      );
      return retirosComNome;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  retornaNomeRetiroUsuario = retiroUsuario => {
    const retiroFazenda = this.retirosFazendas.find(
      retiroFazenda =>
        retiroFazenda.IdFazenda === retiroUsuario.IdFazenda &&
        retiroFazenda.IdRetiro === retiroUsuario.IdRetiro
    );
    return retiroFazenda.Nome;
  };

  mapeiaNomesRetirosFazendasRetirosUsuario = (retirosUsuario = []) => {
    return retirosUsuario.map(retiroUsuario => {
      return {
        ...retiroUsuario,
        Nome: this.retornaNomeRetiroUsuario(retiroUsuario)
      };
    });
  };

  async salvaVinculosUsuarioRetiro(IdUsuario, vinculosUsuarioRetiro, fazendaComTodosRetiros) {
    if (!IdUsuario) {
      return false;
    } else if (!vinculosUsuarioRetiro && !fazendaComTodosRetiros) {
      return false;
    }
    return UsuarioManager.salvaVinculosUsuarioRetiro(
      IdUsuario,
      vinculosUsuarioRetiro,
      fazendaComTodosRetiros
    );
  }
}
export default new UsuarioRetiroVinculoManager();
