import React from 'react';
import PropTypes from 'prop-types';
import UsuarioRetiroVinculoItem from './usuarioRetiroVinculoItem';

export const usuarioRetiroVinculoList = props => {
  const {
    retirosUsuario,
    opcoesSelectFazenda,
    retirosAgrupadosPorFazenda,
    salvaAlteracoesVinculo,
    excluiUsuarioRetiroVinculo
  } = props;

  return (
    <React.Fragment>
      {retirosUsuario.map((retiroUsuario, index) => {
        return (
          <UsuarioRetiroVinculoItem
            key={`${retiroUsuario.IdRetiro}-${
              retiroUsuario.IdFazenda
            }-${index}`}
            excluiUsuarioRetiroVinculo={excluiUsuarioRetiroVinculo}
            fazendaSelecionada={retiroUsuario.IdFazenda}
            retiroUsuario={retiroUsuario}
            opcoesSelectFazenda={opcoesSelectFazenda}
            retirosAgrupadosPorFazenda={retirosAgrupadosPorFazenda}
            salvaAlteracoesVinculo={salvaAlteracoesVinculo}
            index={index}
          />
        );
      })}
    </React.Fragment>
  );
};

usuarioRetiroVinculoList.displayName = 'UsuarioRetiroVinculoList';
usuarioRetiroVinculoList.propTypes = {
  retirosUsuario: PropTypes.arrayOf(
    PropTypes.shape({
      IdRetiro: PropTypes.number,
      IdFazenda: PropTypes.number
    })
  ).isRequired,
  opcoesSelectFazenda: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  retirosAgrupadosPorFazenda: PropTypes.object.isRequired,
  salvaAlteracoesVinculo: PropTypes.func.isRequired,
  excluiUsuarioRetiroVinculo: PropTypes.func.isRequired
};

export default usuarioRetiroVinculoList;
