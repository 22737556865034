export const metadadosAuth = [
  {
    label: "Aplicativo",
    value: "acessa_aplicativo",
  },
  {
    label: "Acessa plataforma",
    value: "acessa_plataforma"
  },
  {
    label: "Importar mapas",
    value: "importar_mapas",
  },
  {
    label: "Importar/exportar CSV pasto",
    value: "exportar_importar_csv_pasto",
  },
  {
    label: "Cadastrar ciclo de forragem",
    value: "cadastrar_ciclo_forragem",
  },
  {
    label: "Portal Configurações",
    value: "acessa_portal_configuracoes",
  },
  {
    label: "Estoque final",
    value: "estoque_final",
  },
  {
    label: "Semi Confinamento",
    value: "semiconfinamento",
  },
];
