import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectForm } from '../../../shared/components';
import SelectVinculoLinha from '../../vinculos/views/selectVinculoLinha';
import { mapeiaRetirosEmOpcoesSelect } from '../usuarioRetiroVinculoUtils';

export class UsuarioRetiroVinculoItem extends Component {
  constructor(props) {
    super(props);
    const { fazendaSelecionada } = this.props;
    this.state = {
      valorFazendaSelecionada: fazendaSelecionada,
      valorRetiroSelecionado: this.props.retiroUsuario.IdRetiro,
      opcoesSelectRetiro: this.obtemOpcoesRetirosFazenda(fazendaSelecionada)
    };
  }

  excluirUsuarioRetiro = () => {
    this.props.excluiUsuarioRetiroVinculo(this.props.index);
  };

  obtemOpcoesRetirosFazenda = fazendaSelecionada => {
    const { retirosAgrupadosPorFazenda } = this.props;
    const retirosFazendaSelecionada = mapeiaRetirosEmOpcoesSelect(
      retirosAgrupadosPorFazenda,
      fazendaSelecionada
    );
    return retirosFazendaSelecionada;
  };

  selecionaFazenda = e => {
    const fazendaSelecionada = Number(e.target.value);
    const opcoesSelectRetiro = this.obtemOpcoesRetirosFazenda(fazendaSelecionada);
    const primeiraOpcaoRetirosFazenda = opcoesSelectRetiro.length
      ? opcoesSelectRetiro[0].value
      : null;
    this.setState(
      {
        valorFazendaSelecionada: fazendaSelecionada,
        valorRetiroSelecionado: primeiraOpcaoRetirosFazenda,
        opcoesSelectRetiro
      },
      () => {
        this.props.salvaAlteracoesVinculo(
          {
            IdFazenda: fazendaSelecionada,
            IdRetiro: primeiraOpcaoRetirosFazenda
          },
          this.props.index
        );
      }
    );
  };

  selecionaRetiro = e => {
    const retiroSelecionado = Number(e.target.value);
    const tempRetiro = {
      IdFazenda: this.state.valorFazendaSelecionada,
      IdRetiro: retiroSelecionado
    };
    this.setState({
      valorRetiroSelecionado: retiroSelecionado
    });
    this.props.salvaAlteracoesVinculo(tempRetiro, this.props.index);
  };

  render() {
    const { opcoesSelectFazenda, retiroUsuario } = this.props;
    const { valorFazendaSelecionada, valorRetiroSelecionado, opcoesSelectRetiro } = this.state;

    return (
      <SelectVinculoLinha
        excluirLinha={this.excluirUsuarioRetiro}
        selects={
          <React.Fragment>
            <SelectForm
              id={`select-form-fazenda-${this.props.index}`}
              selectComError={retiroUsuario.repetido}
              labelSelect="Fazenda"
              nomeInput="inputFazenda"
              opcoesSelect={opcoesSelectFazenda}
              valorOpcaoSelecionada={valorFazendaSelecionada}
              textoAjuda="Selecione uma fazenda"
              alteraSelecao={this.selecionaFazenda}
            />
            <SelectForm
              id={`select-form-retiro-${this.props.index}`}
              selectComError={retiroUsuario.repetido}
              labelSelect="Retiro"
              nomeInput="inputRetiro"
              opcoesSelect={opcoesSelectRetiro}
              valorOpcaoSelecionada={valorRetiroSelecionado}
              alteraSelecao={this.selecionaRetiro}
              textoAjuda={
                opcoesSelectRetiro.length
                  ? 'Selecione um retiro'
                  : 'Não existe retiros para a fazenda'
              }
            />
          </React.Fragment>
        }
      />
    );
  }
}

UsuarioRetiroVinculoItem.displayName = 'UsuarioRetiroVinculoItem';
UsuarioRetiroVinculoItem.propTypes = {
  fazendaSelecionada: PropTypes.number.isRequired,
  retiroUsuario: PropTypes.shape({
    IdRetiro: PropTypes.number,
    repetido: PropTypes.bool
  }).isRequired,
  opcoesSelectFazenda: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  retirosAgrupadosPorFazenda: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  salvaAlteracoesVinculo: PropTypes.func.isRequired,
  excluiUsuarioRetiroVinculo: PropTypes.func.isRequired
};
export default UsuarioRetiroVinculoItem;
